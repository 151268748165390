import { ITermsResponse } from '@sparelabs/api-client'
import { openBrowserAsync, WebBrowserPresentationStyle } from 'expo-web-browser'
import React from 'react'
import { Platform, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'
import { colors } from 'src/assets/colors'
import { st } from 'src/locales'
import { OsType } from 'src/util/types'

export const SPARE_TERMS_OF_USE = 'https://sparelabs.com/terms-of-use/'
export const SPARE_PRIVACY_POLICY = 'https://sparelabs.com/privacy-policy/'

interface ILoginTermsProps {
  terms: Omit<ITermsResponse, 'serviceTermsUrl'>
  containerStyle?: StyleProp<ViewStyle>
}

export const LoginTerms = ({ terms, containerStyle }: ILoginTermsProps) => {
  const openHyperLink = async (url: string) => {
    const isWeb = Platform.OS === OsType.Web
    if (!isWeb) {
      await openBrowserAsync(url, {
        presentationStyle: WebBrowserPresentationStyle.PAGE_SHEET,
        dismissButtonStyle: 'close',
        showTitle: false,
        windowFeatures: '_blank',
      })
    }
    {
      window.open(url, '_blank', 'noreferrer')
    }
  }

  return (
    <View style={[styles.termsContainer, containerStyle]}>
      <Text style={styles.smallText}>{`${st.screens.login.standardTerms()} `}</Text>
      <Text style={styles.smallTextLink} onPress={() => openHyperLink(terms.termsOfUseUrl)} accessibilityRole='link'>
        {st.screens.login.termsOfUse()}
      </Text>
      <Text style={styles.smallText}>{` ${st.common.conjunction()} `}</Text>
      <Text style={styles.smallTextLink} onPress={() => openHyperLink(terms.privacyPolicyUrl)} accessibilityRole='link'>
        {st.screens.login.privacyPolicy()}.
      </Text>
    </View>
  )
}
const styles = StyleSheet.create({
  termsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 24,
  },
  smallText: {
    color: colors.gray70,
    fontSize: 14,
  },
  smallTextLink: {
    color: colors.blue50,
    fontSize: 14,
  },
})
