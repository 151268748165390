import { createStackNavigator } from '@react-navigation/stack'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Platform } from 'react-native'
import { st } from 'src/locales'
import { ScreenName } from 'src/navigation'
import {
  getScreenCardStyle,
  getScreenOptionsWithBackButtonNoOverlay,
  getScreenOptionsWithCloseButtonNoOverlay,
} from 'src/navigation/consts/NavigationHelper'
import { NavigatorSettings } from 'src/navigation/consts/NavigatorSettings'
import { ParamsListAddPaymentMethod } from 'src/navigation/types/ScreenParamsListTypes'
import { AddNimoca } from 'src/screens/payments/AddNimoca'
import { AddPaymentMethod } from 'src/screens/payments/AddPaymentMethod'
import { AddStripePaymentMethodSession } from 'src/screens/payments/AddStripePaymentMethodSession'
import { UIStateStore } from 'src/stores/UIStore'
import { OsType } from 'src/util/types'

const Stack = createStackNavigator<ParamsListAddPaymentMethod>()
const headerTitleAlign = NavigatorSettings.headerTitleAlign

export const NavigationStackAddPaymentMethod = observer(() => (
  <Stack.Navigator
    screenOptions={{
      headerStatusBarHeight: NavigatorSettings.headerStatusBarHeight,
    }}
  >
    <Stack.Screen
      name={ScreenName.AddPaymentMethod}
      component={AddPaymentMethod}
      options={({ navigation }) =>
        getScreenOptionsWithCloseButtonNoOverlay(navigation, st.screens.addPaymentMethod.title())
      }
    />
    <Stack.Screen
      name={ScreenName.AddNimoca}
      component={AddNimoca}
      options={({ navigation }) => getScreenOptionsWithBackButtonNoOverlay(navigation, st.screens.addNimoca.title())}
    />
    <Stack.Screen
      name={ScreenName.AddStripePaymentMethodSession}
      component={AddStripePaymentMethodSession}
      options={() => ({
        title: st.screens.addCreditCard.title(),
        headerTitleAlign,
        cardStyle: getScreenCardStyle(UIStateStore.shouldShowLandscapeWeb),
        // Only show the head on web because the full screen stripe sessions
        // On mobile has a back button
        headerShown: Platform.OS === OsType.Web,
      })}
    />
  </Stack.Navigator>
))
