import { isNumber } from 'lodash'

export interface IDimensions {
  width: number | string
  height: number | string
}

export const validateDimensions = (
  heightFixed?: number,
  widthFixed?: number,
  heightPercentage?: number,
  widthPercentage?: number
): IDimensions => {
  // only one of fixed and percentage values should be given
  if (heightFixed && heightPercentage) {
    throw new Error('Both Fixed and Percentage values given for height')
  }
  if (widthFixed && widthPercentage) {
    throw new Error('Both Fixed and Percentage values given for width')
  }

  if (isNumber(widthPercentage) && isNumber(heightPercentage)) {
    if (widthPercentage >= 0 && widthPercentage <= 100 && heightPercentage >= 0 && heightPercentage <= 100) {
      return { width: `${widthPercentage}%`, height: `${heightPercentage}%` }
    }
    throw new Error('Percentage value is out of range')
  }
  if (isNumber(widthFixed) && isNumber(heightFixed)) {
    return { width: widthFixed, height: heightFixed }
  }

  throw new Error('One of width or height not given')
}
