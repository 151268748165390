import React, { Component } from 'react'
import { Marker } from 'src/components/mapMarkers/marker/Marker'
import { IMarker } from 'src/types'

interface IProps {
  marker: IMarker
}

export class PillMarker extends Component<IProps> {
  public render() {
    const marker = this.props.marker
    return (
      <Marker {...marker}>
        <>{marker.child ? marker.child : null}</>
      </Marker>
    )
  }
}
