import { IEstimateService, IJourney } from '@sparelabs/api-client'

export enum RideOption {
  Journey = 'journey',
  OnDemand = 'onDemand',
}

export type IRideOption = IJourneyRideOption | IOnDemandRideOption

export interface IAbstractRideOption {
  rideOptionType: RideOption
  data: IJourney | IEstimateService | null
}

export interface IJourneyRideOption extends IAbstractRideOption {
  rideOptionType: RideOption.Journey
  data: IJourney
}

export interface IOnDemandRideOption extends IAbstractRideOption {
  rideOptionType: RideOption.OnDemand
  data: IEstimateService | null
}

export enum ExternalRideOptions {
  Lyft = 'Lyft',
}
