import { PriceInput } from '@sparelabs/web-ui-components'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { ITipInput, ITipInputProps } from 'src/screens/tip/tipInput/TipInputTypes'

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginBottom: 8,
  },
})

export const TipInput: ITipInput = ({ currency, value, onChange, onFocus }: ITipInputProps) => (
  <View style={styles.input}>
    <PriceInput
      onChange={(value?: number) => onChange(value?.toString() ?? '')}
      value={Number(value)}
      currency={currency}
      onFocus={onFocus}
    />
  </View>
)
