import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { colors } from 'src/assets/colors'
import { Constants } from 'src/consts/Constants'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'

const styles = StyleSheet.create({
  footerText: {
    textAlign: 'center',
    margin: 20,
    color: colors.textDark,
    lineHeight: 22,
  },
})

export const VersionFooter = () => {
  const getVersionString = (): string => {
    let versionString = `v${Constants.APP_VERSION}`
    if (Constants.NATIVE_APP_VERSION && Constants.NATIVE_BUILD_VERSION) {
      versionString = `OTA ${versionString}${'\n'}Native v${Constants.NATIVE_APP_VERSION}-${
        Constants.NATIVE_BUILD_VERSION
      }`
    }
    return versionString
  }

  return (
    <Text style={styles.footerText}>
      {getVersionString()}
      {'\n'}© {moment().format('YYYY')} Spare Labs Inc.
      {'\n'}
      {st.components.accountSettingsFooter.footerLove()}
    </Text>
  )
}
