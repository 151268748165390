import { IconName } from '@fortawesome/fontawesome-svg-core'
import { IJourney, ILeg, IOnDemandLeg, LegMode } from '@sparelabs/api-client'
import { IPoint } from '@sparelabs/geography'
import { MINUTE } from '@sparelabs/time'
import { st } from 'src/locales'
import { IEstimatesUserInputParsed, IScheduledEstimatesUserInputParsed } from 'src/types'

export const MODE_FONT_SIZE_PT = 14
export const MODE_ICON_SIZE_PX = 16

const TRUNCATE_TEXT_LENGTH_THRESHOLD = 3
const SHOW_WALKING_THRESHOLD_MINUTES = 2

export interface IOnDemandItemEstimateInfo {
  serviceId: string
  organizationName: string
  pickupLocationName: string
  dropoffLocationName: string
  pickupLocation: IPoint
  dropoffLocation: IPoint
  pickupTs: number
  dropoffTs: number
  latestDropoffTs: number
}

export enum TagSize {
  Full = 'full',
  Compact = 'compact',
}

export const getModeIcon = (mode: LegMode): IconName => {
  switch (mode) {
    case LegMode.Train:
      return 'subway'
    case LegMode.Tram:
      return 'train'
    case LegMode.Walk:
      return 'walking'
    case LegMode.OnDemand:
      return 'car'
    case LegMode.Bus:
      return 'bus'
    default:
      return 'bus'
  }
}

export const getModeLabel = (mode: LegMode): string => {
  const translateFunction = st.enums.transitModes[mode]

  return translateFunction ? translateFunction() : ''
}

export const getRouteName = (leg: ILeg): string => {
  if (leg.mode === LegMode.Walk) {
    // Returns the amount of time needed to walk
    return `${Math.ceil((leg.endTime.ts - leg.startTime.ts) / MINUTE)}`
  }
  if (leg.mode === LegMode.OnDemand) {
    return leg.serviceName
  }
  return leg.routeName
}

// Returns the minimum width that the tag can while still displaying route name
export const getMinRouteNameTagWidth = () => TRUNCATE_TEXT_LENGTH_THRESHOLD * MODE_FONT_SIZE_PT + MODE_ICON_SIZE_PX

export const getTagSize = (tagWidth: number, screenWidth: number, leg: ILeg) => {
  if (
    tagWidth < getMinRouteNameTagWidth() &&
    leg.mode !== LegMode.Walk &&
    leg.mode !== LegMode.OnDemand &&
    leg.routeName.length > TRUNCATE_TEXT_LENGTH_THRESHOLD
  ) {
    return TagSize.Compact
  } else if (tagWidth > screenWidth) {
    return TagSize.Compact
  }
  return TagSize.Full
}

// In some cases, we don't want to show walking legs that take very little time to complete
// so remove those legs from the list to prevent them from being rendered
export const removeShortDistanceLegs = (legs: ILeg[]) => {
  const prunedLegs: ILeg[] = []
  for (const leg of legs) {
    if (leg.mode === LegMode.Walk) {
      const walkTime = Math.ceil((leg.endTime.ts - leg.startTime.ts) / MINUTE)
      if (walkTime > SHOW_WALKING_THRESHOLD_MINUTES) {
        prunedLegs.push(leg)
      }
    } else {
      prunedLegs.push(leg)
    }
  }

  return prunedLegs
}

export const isWalkingOnlyJourney = (journey: IJourney) =>
  journey.legs.length === 1 && journey.legs[0].mode === LegMode.Walk

export const getEstimateQueryForLeg = (
  leg: IOnDemandLeg,
  { numRiders, riders, accessibilityFeatures, paymentMethodId }: IEstimatesUserInputParsed
): IScheduledEstimatesUserInputParsed => ({
  requestedPickupAddress: leg.start.name,
  requestedDropoffAddress: leg.end.name,
  requestedPickupLocation: leg.start.location,
  requestedDropoffLocation: leg.end.location,
  numRiders,
  riders,
  requestedPickupTs: leg.startTime.ts,
  accessibilityFeatures,
  // TODO: need to implement for first leg replacement
  requestedDropoffTs: null,
  paymentMethodId,
  scheduledPickupLocation: null,
  scheduledDropoffLocation: null,
  pickupWalkingDuration: null,
  dropoffWalkingDuration: null,
  pickupWalkingPolyline: null,
  dropoffWalkingPolyline: null,
})
