import { AccessibilityFeature, IServiceAccessibilityFeature } from '@sparelabs/api-client'
import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { CardIconAction } from 'src/components/cards/CardIconAction'
import { parseAccessibilityFeatureMapToArray } from 'src/helpers/EstimateHelper'
import { st } from 'src/locales'
import { LanguageHelper } from 'src/locales/LanguageHelper'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { AccessibilityCountMap } from 'src/screens/rideOptions/EstimateAccessibilityOptions'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { IEstimateStore } from 'src/stores/EstimateStore'
import { IEstimatesUserInputParsed } from 'src/types'

const translation = st.components.rideOptionsCardBody

export interface IProps {
  estimatesUserInput: IEstimatesUserInputParsed
  features: IServiceAccessibilityFeature[]
  handleNavigateEstimateAccessibilityOptions: (params: ParamsListRoot[ScreenName.EstimateAccessibilityOptions]) => void
  estimateStore: IEstimateStore
  estimateInputStore: IEstimateInputStore
  isJourneySelected: boolean
  isDisabled?: boolean
}

export const getAccessibilityFeatureCount = (accessibilityFeatures?: AccessibilityCountMap): number => {
  let sum = 0
  if (accessibilityFeatures) {
    for (const featureCount of Object.values(accessibilityFeatures)) {
      if (featureCount) {
        sum += featureCount
      }
    }
  }
  return sum
}

export const getAccessibilityTitle = (accessibilityFeatures?: AccessibilityCountMap): string => {
  const count = getAccessibilityFeatureCount(accessibilityFeatures)
  if (count === 0) {
    return translation.accessibility()
  }
  if (count > 1) {
    return LanguageHelper.getFormattedCount(translation.accessibilityRequirement_plural(), count)
  }
  return LanguageHelper.getFormattedCount(translation.accessibilityRequirement(), count)
}

export const AccessibilityControl: FC<IProps> = observer((props: IProps) => {
  const serviceAccessibilityFeatures: AccessibilityFeature[] =
    props.features.length === 0 ? [] : props.features.map((feature) => feature.type)

  return (
    <CardIconAction
      testID='accessibilityIconAction'
      icon='wheelchair'
      title={getAccessibilityTitle(props.estimatesUserInput.accessibilityFeatures)}
      accessibilityLabel={translation.accessibilityLabelFilter()}
      accessibilityHint={translation.accessibilityHintFilter()}
      isDisabled={props.isJourneySelected || props.isDisabled}
      onPress={() =>
        props.handleNavigateEstimateAccessibilityOptions({
          estimateInputStore: props.estimateInputStore,
          serviceAccessibilityFeatures,
          accessibilityFeatures: parseAccessibilityFeatureMapToArray(props.estimatesUserInput.accessibilityFeatures),
          estimateStore: props.estimateStore,
        })
      }
    />
  )
})
