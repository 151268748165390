import { FavoriteLocationType } from '@sparelabs/api-client'
import { colors } from 'src/assets/colors'
import { IListItemIcon } from 'src/components/list/ListViewItem'

export const iconColors = {
  Blue: colors.selectedBlue,
  Grey: colors.radioLabel,
  GreyBlue: colors.titleBlue,
  Purple: colors.purple,
}

export const favoriteTypeToIconMap: Record<FavoriteLocationType, IListItemIcon> = {
  [FavoriteLocationType.Home]: IListItemIcon.Home,
  [FavoriteLocationType.Work]: IListItemIcon.BriefCase,
  [FavoriteLocationType.Other]: IListItemIcon.Star,
  [FavoriteLocationType.Heart]: IListItemIcon.Heart,
  [FavoriteLocationType.Community]: IListItemIcon.PrayingHands,
  [FavoriteLocationType.Subway]: IListItemIcon.Subway,
  [FavoriteLocationType.School]: IListItemIcon.School,
  [FavoriteLocationType.Child]: IListItemIcon.Child,
  [FavoriteLocationType.Restaurant]: IListItemIcon.Utensils,
  [FavoriteLocationType.Gym]: IListItemIcon.Dumbbell,
}

const favoriteTypeToColorMap: Partial<Record<FavoriteLocationType, string>> = {
  [FavoriteLocationType.Home]: colors.selectedBlue,
  [FavoriteLocationType.Work]: colors.purple,
}

export const mapFavoriteTypeToIcon = (type: FavoriteLocationType): IListItemIcon =>
  (type && favoriteTypeToIconMap[type]) || IListItemIcon.Star

export const mapFavoriteTypeToColor = (type: FavoriteLocationType) =>
  (type && favoriteTypeToColorMap[type]) || iconColors.GreyBlue
