import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { st } from 'src/locales'
import { ParamsListEditPhoneNumber, ScreenName } from 'src/navigation'
import { NavigationModalCloseButtonOptions } from 'src/navigation/components/NavigationModalCloseButton'
import { NavigationScreenBackButtonOptions } from 'src/navigation/components/NavigationScreenBackButton'
import { NavigatorSettings } from 'src/navigation/consts/NavigatorSettings'
import { EditPhoneNumber } from 'src/screens/profile/EditPhoneNumber'
import { EditPhoneNumberConfirm } from 'src/screens/profile/EditPhoneNumberConfirm'

const Stack = createStackNavigator<ParamsListEditPhoneNumber>()
const headerTitleAlign = NavigatorSettings.headerTitleAlign

export const NavigationStackEditPhoneNumber = (): JSX.Element => (
  <Stack.Navigator screenOptions={{ headerStatusBarHeight: NavigatorSettings.headerStatusBarHeight }}>
    <Stack.Screen
      name={ScreenName.EditPhoneNumber}
      component={EditPhoneNumber}
      options={({ navigation, route }) => ({
        ...NavigationModalCloseButtonOptions(navigation, route.params.title),
        headerTitleAlign,
      })}
    />
    <Stack.Screen
      name={ScreenName.EditPhoneNumberConfirm}
      component={EditPhoneNumberConfirm}
      options={({ navigation }) => ({
        ...NavigationScreenBackButtonOptions(navigation, st.screens.setPhoneNumberConfirm.title()),
        headerTitleAlign,
      })}
    />
  </Stack.Navigator>
)
