import React, { Component } from 'react'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { st } from 'src/locales'

const BackButtonDiameter = 35

const styles = StyleSheet.create({
  container: {
    paddingLeft: 8,
    paddingBottom: 15,
    width: 70,
    zIndex: 2,
    marginTop: 44,
  },
  touchable: {
    shadowColor: colors.darkShadowColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3,
    shadowOpacity: 1,
    elevation: 2, // For android shadow
    width: BackButtonDiameter,
    height: BackButtonDiameter,
    backgroundColor: 'white',
    borderRadius: BackButtonDiameter / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 13,
    width: 13,
  },
})

interface IProps {
  onPress: () => void
}

export class BackButton extends Component<IProps> {
  public render() {
    return (
      <View style={styles.container}>
        <TouchableOpacity
          style={styles.touchable}
          onPress={this.props.onPress}
          accessibilityLabel={st.common.accessibilityBackButton()}
          accessibilityHint={st.common.accessibilityBackButtonHint()}
          accessibilityRole='button'
        >
          <Image resizeMode='contain' style={styles.image} source={Images.back} />
        </TouchableOpacity>
      </View>
    )
  }
}
