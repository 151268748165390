import { IconName } from '@fortawesome/fontawesome-svg-core'
import React, { Component } from 'react'
import {
  Image,
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'

const styles = StyleSheet.create({
  imageContainer: {
    width: 16,
    height: 16,
    paddingBottom: 4,
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
    padding: 8,
    marginHorizontal: 4,
    borderRadius: 8,
    backgroundColor: colors.blue10,
    height: 48,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 4,
  },
  title: {
    fontSize: 14,
    lineHeight: 16,
    marginLeft: 8,
    marginRight: 12,
  },
  textContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexShrink: 1,
  },
})

interface IProps {
  image?: ImageSourcePropType
  icon?: IconName
  iconColor?: string
  containerStyle?: StyleProp<ViewStyle>
  title: string
  titleColor?: string
  onPress: () => void
  testID?: string
  isDisabled?: boolean
  accessibilityLabel: string
  accessibilityHint: string
  accessibilityValue?: string
}

export class CardIconAction extends Component<IProps> {
  public render() {
    const titleColor = this.props.titleColor ?? (this.props.isDisabled ? colors.gray5 : colors.blue70)
    const iconColor = this.props.iconColor ?? (this.props.isDisabled ? colors.gray5 : colors.blue70)
    return (
      <TouchableOpacity
        testID={this.props.testID}
        accessibilityLabel={this.props.accessibilityLabel}
        accessibilityHint={this.props.accessibilityHint}
        accessibilityValue={{ text: this.props.accessibilityValue ?? '' }}
        accessibilityRole='button'
        style={[styles.buttonContainer, this.props.containerStyle]}
        onPress={() => this.props.onPress()}
        disabled={this.props.isDisabled}
      >
        <View style={styles.button}>
          {this.renderIconOrImage(iconColor)}
          <View style={styles.textContainer}>
            <Text style={[styles.title, { color: titleColor }]} numberOfLines={2}>
              {this.props.title}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  private renderIconOrImage(iconColor: string) {
    if (this.props.image) {
      return <Image resizeMode='contain' style={styles.imageContainer} source={this.props.image} />
    } else if (this.props.icon) {
      return <FontAwesomeIconWrapper icon={this.props.icon} size={16} color={iconColor} />
    }
    throw new Error('No Image or Icon provided!')
  }
}
