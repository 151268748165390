import { Currency } from '@sparelabs/currency'
import React from 'react'
import { Image, Linking, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { AbstractButton } from 'src/components/buttons/AbstractButton'
import { BackButton } from 'src/components/buttons/BackButton'
import { CardWrapper } from 'src/components/cards/CardWrapper'
import { FloatingCard } from 'src/components/cards/FloatingCard'
import { Header } from 'src/components/headers/Header'
import { EstimateCardButtonWrapper } from 'src/components/rideOptions/EstimateCardButtonWrapper'
import { LyftConstants } from 'src/consts/LyftConstants'
import { getDropoffTimeString, getPickupTimeString } from 'src/helpers/EstimateHelper'
import { FareRenderer } from 'src/helpers/FareRenderer'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { RouterStore } from 'src/stores/RouterStore'
import { ILyftEstimate, LyftRideType } from 'src/types/lyftTypes'

const ICON_SIZE = 64

const styles = StyleSheet.create({
  body: {
    flex: 1,
    padding: 16,
    marginBottom: 2,
  },
  infoContainer: {
    flexDirection: 'column',
    paddingTop: 8,
  },
  serviceInfo: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    alignItems: 'center',
  },
  buttonWrapper: {
    paddingHorizontal: 16,
    marginBottom: 16,
  },
  fareText: {
    fontSize: 16,
    color: colors.gray90,
    lineHeight: 20,
  },
  cost: {
    color: colors.gray70,
    textDecorationLine: 'line-through',
    fontSize: 16,
    marginLeft: 4,
  },
  iconWrapper: {
    alignItems: 'center',
    marginTop: 16,
  },
  serviceIcon: {
    height: ICON_SIZE,
    width: ICON_SIZE,
    backgroundColor: 'white',
    borderRadius: ICON_SIZE / 2,
  },
  text: {
    color: colors.gray70,
    fontSize: 16,
    lineHeight: 20,
  },
  lyftIcon: {
    width: 32,
    height: 22,
    marginRight: 10,
  },
  extIcon: {
    width: 16,
    height: 16,
  },
})

interface IProps {
  lyftEstimates: ILyftEstimate[]
  estimateInputStore: IEstimateInputStore
}

export function LyftPassConfirmServiceCard(props: IProps): JSX.Element | null {
  const getPickupTime = () => {
    const pickUpTime = moment().format('LT')
    return getPickupTimeString(pickUpTime, pickUpTime, pickUpTime, false)
  }

  const renderPrice = (estimate: ILyftEstimate) => {
    const { estimated_cost_cents_min, estimated_cost_cents_max } = estimate
    return (
      <Text style={styles.fareText}>
        {`${FareRenderer.renderPriceAmount({
          fare: {
            cost: estimated_cost_cents_min,
            discount: 0,
            total: estimated_cost_cents_min,
            currency: Currency.CAD,
          },
        })}-${FareRenderer.renderPriceAmount({
          fare: {
            cost: estimated_cost_cents_max,
            discount: 0,
            total: estimated_cost_cents_max,
            currency: Currency.CAD,
          },
        })}`}
      </Text>
    )
  }

  const renderServiceInfoContainer = (estimate: ILyftEstimate) => {
    const { estimated_duration_seconds } = estimate
    const dropOffTime = moment().add(estimated_duration_seconds, 'seconds').format('LT')
    return (
      <View style={styles.infoContainer}>
        <Header headerText={st.components.rideOptionsCardBody.lyftPass()} />
        <View style={[styles.serviceInfo, { marginBottom: 4, marginTop: 4 }]}>
          <Text style={styles.text}>{getPickupTime()}</Text>
          {renderPrice(estimate)}
        </View>
        <View style={styles.serviceInfo}>
          <Text style={styles.text}>{getDropoffTimeString(dropOffTime, dropOffTime, dropOffTime, false)}</Text>
        </View>
      </View>
    )
  }

  const handleLyftPassBtn = async () => {
    const { LYFT_HOST, LYFT_CLIENT_ID } = LyftConstants
    const { estimateInput } = props.estimateInputStore
    if (estimateInput) {
      const [startLng, startLat] = estimateInput.requestedPickupLocation.coordinates
      const [endLng, endLat] = estimateInput.requestedDropoffLocation.coordinates
      const url = `${LYFT_HOST}/ride?id=lyft&pickup[latitude]=${startLat}&pickup[longitude]=${startLng}&destination[latitude]=${endLat}&destination[longitude]=${endLng}&partner=${LYFT_CLIENT_ID}`
      await Linking.openURL(url)
    }
  }

  const renderCardBody = (estimate: ILyftEstimate) => (
    <FloatingCard informationBanner={undefined}>
      <View style={styles.body}>
        <View style={styles.iconWrapper}>
          <Image style={styles.serviceIcon} source={Images.lyftLogo} />
        </View>
        {renderServiceInfoContainer(estimate)}
      </View>
      <EstimateCardButtonWrapper>
        <AbstractButton
          testID='LyftPassBtn'
          title={st.components.rideOptionsCard.requestButtonTitle()}
          subtitle={getPickupTime()}
          onPress={() => handleLyftPassBtn()}
          containerStyle={{ flexDirection: 'row' }}
          textColor={colors.textLight}
          backgroundColor={colors.lyftPink}
          textContainerStyles={{ alignItems: 'flex-start' }}
        >
          <View style={{ flexDirection: 'row' }}>
            <Image source={Images.lyftLogoNoBg} style={styles.lyftIcon} />
            <Image source={Images.externalLink} style={styles.extIcon} />
          </View>
        </AbstractButton>
      </EstimateCardButtonWrapper>
    </FloatingCard>
  )

  const estimate = props.lyftEstimates.find((estimate) => estimate.ride_type === LyftRideType.Lyft)
  if (!estimate) {
    return null
  }
  return (
    <CardWrapper containerStyle={{ bottom: 0 }}>
      <View pointerEvents='box-none'>
        <BackButton onPress={() => RouterStore.returnToPreviousScreen()} />
      </View>
      {renderCardBody(estimate)}
    </CardWrapper>
  )
}
