import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Shimmer } from './shimmer/Shimmer'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    height: 2,
  },
  shimmerContainer: {
    flex: 1,
    flexDirection: 'row',
    borderWidth: 0,
    borderRadius: 5,
    overflow: 'hidden',
    height: 2,
  },
})

export const LoadingShimmer = () => {
  const [width, setWidth] = useState<number | null>(null)
  return (
    <View style={styles.container}>
      <View
        style={styles.shimmerContainer}
        onLayout={(event) => {
          setWidth(event.nativeEvent.layout.width)
        }}
      >
        {width === null ? null : (
          <Shimmer
            width={width}
            height={2}
            shimmerStyle={{ style: styles.shimmerContainer }}
            shimmerContainerProps={{ style: styles.shimmerContainer }}
          />
        )}
      </View>
    </View>
  )
}
