import { Moment } from 'src/helpers/Moment'

export interface IDateTimeInputProps {
  date: Moment
  onHandleDateChange: (date: Moment) => void
  showTitle: boolean
  minimumDate?: Moment
  mode?: DateTimePickerMode
  display: DateTimeDisplay
  overrideTimeLabel?: string
}

export enum DateTimePickerMode {
  Date = 'date',
  DateTime = 'datetime',
  Time = 'time',
}

export enum DateTimeDisplay {
  Spinner = 'spinner',
  Calendar = 'calendar',
  Default = 'default',
}

export type IDateTimeInput = React.FC<IDateTimeInputProps>
