import React from 'react'
import { StyleProp, Text, TextStyle, TouchableOpacity, ViewStyle } from 'react-native'
import { FontAwesomeIconWrapper, IFontAwesomeWrapperProps } from 'src/components/FontAwesomeIcon'

interface IProps {
  text: string
  textStyle: StyleProp<TextStyle>
  onPress: () => void
  containerStyle?: StyleProp<ViewStyle>
  leftIconProps?: IFontAwesomeWrapperProps
  rightIconProps?: IFontAwesomeWrapperProps
  testPropertyKey?: string
}

export const TextButton = ({
  containerStyle,
  onPress,
  text,
  textStyle,
  leftIconProps,
  rightIconProps,
  testPropertyKey,
}: IProps) => (
  <TouchableOpacity style={containerStyle} onPress={onPress} testID={testPropertyKey}>
    {leftIconProps && (
      <FontAwesomeIconWrapper icon={leftIconProps.icon} size={leftIconProps.size} color={leftIconProps.color} />
    )}
    <Text style={textStyle}>{text}</Text>
    {rightIconProps && (
      <FontAwesomeIconWrapper icon={rightIconProps.icon} size={rightIconProps.size} color={rightIconProps.color} />
    )}
  </TouchableOpacity>
)
