import React from 'react'
import { SsoLoginButton } from 'src/components/login/SsoLoginButton'
import { StandardLoginButton } from 'src/components/login/StandardLoginButton'
import { AuthType } from 'src/types/constants'

interface ILoginButtonProps {
  authType: AuthType
  handleSsoLoginPress: (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void
  handleLoginPress: () => void
  handleRegisterPress: () => void
}

export const LoginButtons = ({
  authType,
  handleSsoLoginPress,
  handleLoginPress,
  handleRegisterPress,
}: ILoginButtonProps): JSX.Element => {
  switch (authType) {
    case AuthType.SSO:
      return <SsoLoginButton handleSsoLoginPress={handleSsoLoginPress} />
    case AuthType.STANDARD:
    default:
      return <StandardLoginButton handleLoginPress={handleLoginPress} handleRegisterPress={handleRegisterPress} />
  }
}
