import { KILOMETER } from '@sparelabs/geography'
import circle from '@turf/circle'
import { Position } from '@turf/helpers'
import React from 'react'
import { SVGOverlay } from 'react-map-gl'
import { ICircleProps } from 'src/components/mapMarkers/MapMarkerProps'

const getStyle = (props: ICircleProps) => {
  const { fillColor, strokeColor, strokeWidth } = props
  return {
    fill: fillColor,
    stroke: strokeColor,
    strokeWidth,
  }
}

const getCircleCoordinates = (props: ICircleProps) => {
  const center = [props.center.longitude, props.center.latitude]
  const radius = props.radius / KILOMETER
  return circle(center, radius)
}

export const Circle = (props: ICircleProps): JSX.Element => (
  <SVGOverlay
    redraw={({ project }) => {
      const circle = getCircleCoordinates(props)
      const style = getStyle(props)
      return (
        <g style={{ pointerEvents: 'visibleStroke' }}>
          <polygon
            style={{ ...style }}
            points={circle.geometry.coordinates[0]
              .map((coordinate: Position) => project([coordinate[0], coordinate[1]]))
              .join(' ')}
          />
        </g>
      )
    }}
  />
)
