import { FontAwesomeIconStyle } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'

export const OUTER_CIRCLE_DIAMETER = 16

const styles = StyleSheet.create({
  backgroundCircle: {
    position: 'absolute',
    top: 4,
    borderColor: colors.white,
    borderWidth: 4,
    borderRadius: 4,
  },
})

interface IProps {
  outerColor: string
}

export class StopCircle extends Component<IProps> {
  public render() {
    const { outerColor } = this.props
    return (
      <View>
        <View style={styles.backgroundCircle} />
        <FontAwesomeIconWrapper
          icon={[FontAwesomeIconStyle.Solid, 'dot-circle']}
          size={OUTER_CIRCLE_DIAMETER}
          color={outerColor}
        />
      </View>
    )
  }
}
