import { FavoriteLocationType } from '@sparelabs/api-client'
import { EnumValues } from 'enum-values'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { mapFavoriteTypeToIcon } from 'src/helpers/FavoritesHelper'
import { FavoriteTypeIconButton } from './FavoriteTypeIconButton'

const styles = StyleSheet.create({
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    width: '100%',
  },
})

interface IProps {
  onPress: (type: FavoriteLocationType) => void
  selected?: FavoriteLocationType
}

export class FavoriteTypeSelector extends Component<IProps> {
  public renderIcons() {
    const iconTypes = EnumValues.getValues<FavoriteLocationType>(FavoriteLocationType)
    return iconTypes.map((iconType: FavoriteLocationType) => {
      const icon = mapFavoriteTypeToIcon(iconType)
      return (
        <FavoriteTypeIconButton
          key={iconType}
          icon={icon}
          active={iconType === this.props.selected}
          onPress={() => this.props.onPress(iconType)}
        />
      )
    })
  }

  public render() {
    return <View style={styles.iconContainer}>{this.renderIcons()}</View>
  }
}
