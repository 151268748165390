import React, { Component } from 'react'
import { ActivityIndicator, Image, StyleProp, StyleSheet, Text, TouchableOpacity, ViewStyle } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'

const styles = StyleSheet.create({
  infoBar: {
    flex: 1,
    borderRadius: 5,
    paddingVertical: 16,
    backgroundColor: colors.selectedBlue,
    shadowColor: colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3,
    shadowOpacity: 1,
    elevation: 3,
    paddingHorizontal: 16,
    marginBottom: 8,
    marginHorizontal: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoText: {
    width: '85%',
    color: colors.textLight,
    fontWeight: '700',
    fontSize: 14,
  },
  infoArrow: {
    width: 14,
    height: 14,
  },
})

interface IProps {
  onPress: () => void
  infoText: string | null
  barStyle?: StyleProp<ViewStyle>
  loading?: boolean
}

export class HomeInfoBar extends Component<IProps> {
  public render() {
    return (
      <TouchableOpacity
        style={[styles.infoBar, this.props.barStyle]}
        onPress={this.props.onPress}
        accessibilityRole='button'
      >
        <Text style={styles.infoText}>{this.props.infoText}</Text>
        {this.props.loading ? (
          <ActivityIndicator color='white' style={styles.infoArrow} />
        ) : (
          <Image resizeMode='contain' style={styles.infoArrow} source={Images.arrowRight} />
        )}
      </TouchableOpacity>
    )
  }
}
