import { IRequestResponse, RequestStatus } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { colors } from 'src/assets/colors'
import { Pill } from 'src/components/PillArea'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { ActiveRequestVehicleLocationStore } from 'src/stores/ActiveRequestVehicleLocationStore'

interface IProps {
  request: IRequestResponse | null
}

export class VehicleStatusPill extends Component<IProps> {
  public render() {
    const { request } = this.props
    if (!request || request.scheduledPickupTs === null || request.status === RequestStatus.InProgress) {
      return null
    }
    const minutes = this.getMinutesUntilVehicleReveal(request.scheduledPickupTs)
    if (minutes < 1 || minutes >= 30) {
      return null
    }
    return (
      <Pill
        backgroundColor={colors.darkGrayBlue}
        textColor='white'
        title={st.helpers.mapMarkerHelpers.hiddenVehicle({ minutes })}
      />
    )
  }

  private getMinutesUntilVehicleReveal(pickupTs: number): number {
    return (
      moment.unix(pickupTs).diff(moment(), 'minutes') -
      ActiveRequestVehicleLocationStore.VEHICLE_DISPLAY_THRESHOLD_MINUTES
    )
  }
}
