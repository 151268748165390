import {
  ApiClient,
  ClientType,
  IAutocompleteSuggestionsResponse,
  IEstimateNextAvailableQueryParams,
  IEstimateScheduledQueryParams,
  IGlobalMobileAppServerResponse,
  IJourney,
  IJourneyEstimateQueryParams,
  IPlaceDetailsResponse,
  IRequestInsightsResponse,
  IReverseGeocodeResponse,
  ISupportedClientsGetResponse,
  ITermsResponse,
  ITipPolicyResponse,
  ITipPostBody,
  ITipResponse,
  Resources,
} from '@sparelabs/api-client'
import { IPoint } from '@sparelabs/geography'
import { HostStore } from 'src/api/HostStore'
import { LyftConstants } from 'src/consts/LyftConstants'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { LanguageHelper } from 'src/locales/LanguageHelper'
import { ApiStore } from 'src/stores/ApiStore'
import { ILyftResponse } from 'src/types/lyftTypes'
import superagent from 'superagent'
import superagentCache from 'superagent-cache'
import defaults from 'superagent-defaults'
import { IEstimateQueryData } from '../types'

/**
 * Setup superagent-cache, default to 15 seconds of caching but set forceUpdate: true so
 * requests are not cached by default, this can be overridden on a per-endpoint basis
 */
superagentCache(superagent, { defaultExpiration: 15 }, { forceUpdate: true })

export function getApiBasePath(): string {
  return `${AuthenticatorHelper.getRegionalHost()}/v1`
}

// TODO: Refactor to use ResourceTypes library rather than hard-coding endpoints

export function request(token?: string) {
  if (token) {
    return defaults()
      .set('Authorization', `Bearer ${token}`)
      .set('Accept-Language', LanguageHelper.getCurrentLanguageCode())
  }
  return superagent
}

// Queries Places Service for locations (known establishments, street addresses, etc) corresponding to the given coordinates.
export function reverseGeocode({
  latitude,
  longitude,
}: {
  latitude: number
  longitude: number
}): Promise<IReverseGeocodeResponse> {
  return placesReverseGeocode(latitude, longitude)
}

function placesReverseGeocode(latitude: number, longitude: number) {
  return getApi(AuthenticatorHelper.getUserOrgToken()).places.reverseGeocode.get({
    location: [latitude, longitude],
    language: LanguageHelper.getCurrentLanguageCode(),
  })
}

export function searchPlaces(
  search: string,
  referencePoint?: IPoint | null
): Promise<IAutocompleteSuggestionsResponse[]> {
  return getApi(AuthenticatorHelper.getUserOrgToken()).places.autocompleteSuggestions.get({
    search,
    ...(referencePoint?.coordinates && { location: [referencePoint.coordinates[1], referencePoint.coordinates[0]] }),
    language: LanguageHelper.getCurrentLanguageCode(),
  })
}

export function fetchPlaceDetails({ placeId }: { placeId: string }): Promise<IPlaceDetailsResponse> {
  return getApi(AuthenticatorHelper.getUserOrgToken()).places.placeDetails.get({
    placeId,
    language: LanguageHelper.getCurrentLanguageCode(),
  })
}

export function setStripeToken({ token, stripeToken }: { token: string; stripeToken: string }) {
  return LegacyApiClient.post(token, 'connectedAccounts/stripe/token', { token: stripeToken })
}

export function get({ token, resource, id, query }: { token: string; resource: string; id?: string; query?: object }) {
  if (id) {
    return LegacyApiClient.get(token, `${resource}/${id}`, query)
  }
  return LegacyApiClient.get(token, `${resource}`, query)
}

export function del({ token, resource, id }: { token: string; resource: string; id: string }) {
  return LegacyApiClient.delete(token, `${resource}/${id}/`)
}

export async function post({ token, resource, data }: { token: string; resource: string; data: object }) {
  return LegacyApiClient.post(token, `${resource}/`, data)
}

export async function assignGroup({ token, secretCode }: { token: string; secretCode: string }) {
  return LegacyApiClient.post(token, 'users/me/rider/group', { secretCode })
}

export async function unassignGroup({ token, id }: { token: string; id: string }) {
  return LegacyApiClient.delete(token, `users/me/rider/group/${id}`)
}

export function estimateNextAvailable(token: string, query: IEstimateNextAvailableQueryParams) {
  // Enable caching for this API call as it's expensive by setting forceUpdate to false
  return LegacyApiClient.get(token, 'estimates/nextAvailable', query, false, false)
}

export function estimateScheduled(token: string, query: IEstimateScheduledQueryParams) {
  // Enable caching for this API call as it's expensive by setting forceUpdate to false
  return LegacyApiClient.get(token, 'estimates/request', query, false, false)
}

export function estimateServices(token: string, query: IEstimateQueryData) {
  return LegacyApiClient.get(token, 'estimates/services', query)
}

export async function fetchLyftEstimates(query: any): Promise<ILyftResponse> {
  const res = await superagent.get(`${LyftConstants.LYFT_HOST}/api/costs`).query(query)
  return res
}

export function postNimoca(token: string, nimocaId: string) {
  return LegacyApiClient.post(token, 'connectedAccounts/nimoca', {
    nimocaId,
  })
}

export function getActiveAnnouncements(token: string, latitude: number | null, longitude: number | null) {
  return LegacyApiClient.get(token, `${Resources.Announcements}/active`, {
    latitude,
    longitude,
  })
}

export function redeemPromos(token: string, userId: string, code: string) {
  return LegacyApiClient.post(token, 'promoAllocations', {
    userId,
    code,
  })
}

export async function listStops(token: string, query: object) {
  return LegacyApiClient.get(token, 'stops', query)
}

export async function getServer(mobileAppId: string): Promise<IGlobalMobileAppServerResponse> {
  const res = await getApi().global.getServer(mobileAppId)
  return res
}

export async function createTip(requestId: string, query: ITipPostBody): Promise<ITipResponse> {
  const res = await getApi(AuthenticatorHelper.getUserOrgToken()).requests.postTip(requestId, query)
  return res
}

export async function retrieveTip(requestId: string): Promise<ITipResponse> {
  const res = await getApi(AuthenticatorHelper.getUserOrgToken()).requests.getTip(requestId)
  return res
}

export async function getTipPolicy(id: string): Promise<ITipPolicyResponse> {
  const res = await getApi(AuthenticatorHelper.getUserOrgToken()).tipPolicies.get(id)
  return res
}

export async function getSupportedClientVersion(): Promise<ISupportedClientsGetResponse> {
  const res = await getApi().supportedClients.get(ClientType.Rider)
  return res
}

export async function getMobileAppTerms(id: string): Promise<ITermsResponse> {
  return getApi().public.getTerms({ mobileAppId: id })
}

export async function getOrganizationTerms(id: string): Promise<ITermsResponse> {
  return getApi().public.getTerms({ organizationId: id })
}

export function getAuthedApi(): ApiClient {
  return getApi(AuthenticatorHelper.getUserOrgToken())
}

export async function getJourneys(query: IJourneyEstimateQueryParams): Promise<IJourney[]> {
  const res = await getApi(AuthenticatorHelper.getUserOrgToken()).journeyEstimates.list(query)
  return res.data
}

export async function getRequestInsights(id: string): Promise<IRequestInsightsResponse> {
  const res = await getApi(AuthenticatorHelper.getUserOrgToken()).requests.getInsights(id)
  return res
}

function getApi(token?: string): ApiClient {
  return new ApiClient({
    host: AuthenticatorHelper.regionalHost ?? HostStore.getHost(),
    token,
    locale: LanguageHelper.getCurrentLanguageCodeFull(),
  })
}

// TO DO: Switch this over to use @sparelabs/api-client
export class LegacyApiClient {
  public static async post(token: string | undefined, path: string, body?: any, throws: boolean = false) {
    try {
      const res = await this.request(token).post(`${getApiBasePath()}/${path}`).send(body)
      ApiStore.handleNetworkSuccess()
      return res
    } catch (error) {
      return this.handleError(error, throws)
    }
  }

  public static async patch(token: string, path: string, body: any, throws: boolean = false) {
    try {
      const res = await this.request(token).patch(`${getApiBasePath()}/${path}`).send(body)
      ApiStore.handleNetworkSuccess()
      return res
    } catch (error) {
      return this.handleError(error, throws)
    }
  }

  public static async get(
    token: string,
    path: string,
    query: any = {},
    throws: boolean = false,
    forceUpdate: boolean = true
  ) {
    try {
      // When forceUpdate is true (the default) this request will not be cached
      const res = await this.request(token).get(`${getApiBasePath()}/${path}`).query(query).forceUpdate(forceUpdate)
      ApiStore.handleNetworkSuccess()
      return res
    } catch (error) {
      return this.handleError(error, throws)
    }
  }

  public static async delete(token: string, path: string, query: any = {}, throws: boolean = false) {
    try {
      const res = await this.request(token).delete(`${getApiBasePath()}/${path}`).query(query)
      ApiStore.handleNetworkSuccess()
      return res
    } catch (error) {
      return this.handleError(error, throws)
    }
  }

  public static async postImage(token: string, path: string, photoPath: string, throws: boolean = false) {
    try {
      const res = await request(token).post(`${getApiBasePath()}/${path}`).attach('upload', {
        uri: photoPath,
        name: 'photo.jpg',
        type: 'multipart/form-data',
      })
      ApiStore.handleNetworkSuccess()
      return res
    } catch (error) {
      return this.handleError(error, throws)
    }
  }

  private static throwNetworkError(error, throws: boolean) {
    if (throws) {
      throw new Error('Network Error')
    }
    return ApiStore.handleNetworkError(error)
  }

  private static handleError(error, throws: boolean = false) {
    if (!error.response || (error.response && error.response.statusCode >= 500)) {
      return this.throwNetworkError(error, throws)
    }
    throw error
  }

  private static request(token?: string): any {
    if (token) {
      return defaults()
        .set('Authorization', `Bearer ${token}`)
        .set('Accept-Language', LanguageHelper.getCurrentLanguageCode())
    }
    return superagent
  }
}
