import React from 'react'
import { Image, StyleSheet, TouchableOpacity } from 'react-native'
import { Images } from 'src/assets/Images'
import { st } from 'src/locales'
import { NavigationScreenTitle } from 'src/navigation/components/NavigationModalTitle'

const styles = StyleSheet.create({
  image: {
    height: 18,
    width: 18,
  },
  touchable: {
    paddingLeft: 12,
    paddingVertical: 12,
    width: 70,
  },
})

export const NavigationModalCloseButton = ({ onPress }: { onPress: () => void }) => (
  <TouchableOpacity
    style={styles.touchable}
    onPress={onPress}
    accessibilityLabel={st.common.accessibilityBackButton()}
    accessibilityHint={st.common.accessibilityBackButtonHint()}
    accessibilityRole='button'
  >
    <Image style={styles.image} source={Images.close} />
  </TouchableOpacity>
)

export const NavigationModalCloseButtonOptions = (navigation: any, title: string) => ({
  headerLeft: function headerLeft() {
    return <NavigationModalCloseButton onPress={() => navigation.pop()} />
  },
  headerTitle: function headerTitle() {
    return <NavigationScreenTitle title={title} />
  },
})
