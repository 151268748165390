import React, { useState } from 'react'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { Constants } from 'src/consts/Constants'
import { st } from 'src/locales'

interface ISsoLoginButtonProps {
  handleSsoLoginPress: (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void
}

export const SsoLoginButton = ({ handleSsoLoginPress }: ISsoLoginButtonProps): JSX.Element => {
  const [loading, setLoading] = useState(false)

  return (
    <PrimaryButton
      testID='ssoLoginButton'
      title={st.screens.login.ssoLoginLabel({
        ssoProvider: Constants.SSO_PROVIDER_NAME ?? '<SSO_PROVIDER_NAME not set>',
      })}
      onPress={() => handleSsoLoginPress(setLoading)}
      loading={loading}
    />
  )
}
