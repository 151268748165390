import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { st } from 'src/locales'
import { ParamsListSetPhoneNumber, ScreenName } from 'src/navigation'
import { NavigationModalCloseButtonOptions } from 'src/navigation/components/NavigationModalCloseButton'
import { NavigationScreenBackButtonOptions } from 'src/navigation/components/NavigationScreenBackButton'
import { NavigatorSettings } from 'src/navigation/consts/NavigatorSettings'
import { Login } from 'src/screens/login/Login'
import { SetPhoneNumber } from 'src/screens/login/SetPhoneNumber'
import { SetPhoneNumberConfirm } from 'src/screens/login/SetPhoneNumberConfirm'

const Stack = createStackNavigator<ParamsListSetPhoneNumber>()
const headerTitleAlign = NavigatorSettings.headerTitleAlign

export const NavigationStackSetPhoneNumber = (): JSX.Element => (
  <Stack.Navigator screenOptions={{ headerStatusBarHeight: NavigatorSettings.headerStatusBarHeight }}>
    <Stack.Screen
      name={ScreenName.Login}
      component={Login}
      options={({ navigation, route }) => ({
        ...NavigationModalCloseButtonOptions(navigation, route.params.title),
        headerTitleAlign,
      })}
    />
    <Stack.Screen
      name={ScreenName.SetPhoneNumber}
      component={SetPhoneNumber}
      options={({ navigation, route }) => ({
        ...NavigationScreenBackButtonOptions(navigation, route.params.title),
        headerTitleAlign,
      })}
    />
    <Stack.Screen
      name={ScreenName.SetPhoneNumberConfirm}
      component={SetPhoneNumberConfirm}
      options={({ navigation }) => ({
        ...NavigationScreenBackButtonOptions(navigation, st.screens.setPhoneNumberConfirm.title()),
        headerTitleAlign,
      })}
    />
  </Stack.Navigator>
)
