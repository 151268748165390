import { FontAwesomeIconStyle } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { GenericCard } from 'src/components/cards/GenericCard'
import { st } from 'src/locales'

const getStyles = () =>
  StyleSheet.create({
    card: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: 196,
    },
    overrideBaseCard: {
      backgroundColor: 'white',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      shadowColor: colors.shadowColor,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 3,
      shadowOpacity: 1,
      elevation: 3,
      height: '100%',
      marginHorizontal: 0,
    },
  })

export class NetworkErrorCard extends Component {
  public render() {
    const styles = getStyles()
    return (
      <View style={styles.card}>
        <GenericCard
          icon={{ file: [FontAwesomeIconStyle.Solid, 'times-circle'], size: 40, color: colors.blue50 }}
          message={st.errors.networkError()}
          overrideCardStyles={styles.overrideBaseCard}
        />
      </View>
    )
  }
}
