import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons'
import React, { Component } from 'react'
import { SafeAreaView, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderRadius: 5,
    zIndex: 1,
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  icon: {
    marginRight: 15,
  },
  title: {
    fontSize: 16,
    color: colors.textLight,
    flex: 1,
    flexWrap: 'wrap',
    lineHeight: 24,
  },
})

interface IProps {
  title: string
  icon: [IconPrefix, IconName]
  backgroundColor?: string
}

export class AnnouncementTitleBar extends Component<IProps> {
  public renderContent() {
    return (
      <>
        <FontAwesomeIconWrapper icon={this.props.icon} style={styles.icon} size={16} color={colors.textLight} />
        <Text style={styles.title}>{this.props.title}</Text>
      </>
    )
  }

  public getContainerStyle(): StyleProp<ViewStyle> {
    return [styles.container, this.props.backgroundColor ? { backgroundColor: this.props.backgroundColor } : null]
  }

  public render() {
    return (
      <SafeAreaView style={this.getContainerStyle()}>
        <View style={styles.contentContainer}>{this.renderContent()}</View>
      </SafeAreaView>
    )
  }
}
