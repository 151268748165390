import React from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { OsType } from 'src/util/types'

const styles = StyleSheet.create({
  title: {
    flex: 1,
    paddingHorizontal: 24,
    justifyContent: 'center',
  },
  webTitle: {
    flex: 1,
    maxWidth: 300,
  },
  text: {
    fontSize: 18,
    fontWeight: '500',
  },
})

export const NavigationScreenTitle = ({ title }: { title: string }): JSX.Element | null =>
  title ? (
    <View style={Platform.OS === OsType.Web ? styles.webTitle : styles.title}>
      <Text numberOfLines={1} style={styles.text}>
        {title}
      </Text>
    </View>
  ) : null
