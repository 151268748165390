import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons'
import { AnnouncementIcon, AnnouncementIconFontAwesomeMap } from '@sparelabs/api-client'

export class AnnouncementHelper {
  public static getAnnouncementIcon(icon: AnnouncementIcon): [IconPrefix, IconName] {
    // TODO: AnnouncementIconFontAwesomeMap is from @sparelabs/api-client which does not use the correct types for FontAwesome icons
    // However, we should be maintaining the types through the app code, hence the usage of casting
    return AnnouncementIconFontAwesomeMap[icon] as [IconPrefix, IconName]
  }
}
