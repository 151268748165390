import { createStackNavigator } from '@react-navigation/stack'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { st } from 'src/locales'
import { ScreenName } from 'src/navigation'
import { getScreenOptionsWithBackButton, getScreenOptionsWithCloseButton } from 'src/navigation/consts/NavigationHelper'
import { NavigatorSettings } from 'src/navigation/consts/NavigatorSettings'
import { ParamsListAccount } from 'src/navigation/types/ScreenParamsListTypes'
import { Account } from 'src/screens/Account'
import { FarePass } from 'src/screens/farePasses/FarePass'
import { FarePassHistoryList } from 'src/screens/farePasses/FarePassHistoryList'
import { FarePassList } from 'src/screens/farePasses/FarePassList'
import { Favorites } from 'src/screens/favorites/FavoriteLocationScreen'
import { FleetAgreementDetails } from 'src/screens/fleetAgreements/FleetAgreementDetails'
import { FleetAgreementList } from 'src/screens/fleetAgreements/FleetAgreementList'
import { GroupDetails } from 'src/screens/groups/GroupDetails'
import { GroupMemberships } from 'src/screens/groups/GroupMemberships'
import { JoinGroup } from 'src/screens/groups/JoinGroup'
import { Legal } from 'src/screens/Legal'
import { PaymentMethods } from 'src/screens/payments/PaymentMethods'
import { ViewCash } from 'src/screens/payments/ViewCash'
import { ViewCreditCard } from 'src/screens/payments/ViewCreditCard'
import { ViewNimoca } from 'src/screens/payments/ViewNimoca'
import { ViewSepa } from 'src/screens/payments/ViewSepa'
import { AddPromo } from 'src/screens/promos/AddPromo'
import { ListPromoScreen } from 'src/screens/promos/ListPromoScreen'
import { PastTrip } from 'src/screens/request/PastTrips/PastTrip'
import { PastTrips } from 'src/screens/request/PastTrips/PastTripsList'

const Stack = createStackNavigator<ParamsListAccount>()

export const NavigationStackAccount = observer(() => (
  <Stack.Navigator
    screenOptions={{
      headerStatusBarHeight: NavigatorSettings.headerStatusBarHeight,
    }}
  >
    <Stack.Screen
      name={ScreenName.Account}
      component={Account}
      options={({ navigation }) => getScreenOptionsWithCloseButton(navigation, st.screens.account.title())}
    />
    <Stack.Screen
      name={ScreenName.FavoritesListScreen}
      component={Favorites}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.favorites.title())}
    />
    <Stack.Screen
      name={ScreenName.FarePassList}
      component={FarePassList}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.farePasses.title())}
    />
    <Stack.Screen
      name={ScreenName.FarePassHistoryList}
      component={FarePassHistoryList}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.farePassHistoryList.title())}
    />
    <Stack.Screen
      name={ScreenName.ViewFarePass}
      component={FarePass}
      options={({ navigation, route }) => getScreenOptionsWithBackButton(navigation, route.params.farePassName)}
    />
    <Stack.Screen
      name={ScreenName.PaymentMethods}
      component={PaymentMethods}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.paymentMethods.title())}
    />
    <Stack.Screen
      name={ScreenName.ViewCash}
      component={ViewCash}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.viewCash.title())}
    />
    <Stack.Screen
      name={ScreenName.ViewNimoca}
      component={ViewNimoca}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.viewNimoca.title())}
    />
    <Stack.Screen
      name={ScreenName.ViewSepa}
      component={ViewSepa}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.viewSepa.title())}
    />
    <Stack.Screen
      name={ScreenName.ViewCreditCard}
      component={ViewCreditCard}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.viewCreditCard.title())}
    />
    <Stack.Screen
      name={ScreenName.Legal}
      component={Legal}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.account.legal())}
    />
    <Stack.Screen
      name={ScreenName.GroupMemberships}
      component={GroupMemberships}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.group.groupMembershipsTitle())}
    />
    <Stack.Screen
      name={ScreenName.JoinGroup}
      component={JoinGroup}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.group.joinGroup())}
    />
    <Stack.Screen
      name={ScreenName.GroupDetails}
      component={GroupDetails}
      options={({ navigation, route }) => getScreenOptionsWithBackButton(navigation, route.params.group.name)}
    />
    <Stack.Screen
      name={ScreenName.FleetAgreementList}
      component={FleetAgreementList}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.fleetAgreementList.title())}
    />
    <Stack.Screen
      name={ScreenName.FleetAgreementsDetails}
      component={FleetAgreementDetails}
      options={({ navigation, route }) => getScreenOptionsWithBackButton(navigation, route.params.fleet.data.name)}
    />
    <Stack.Screen
      name={ScreenName.PastTripsList}
      component={PastTrips}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.pastTrips.title())}
    />
    <Stack.Screen
      name={ScreenName.PastTrip}
      component={PastTrip}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.pastTrip.title())}
    />
    <Stack.Screen
      name={ScreenName.ListPromo}
      component={ListPromoScreen}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.listPromoScreen.title())}
    />
    <Stack.Screen
      name={ScreenName.AddPromo}
      component={AddPromo}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.addPromo.title())}
    />
  </Stack.Navigator>
))
