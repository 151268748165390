import React from 'react'
import { ImageRequireSource, StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
  },
  cell: {
    height: 70,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    flexBasis: 'auto',
    backgroundColor: 'white',
  },
})

export interface IProps {
  id: string
  title: string
  subTitle?: string
  withHeader?: boolean
  titleImage?: ImageRequireSource
  color?: string
  titleColor?: string
  onPressItem: (id: string) => void
  selected: boolean
  testID?: string
}

export const SettingsActionItem = (props: IProps) => {
  const titleStyle = (color: string) => ({
    flex: 1,
    fontSize: 16,
    paddingHorizontal: 16,
    color,
  })

  return (
    <View style={props.withHeader ? styles.container : null}>
      <TouchableListItemWrapper
        style={styles.cell}
        onPress={() => props.onPressItem(props.id)}
        testPropertiesKey={props.testID}
      >
        <Text
          style={
            props.color
              ? (titleStyle(props.color) as StyleProp<TextStyle>)
              : (titleStyle(colors.textDark) as StyleProp<TextStyle>)
          }
        >
          {props.title}
        </Text>
      </TouchableListItemWrapper>
    </View>
  )
}
