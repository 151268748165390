import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { st } from 'src/locales'

interface IRetryButtonProps {
  isLoading: boolean
  onRetryPress: () => Promise<void>
}

export const RetryButton = ({ onRetryPress, isLoading }: IRetryButtonProps): JSX.Element => (
  <>
    <View>
      <Text style={styles.smallText}>{st.screens.login.noConnection()}</Text>
    </View>
    <PrimaryButton
      testID='retryButton'
      title={st.screens.login.retryLabel()}
      onPress={async () => {
        await onRetryPress()
      }}
      loading={isLoading}
    />
  </>
)

const styles = StyleSheet.create({
  smallText: {
    color: colors.gray70,
    fontSize: 14,
    textAlign: 'center',
    paddingVertical: 16,
  },
})
