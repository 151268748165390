import Lottie from 'lottie-react-web'
import React from 'react'
import { useWindowDimensions } from 'react-native'
import {
  ILottieAnimation,
  ILottieAnimationInputProps,
  ILottieAnimationSizeProps,
  SizePercent,
} from './LottieAnimationTypes'

const getSize = (size: SizePercent | number, fullDimensionSize: number) => {
  if (typeof size === 'number') {
    return size
  }

  if (size.charAt(size.length - 1) === '%') {
    const sizeRatio = parseInt(size.slice(0, -1)) / 100
    return fullDimensionSize * sizeRatio
  }

  return 0
}

interface ICalculateDimensionsInput extends ILottieAnimationSizeProps {
  windowHeight: number
  windowWidth: number
}

const calculateDimensions = ({
  height,
  maxHeight,
  width,
  maxWidth,
  windowHeight,
  windowWidth,
}: ICalculateDimensionsInput): { calculatedHeight: number | undefined; calculatedWidth: number | undefined } => {
  let calculatedHeight: number = 0
  let calculatedWidth: number = 0

  if (height && !maxHeight) {
    calculatedHeight = getSize(height, windowHeight)
  }
  if (height && maxHeight) {
    const baseHeight = getSize(height, windowHeight)
    const calculatedMaxHeight = getSize(maxHeight, windowHeight)
    calculatedHeight = Math.min(baseHeight, calculatedMaxHeight)
  }
  if (width && !maxWidth) {
    calculatedWidth = getSize(width, windowWidth)
  }
  if (width && maxWidth) {
    const baseWidth = getSize(width, windowWidth)
    const calculatedMaxWidth = getSize(maxWidth, windowWidth)
    calculatedWidth = Math.min(baseWidth, calculatedMaxWidth)
  }

  return {
    calculatedHeight: calculatedHeight > 0 ? calculatedHeight : undefined,
    calculatedWidth: calculatedWidth > 0 ? calculatedWidth : undefined,
  }
}

export const LottieAnimation: ILottieAnimation = (props: ILottieAnimationInputProps): JSX.Element => {
  const { source, autoPlay = true, loop = false, ...restProps } = props
  const { height: windowHeight, width: windowWidth } = useWindowDimensions()
  const { calculatedHeight, calculatedWidth } = calculateDimensions({ ...restProps, windowHeight, windowWidth })

  return (
    <Lottie
      options={{ animationData: source, autoplay: autoPlay, loop }}
      height={calculatedHeight}
      width={calculatedWidth}
    />
  )
}
