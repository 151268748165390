import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native'
import { StripeConfirmIntentWebView } from 'src/components/stripeConfirmIntentWebView/StripeConfirmIntentWebView'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'

type Props = ParamsListRoot[ScreenName.ConfirmStripePayment] & {
  handleDismiss: () => void
}

const ConfirmStripePaymentView = (props: Props) => {
  const [isComplete, setIsComplete] = useState(false)

  // We do this to prevent accidentally calling the confirm complete callback twice
  const onConfirmComplete = (success: boolean) => {
    if (isComplete) {
      return
    }
    setIsComplete(true)
    props.onConfirmComplete(success)
  }

  // when the component is unmounted and we have not yet confirmed then we should confirm with the assumption that it failed
  useEffect(
    () => () => {
      onConfirmComplete(false)
    },
    []
  )

  return (
    <>
      {/* SafeAreaView is added only needs to apply at the top of webview */}
      <SafeAreaView />
      <StripeConfirmIntentWebView
        stripePublicKey={props.stripePublicKey}
        clientSecret={props.clientSecret}
        onSuccess={async () => {
          onConfirmComplete(true)
          props.handleDismiss()
        }}
        onCancel={() => {
          onConfirmComplete(false)
          props.handleDismiss()
        }}
      />
    </>
  )
}

export const ConfirmStripePayment = (props: ScreenPropsRoot<ScreenName.ConfirmStripePayment>) => (
  <ConfirmStripePaymentView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
