import React from 'react'
import { ITransitPolylineProps } from 'src/components/mapMarkers/MapMarkerProps'
import { Polyline } from 'src/components/mapMarkers/polyline/Polyline'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { getRouteColour, getTransitPolylineCoordinates } from 'src/screens/rideOptions/JourneyMapHelper'

/**
 * Creates a polyline between the boarding and exit location for a transit leg
 */
export const TransitPolyline = (props: ITransitPolylineProps) => {
  const { transitLeg } = props
  const polylineCoordinates = getTransitPolylineCoordinates(transitLeg)
  const routeColour = getRouteColour(transitLeg.routeColor)
  return (
    <Polyline
      strokeWidth={MapMarkerHelper.getPolylineStrokeWidth()}
      strokeColor={routeColour}
      coordinates={polylineCoordinates}
    />
  )
}
