import React, { useState } from 'react'
import { WebView } from 'react-native-webview'
import { WebViewNavigationEvent } from 'react-native-webview/lib/WebViewTypes'
import { stripeConfirmIntentRedirectHTML } from './stripeConfirmIntentRedirectHTML'
import { StripeQueryParams } from './types'

/**
 * This component will perform a stripe redirect inside of a web view
 * Heavily based on a similar implementation for checkout sessions https://github.com/sparelabs/react-native-stripe-checkout-webview
 */

interface IProps {
  /** Stripe public key */
  stripePublicKey: string
  /** Stripe Checkout Session input */
  clientSecret: string
  /** Called when the Stripe checkout session completes with status 'success' */
  onSuccess: () => any
  /** Called when the Stripe checkout session completes with status 'cancel' */
  onCancel: () => any
}

export const StripeConfirmIntentWebView = (props: IProps) => {
  const { stripePublicKey, clientSecret, onSuccess, onCancel } = props
  /** Holds the complete URL if exists */
  const [completed, setCompleted] = useState<boolean | null>(null)

  /**
   * Called every time the URL stats to load in the webview
   * handles completing the checkout session
   */
  const _onLoadStart = (syntheticEvent: WebViewNavigationEvent) => {
    const { nativeEvent } = syntheticEvent
    const { url: currentUrl } = nativeEvent
    /** Check and handle checkout state: success */
    if (currentUrl.includes(StripeQueryParams.Success)) {
      setCompleted(true)
      onSuccess?.()
      return
    }
    /** Check and handle checkout state: cancel */
    if (currentUrl.includes(StripeQueryParams.Cancel)) {
      setCompleted(true)
      onCancel?.()
    }
  }

  /** If the checkout session is complete we don't need to render anything now */
  if (completed) {
    return null
  }

  /** Render the WebView holding the Stripe checkout flow */
  return (
    <WebView
      /** pass baseUrl to avoid  `IntegrationError: Live Stripe.js integrations must use HTTPS.` error https://github.com/react-native-community/react-native-webview/issues/1317 */
      baseUrl=''
      originWhitelist={['*']}
      source={{
        html: stripeConfirmIntentRedirectHTML(stripePublicKey, clientSecret),
        // Ensure an https baseUrl is used to avoid infinite loading on production due to https://github.com/A-Tokyo/react-native-stripe-checkout-webview/issues/10
        baseUrl: 'https://stripe.com',
      }}
      onLoadStart={_onLoadStart}
    />
  )
}
