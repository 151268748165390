import React, { ReactNode } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { colors } from 'src/assets/colors'

const styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    marginHorizontal: 8,
    backgroundColor: 'white',
    shadowColor: colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3,
    shadowOpacity: 1,
    elevation: 3,
    marginBottom: 10,
  },
})

interface IProps {
  children: ReactNode
  containerStyle?: StyleProp<ViewStyle>
  testID?: string
}

export const BaseCard = (props: IProps) => (
  <View style={[styles.container, props.containerStyle]} testID={props.testID}>
    {props.children}
  </View>
)
