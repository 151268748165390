import { FontAwesomeIconStyle } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'

const styles = StyleSheet.create({
  backgroundCircle: {
    position: 'absolute',
    top: 1,
    right: 1,
    borderColor: colors.white,
    borderWidth: 3,
    borderRadius: 3,
  },
})

interface IProps {
  outerColor: string
}

export class EnrouteCircle extends Component<IProps> {
  public render() {
    const { outerColor } = this.props
    return (
      <View>
        <View style={styles.backgroundCircle} />
        <FontAwesomeIconWrapper icon={[FontAwesomeIconStyle.Solid, 'dot-circle']} size={8} color={outerColor} />
      </View>
    )
  }
}
