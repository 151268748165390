import { RequestCancellationReason } from '@sparelabs/api-client'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'

const t = st.screens.request

export class RequestCompletionAlerts {
  public static showImmediateNDAAlert() {
    this.renderAlert(t.rideNoLongerAvailableTitle(), t.rideNoLongerAvailableContent())
  }

  /**
   *
   * @param pickupTs This should preferably be scheduled pickup time. If not available, then use requested pick up time.
   */
  public static showNDAAlert(pickupTs: number) {
    this.renderAlert(t.rideNDATitle(), t.rideNDAContent({ rideTime: this.getRideTime(pickupTs) }))
  }

  /**
   *
   * @param pickupTs This should preferably be scheduled pickup time. If not available, then use requested pick up time
   * @param cancellationReason Cancellation reason
   */
  public static showCancelledAlert(pickupTs: number, cancellationReason: RequestCancellationReason) {
    const rideTime = this.getRideTime(pickupTs)
    if (cancellationReason === RequestCancellationReason.NoShow) {
      this.renderAlert(t.rideCancelledTitle(), t.rideCancelledNoShowContent({ rideTime }))
    } else {
      this.renderAlert(t.rideCancelledTitle(), t.rideCancelledContent({ rideTime }))
    }
  }

  private static renderAlert(title: string, content: string) {
    AlertHelper.alert(title, content, [{ text: st.common.alertOk() }], {
      cancelable: false,
    })
  }

  private static getRideTime(pickupTs: number): string {
    return moment.unix(pickupTs).calendar()
  }
}
