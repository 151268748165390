import { toJS } from 'mobx'
import React from 'react'
import { SVGOverlay } from 'react-map-gl'
import { IPolylineProps } from 'src/components/mapMarkers/MapMarkerProps'
import { coordinatesToLineString } from 'src/helpers/MapHelper'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'

const getStyle = (props: IPolylineProps) => ({
  stroke: props.strokeColor,
  strokeWidth: props.strokeWidth,
  strokeLinecap: props.lineCap,
  strokeDasharray: props.lineDashPattern ? MapMarkerHelper.getStringDashArray(props.lineDashPattern) : '',
})

export const Polyline = (props: IPolylineProps): JSX.Element | null => {
  if (!props.coordinates) {
    return null
  }

  const polyline = coordinatesToLineString(props.coordinates)
  const style = getStyle(props)
  return (
    <SVGOverlay
      redraw={({ project }) => (
        <g style={{ pointerEvents: 'visibleStroke' }}>
          <path style={{ fill: 'none', ...style }} d={`M${toJS(polyline.coordinates).map(project).join('L')}`} />
        </g>
      )}
    />
  )
}
