/**
 * Code based on https://github.com/bothrs/expo-mixpanel-analytics
 */
import { SnakeCase } from '@sparelabs/core'
import { Buffer } from 'buffer'
import Constants from 'expo-constants'
import * as Device from 'expo-device'
import { Dimensions, Platform } from 'react-native'

const MIXPANEL_API_URL = 'https://api.mixpanel.com'

export interface IEventProps {
  [key: string]: string | number | undefined | null
}

interface IEvent {
  name: string
  properties?: IEventProps
  sent?: boolean
}

export class ExpoMixpanelAnalytics {
  private ready = false
  private readonly queue: IEvent[] = []
  private properties: {
    token: string
    distinct_id?: string
  } & IEventProps

  constructor(token: string, getDistinctIdAsync: () => Promise<string>) {
    this.properties = {
      token,
      app_id: Constants.manifest?.slug,
      app_name: Constants.manifest?.name,
      app_native_version_string: Constants.manifest?.version,
      app_native_build_number: Constants.manifest?.revisionId,
      device_name: Constants.deviceName,
      // Weird ternary is required because of https://docs.expo.dev/build-reference/migrating/#constantsappownership--will-be--null-
      expo_app_ownership: Constants.appOwnership === null ? 'standalone' : Constants.appOwnership ?? undefined,
      os_version: Platform.Version,
      platform: Platform.OS,
      brand: Device.brand ?? '',
      model_id: (Device.modelId as string | null) ?? '',
      model_name: Device.modelName ?? '',
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Promise.all([getDistinctIdAsync(), Constants.getWebViewUserAgentAsync()]).then(([distinctId, userAgent]) => {
      const { width, height } = Dimensions.get('window')
      this.properties = {
        ...this.properties,
        distinct_id: distinctId,
        user_agent: userAgent ?? '',
        screen_height: height,
        screen_size: `${width}x${height}`,
        screen_width: width,
      }
      this.ready = true
      this.flush()
    })
  }

  public track(name: string, properties?: IEventProps): void {
    this.queue.push({
      name,
      properties,
    })
    this.flush()
  }

  private flush() {
    if (this.ready) {
      while (this.queue.length) {
        const event = this.queue.pop()
        if (event) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.pushEvent(event).then(() => (event.sent = true))
        }
      }
    }
  }

  private async pushEvent(event: IEvent): Promise<void> {
    const data = {
      // converting event name to snake case to improve readability
      event: SnakeCase.camelCaseToSnakeCase(event.name),
      properties: {
        ...this.properties,
        // converting all keys to snake case to improve readability
        ...SnakeCase.objectCamelCaseToSnakeCase(event.properties || {}),
      },
    }
    const buffer = Buffer.from(JSON.stringify(data)).toString('base64')
    await fetch(`${MIXPANEL_API_URL}/track/?data=${buffer}`)
  }
}
