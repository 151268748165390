import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { st } from 'src/locales'
import { ParamsListDeveloperMenu, ScreenName } from 'src/navigation'
import { NavigationModalCloseButtonOptions } from 'src/navigation/components/NavigationModalCloseButton'
import { NavigationScreenBackButtonOptions } from 'src/navigation/components/NavigationScreenBackButton'
import { NavigatorSettings } from 'src/navigation/consts/NavigatorSettings'
import { DeveloperHostSelect } from 'src/screens/developer/DeveloperHostSelect'
import { DeveloperMenu } from 'src/screens/developer/DeveloperMenu'
import { DeveloperReviewerLogin } from 'src/screens/developer/DeveloperReviewerLogin'

const Stack = createStackNavigator<ParamsListDeveloperMenu>()
const headerTitleAlign = NavigatorSettings.headerTitleAlign

export const NavigationStackDeveloperMenu = () => (
  <Stack.Navigator screenOptions={{ headerStatusBarHeight: NavigatorSettings.headerStatusBarHeight }}>
    <Stack.Screen
      name={ScreenName.DeveloperMenu}
      component={DeveloperMenu}
      options={({ navigation }) => ({
        ...NavigationModalCloseButtonOptions(navigation, st.screens.developerMenu.title()),
        headerTitleAlign,
      })}
    />
    <Stack.Screen
      name={ScreenName.DeveloperHostSelect}
      component={DeveloperHostSelect}
      options={({ navigation }) => ({
        ...NavigationScreenBackButtonOptions(navigation, st.screens.developerHostSelect.title()),
        headerTitleAlign,
      })}
    />
    <Stack.Screen
      name={ScreenName.DeveloperReviewerLogin}
      component={DeveloperReviewerLogin}
      options={({ navigation }) => ({
        ...NavigationScreenBackButtonOptions(navigation, st.screens.developerReviewerLogin.title()),
        headerTitleAlign,
      })}
    />
  </Stack.Navigator>
)
