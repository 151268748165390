import { DiscountType } from '@sparelabs/api-client'
import { capitalCase } from 'change-case'
import { st } from 'src/locales'

export class DiscountNameRenderer {
  public static getDiscountName(type: DiscountType | string) {
    const { discountDetails } = st.screens
    switch (type) {
      case DiscountType.Promo:
        return discountDetails.promo()
      case DiscountType.TimeBased:
        return discountDetails.timeBasedPass()
      case DiscountType.StoredValue:
        return discountDetails.storedValuePass()
      default:
        return discountDetails.discount() + ' • ' + capitalCase(type)
    }
  }
}
