import { dateFormat } from '@sparelabs/time'
import { DateInput, DateTimeInput as WebDateTimeInput } from '@sparelabs/web-ui-components'
import React from 'react'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { DateTimePickerMode, IDateTimeInput, IDateTimeInputProps } from './DateTimeInputTypes'

const styles = StyleSheet.create({
  title: {
    marginBottom: 8,
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
  },
})

export const DateTimeInput: IDateTimeInput = (props: IDateTimeInputProps): JSX.Element => {
  const onDateTimeChange = (value?: number) => {
    if (value) {
      props.onHandleDateChange(moment.unix(value))
    }
  }

  const onDateChange = (date?: string) => {
    if (date) {
      props.onHandleDateChange(moment(date, dateFormat))
    }
  }

  const renderDateInputOnly = () => (
    <DateInput
      value={props.date.format(dateFormat)}
      onChange={onDateChange}
      minDate={props.minimumDate?.format(dateFormat)}
    />
  )

  const renderDateTimeInput = () => (
    <WebDateTimeInput
      value={props.date.unix()}
      onChange={onDateTimeChange}
      minDate={props.minimumDate?.format(dateFormat)}
    />
  )

  const renderTitle = (mode?: DateTimePickerMode): JSX.Element => {
    const selectDateString = `${st.components.dateTimePicker.select()} ${st.components.dateTimePicker.date()}`
    if (mode === DateTimePickerMode.Date) {
      return <Text>{selectDateString}</Text>
    }

    return <Text>{`${selectDateString} ${st.common.conjunction()} ${st.components.dateTimePicker.time()}`}</Text>
  }

  return (
    <SafeAreaView>
      {props.showTitle && <View style={styles.title}> {renderTitle(props.mode)} </View>}
      <View style={styles.inputContainer}>
        {props.mode !== DateTimePickerMode.Date ? renderDateTimeInput() : renderDateInputOnly()}
      </View>
    </SafeAreaView>
  )
}
