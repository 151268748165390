import { IAnnouncementResponse } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { FlatList, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { WebViewHelper } from 'src/helpers/WebViewHelper'
import { st } from 'src/locales'
import { ScreenName, ScreenPropsRoot } from 'src/navigation'
import { AnnouncementStore } from 'src/stores/AnnouncementStore'
import { AnnouncementItem } from './AnnouncementItem'

const styles = StyleSheet.create({
  list: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  title: {
    fontSize: 16,
    color: colors.textDark,
    paddingBottom: 15,
    lineHeight: 24,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
  },
})

interface IProps {
  handleActionPress: (uri: string | null) => void
}

export class ListAnnouncementsView extends Component<IProps> {
  public renderAnnouncement = ({ item: announcement }: { item: IAnnouncementResponse; index: number }) => (
    <AnnouncementItem announcement={announcement} handleActionPress={this.props.handleActionPress} />
  )

  public renderEmptyList() {
    return (
      <View style={styles.emptyContainer}>
        <Text style={styles.title}>{st.screens.listAnnouncements.noListAnnouncements()}</Text>
      </View>
    )
  }

  public render() {
    return (
      <FlatList
        style={styles.list}
        data={AnnouncementStore.announcements}
        keyExtractor={(item) => item.id}
        renderItem={this.renderAnnouncement}
        ListEmptyComponent={this.renderEmptyList()}
      />
    )
  }
}

export const ListAnnouncements = (props: ScreenPropsRoot<ScreenName.ListAnnouncements>) => (
  <ListAnnouncementsView
    handleActionPress={(uri: string | null) => {
      if (uri) {
        WebViewHelper.handleLink({ uri }, props.navigation.navigate.bind(ListAnnouncements))
      }
    }}
  />
)
