import React from 'react'
import { ActivityIndicator, StyleSheet, Text, View, ViewStyle } from 'react-native'
import { colors } from 'src/assets/colors'
import { TouchableButtonWrapper } from 'src/components/touchableButtonWrapper/TouchableButtonWrapper'

const styles = StyleSheet.create({
  touchable: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    borderRadius: 8,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  shadow: {
    shadowColor: colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
    elevation: 3,
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.textLight,
  },
  subtitle: {
    fontSize: 14,
    color: colors.textLight,
  },
})

type IProps = IButtonProps & {
  textColor: string
  backgroundColor: string
  children?: JSX.Element
  containerStyle?: ViewStyle
  textContainerStyles?: ViewStyle
}

// These props refer to the button usage
export interface IButtonProps {
  loading?: boolean
  disabled?: boolean
  shadow?: boolean
  title: string
  subtitle?: string
  onPress: () => void
  testID?: string
  paddingHorizontal?: boolean
}

export const AbstractButton = (props: IProps) => {
  const renderTitle = () => {
    const title = <Text style={[styles.title, textColor()]}>{props.title}</Text>
    const subtitle = props.subtitle ? <Text style={[styles.subtitle, textColor()]}>{props.subtitle}</Text> : null
    return (
      <View style={[styles.textContainer, props.textContainerStyles]}>
        {title}
        {subtitle}
      </View>
    )
  }

  const renderTitleOrLoading = () => {
    if (props.loading) {
      return <ActivityIndicator color='white' />
    }
    return renderTitle()
  }

  const backgroundColor = () => ({
    backgroundColor: props.backgroundColor,
  })

  const textColor = () => ({
    color: props.textColor,
  })

  const disabled: boolean = Boolean(props.loading) || Boolean(props.disabled)

  return (
    <TouchableButtonWrapper
      onPress={props.onPress}
      style={[styles.touchable, props.paddingHorizontal ? { paddingHorizontal: 16 } : null]}
      disabled={disabled}
      label={props.title}
      testID={props.testID}
    >
      <View style={[styles.container, props.containerStyle, backgroundColor(), props.shadow ? styles.shadow : null]}>
        {renderTitleOrLoading()}
        {props.children}
      </View>
    </TouchableButtonWrapper>
  )
}
