import React, { Component } from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { WebView as ReactWebView } from 'react-native-webview'
import { ScreenName, ScreenPropsRoot } from 'src/navigation'

const styles = StyleSheet.create({
  loadingIndicator: {
    padding: 50,
  },
})

interface IProps {
  uri: string
}

class WebViewView extends Component<IProps> {
  private readonly renderLoadingView = () => (
    <ActivityIndicator color='black' size='small' style={styles.loadingIndicator} />
  )

  public render() {
    return (
      <ReactWebView
        renderLoading={this.renderLoadingView}
        startInLoadingState={true}
        source={{ uri: this.props.uri }}
      />
    )
  }
}

export const WebViewModal = (props: ScreenPropsRoot<ScreenName.WebViewModal>) => (
  <WebViewView uri={props.route.params.uri} />
)
