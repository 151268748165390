import React, { useState } from 'react'
import { WebViewHelper } from 'src/helpers/WebViewHelper'
import {
  FarePassScreenStateType,
  ParamsListAccount,
  ParamsListRoot,
  ScreenName,
  ScreenPropsAccount,
} from 'src/navigation'
import { FarePassAllocationView } from '../../components/farePasses/FarePassAllocationView'
import { PurchaseFarePassView } from '../../components/farePasses/PurchaseFarePassView'

type Props = ParamsListAccount[ScreenName.ViewFarePass] & {
  handleNavigateConfirmPurchase: (params: ParamsListRoot[ScreenName.ConfirmPurchase]) => void
  handleNavigateWebView: (params: ParamsListRoot[ScreenName.WebViewModal]) => void
}

export const FarePassView = (props: Props) => {
  const [screenState, setScreenState] = useState(props.state)
  const onPurchaseComplete = (allocationId) => {
    setScreenState({
      type: FarePassScreenStateType.FarePassAllocation,
      allocationId,
    })
  }
  if (screenState.type === FarePassScreenStateType.FarePass) {
    return (
      <PurchaseFarePassView
        farePassId={screenState.farePassId}
        onPurchaseComplete={onPurchaseComplete}
        handleNavigateConfirmPurchase={props.handleNavigateConfirmPurchase}
      />
    )
  }
  return (
    <FarePassAllocationView
      allocationId={screenState.allocationId}
      handleNavigateWebView={props.handleNavigateWebView}
    />
  )
}

export const FarePass = (props: ScreenPropsAccount<ScreenName.ViewFarePass>) => (
  <FarePassView
    {...props.route.params}
    handleNavigateConfirmPurchase={(params) => props.navigation.navigate(ScreenName.ConfirmPurchase, params)}
    handleNavigateWebView={(params) => {
      WebViewHelper.handleLink(params, props.navigation.navigate.bind(FarePass))
    }}
  />
)
