import { IPoint } from '@sparelabs/geography'
import React, { Component } from 'react'
import { Images } from 'src/assets/Images'
import { ImageMarker } from 'src/components/mapMarkers/ImageMarker'
import { IMarkerProps } from 'src/components/mapMarkers/MapMarkerProps'
import { ANDROID_ANCHOR, mapMarkerStyles } from 'src/helpers/MapMarkerHelper'
import { IMarker, MarkerTypes } from 'src/types'

/**
 * Marks the location of the current area of which organizations are being searched from
 */
export class SearchLocationMarker extends Component<IMarkerProps> {
  public render() {
    return <ImageMarker {...this.createPin(this.props.location)} />
  }

  private readonly createPin = (searchLocation: IPoint): IMarker => ({
    name: MarkerTypes.SearchLocationPin,
    coordinate: searchLocation,
    image: Images.searchLocationMarker,
    anchor: ANDROID_ANCHOR,
    imageStyle: mapMarkerStyles.searchLocationPin,
  })
}
