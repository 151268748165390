import BottomSheet, { BottomSheetProps } from '@gorhom/bottom-sheet'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useAsync } from 'react-async-hook'
import { AccessibilityInfo } from 'react-native'

const SCREEN_READER_CHANGED_LISTENER = 'screenReaderChanged'

export enum AccessibleBottomSheetPosition {
  Top = 1,
  Initial = 0,
}
interface IProps extends BottomSheetProps {
  children: ReactNode
  onChangePosition?: (position: AccessibleBottomSheetPosition) => void
}

export const AccessibleBottomSheetView = (props: IProps) => {
  const bottomSheetRef = useRef<BottomSheet>(null)
  const [bottomSheetPosition, setBottomSheetPosition] = useState<AccessibleBottomSheetPosition>(
    AccessibleBottomSheetPosition.Initial
  )

  useAsync(async () => {
    if (await AccessibilityInfo.isScreenReaderEnabled()) {
      setBottomSheetPosition(AccessibleBottomSheetPosition.Top)
      bottomSheetRef.current?.expand()
    }
  }, [bottomSheetPosition])

  useEffect(() => {
    AccessibilityInfo.addEventListener(SCREEN_READER_CHANGED_LISTENER, handleScreenReaderToggled)

    return () => {
      AccessibilityInfo.removeEventListener(SCREEN_READER_CHANGED_LISTENER, handleScreenReaderToggled)
    }
  }, [])

  const handleScreenReaderToggled = (screenReaderEnabled: boolean | ((prevState: boolean) => boolean)) => {
    screenReaderEnabled ? bottomSheetRef.current?.expand() : bottomSheetRef.current?.collapse()
  }

  const handleOnChange = (position: number) => {
    setBottomSheetPosition(position as AccessibleBottomSheetPosition)

    if (props.onChangePosition) {
      props.onChangePosition(position as AccessibleBottomSheetPosition)
    }
  }

  return (
    <BottomSheet {...props} ref={bottomSheetRef} onChange={handleOnChange}>
      {props.children}
    </BottomSheet>
  )
}
