export { ServiceType, RequestStatus, ConnectedAccountType, SlotStatus, SlotType } from '@sparelabs/api-client'

export enum OsType {
  Android = 'android',
  iOS = 'ios',
  Web = 'web',
}

export enum AlertButtonStyle {
  Default = 'default',
  Cancel = 'cancel',
  Destructive = 'destructive',
}
