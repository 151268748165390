import { AlertDialog, IAlertDialogButton } from '@sparelabs/web-ui-components'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { AlertButton } from 'react-native'
import { st } from 'src/locales'
import { WebAlertStore } from 'src/stores/WebAlertStore'

const DEFAULT_BUTTONS: IAlertDialogButton[] = [
  {
    text: st.common.alertOk(),
    onPress: () => {
      WebAlertStore.clearAlert()
    },
  },
]

export const WebAlert: React.FC = observer(() => {
  if (!WebAlertStore.showAlert) {
    return null
  }

  const formatButton = ({ text, onPress }: AlertButton) => ({
    text: text ?? '',
    onPress: () => {
      if (onPress) {
        onPress()
      }
      WebAlertStore.clearAlert()
    },
  })

  const buttons: IAlertDialogButton[] =
    WebAlertStore.buttons && WebAlertStore.buttons.length
      ? WebAlertStore.buttons.map((button) => formatButton(button))
      : DEFAULT_BUTTONS

  return <AlertDialog buttons={buttons} title={WebAlertStore.title ?? ''} message={WebAlertStore.message} />
})
