import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import {
  FlatList,
  ImageRequireSource,
  ImageStyle,
  ImageURISource,
  Platform,
  RefreshControl,
  StyleProp,
  StyleSheet,
  View,
} from 'react-native'
import { colors } from 'src/assets/colors'
import { OrganizationItem } from 'src/components/settings/OrganizationItem'
import { SelectItem } from 'src/components/settings/SelectItem'
import { OsType } from '../../util/types'
import { SettingsActionItem } from './SettingsActionItem'
import { SettingsListItem } from './SettingsListItem'

const styles = StyleSheet.create({
  list: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  separator: {
    backgroundColor: colors.gray3,
    height: 1,
  },
})

interface IProps<Selection> {
  onPressItem: (id: Selection) => void
  onRefresh?: () => void
  data: ISettingsListItem[]
  loading?: boolean
  listHeaderComponent?: () => JSX.Element
  listFooterComponent?: JSX.Element
}

export enum SettingsListItemType {
  Action = 'action',
  List = 'list',
  Organization = 'organization',
  Select = 'select',
}

export interface ISettingsListItemGeneric<T> {
  id: string
  type: T
  title: string
  subTitle?: string
  image?: ImageRequireSource
  imageStyle?: StyleProp<ImageStyle>
  iconComponent?: JSX.Element
  withHeader?: boolean
  titleImage?: ImageRequireSource
  color?: string
  titleColor?: string
  loading?: boolean
}

export interface ISettingsOrganizationItem extends ISettingsListItemGeneric<SettingsListItemType.Organization> {
  logo: ImageURISource
  isTestOnly?: boolean
}

export interface ISettingsSelectItem extends ISettingsListItemGeneric<SettingsListItemType.Select> {
  value: boolean
}

export type ISettingsListItem =
  | ISettingsListItemGeneric<SettingsListItemType.Action | SettingsListItemType.List>
  | ISettingsOrganizationItem
  | ISettingsSelectItem

export const SettingsList = observer(<T,>(props: IProps<T>): JSX.Element => {
  const [selected, setSelected] = useState(null)

  const keyExtractor = (item) => item.id

  const pressItem = async (id) => {
    setSelected(id)
    props.onPressItem(id)
  }

  const renderItem = ({ item }: { item: ISettingsListItem }) => {
    switch (item.type) {
      case SettingsListItemType.Select:
        return (
          <SelectItem
            id={item.id}
            loading={selected === item.id}
            onPressItem={pressItem}
            title={item.title}
            subTitle={item.subTitle}
            withHeader={item.withHeader}
            testID={item.title}
            value={item.value}
          />
        )
      case SettingsListItemType.Organization:
        return (
          <OrganizationItem
            id={item.id}
            loading={selected === item.id}
            onPressItem={pressItem}
            title={item.title}
            subTitle={item.subTitle}
            withHeader={item.withHeader}
            testID={item.title}
            logo={item.logo}
            isTestOnly={item.isTestOnly || false}
          />
        )
      case SettingsListItemType.Action:
        return (
          <SettingsActionItem
            id={item.id}
            onPressItem={pressItem}
            selected={selected === item.id}
            title={item.title}
            subTitle={item.subTitle}
            withHeader={item.withHeader}
            color={item.color}
            titleImage={item.titleImage}
            testID={item.title}
          />
        )
      case SettingsListItemType.List:
      default:
        return (
          <SettingsListItem
            id={item.id}
            loading={Boolean(props.loading) && selected !== null}
            onPressItem={pressItem}
            selected={selected === item.id}
            imageStyle={item.imageStyle}
            title={item.title}
            subTitle={item.subTitle}
            titleColor={item.titleColor}
            image={item.image}
            iconComponent={item.iconComponent}
            withHeader={item.withHeader}
            titleImage={item.titleImage}
            testID={item.title}
          />
        )
    }
  }

  const getSeparatorComponent = () => {
    if (Platform.OS !== OsType.Android) {
      // eslint-disable-next-line react/display-name, react/prop-types
      return ({ highlighted }) => <View style={[styles.separator, highlighted && { marginLeft: 0 }]} />
    }
    return null
  }

  const renderRefreshControl = () => {
    if (props.onRefresh) {
      return <RefreshControl refreshing={props.loading || false} onRefresh={props.onRefresh} />
    }
    return undefined
  }

  return (
    <FlatList
      ItemSeparatorComponent={getSeparatorComponent()}
      refreshControl={renderRefreshControl()}
      style={styles.list}
      data={props.data}
      extraData={props}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ListHeaderComponent={props.listHeaderComponent}
      ListFooterComponent={props.listFooterComponent}
    />
  )
})
