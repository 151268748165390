import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'
import { AutoSuggestHelper, AutoSuggestion, AutoSuggestType } from 'src/helpers/AutoSuggestHelper'
import { IconCircle } from './IconCircle'

const styles = StyleSheet.create({
  container: {
    paddingVertical: 18,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.gray2,
    minHeight: 70,
  },
  inlineContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    fontSize: 14,
    color: colors.textDark,
  },
  description: {
    fontSize: 13,
    color: colors.textMedium,
  },
  row: {
    flex: 1,
    flexDirection: 'column',
  },
  cellLeftIcon: {
    marginRight: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
})

interface IProps {
  searchItem: AutoSuggestion
  onPress: () => void
  testID?: string | number
}

export const AutoSuggestListItem = (props: IProps) => {
  const renderSubtitle = (suggestion: AutoSuggestion) => {
    const subtitle = getSubtitle(suggestion)
    if (subtitle) {
      return (
        <View accessible={true} accessibilityLabel={subtitle}>
          <Text style={styles.description}>{subtitle}</Text>
        </View>
      )
    }
    return null
  }

  const getSubtitle = (suggestion: AutoSuggestion): string | null => {
    if (suggestion.type === AutoSuggestType.Stop) {
      return suggestion.description
    } else if (suggestion.type === AutoSuggestType.SetLocationOnMap) {
      return null
    }

    return suggestion.address
  }

  const { searchItem } = props
  const icon = AutoSuggestHelper.getIconForAutoSuggestType(searchItem)
  const color = AutoSuggestHelper.getColorForAutoSuggestType(searchItem)

  return (
    <TouchableListItemWrapper style={styles.container} onPress={props.onPress} testPropertiesKey={`${props.testID}`}>
      <View style={styles.inlineContainer}>
        <View style={styles.cellLeftIcon}>
          <IconCircle icon={icon} backgroundColor={color} />
        </View>
        <View style={styles.row}>
          <View accessible={true} accessibilityLabel={searchItem.name}>
            <Text style={styles.name}>{searchItem.name}</Text>
          </View>
          {renderSubtitle(searchItem)}
        </View>
      </View>
    </TouchableListItemWrapper>
  )
}
