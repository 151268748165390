import React, { Component } from 'react'
import { View } from 'react-native'
import { VersionFooter } from 'src/components/VersionFooter'
import { DeveloperMenuTouchable } from 'src/screens/developer/components/DeveloperMenuTouchable'

interface IProps {
  handleNavigateDeveloperMenu: () => void
}

export class AccountSettingsFooter extends Component<IProps> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      pressCount: 0,
      lastPress: 0,
    }
  }

  public render() {
    return (
      <DeveloperMenuTouchable handleNavigateDeveloperMenu={this.props.handleNavigateDeveloperMenu}>
        <View>
          <VersionFooter />
        </View>
      </DeveloperMenuTouchable>
    )
  }
}
