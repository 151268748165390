import React from 'react'
import { StyleProp, StyleSheet, Text, TextProps, TextStyle } from 'react-native'
import { colors } from 'src/assets/colors'

interface IHeaderProps extends TextProps {
  headerText: string
  style?: StyleProp<TextStyle>
}

const styleSheet = StyleSheet.create({
  default: {
    color: colors.gray90,
    fontWeight: 'bold',
    fontSize: 21,
    lineHeight: 24,
  },
})

export const Header = ({ headerText, style, ...rest }: IHeaderProps) => (
  <Text numberOfLines={1} style={[styleSheet.default, style]} accessibilityRole='header' {...rest}>
    {headerText}
  </Text>
)
