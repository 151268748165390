import { Platform } from 'react-native'

export class NavigatorSettings {
  /**
   * This header status bar height needs to be applied to nested navigators
   */
  public static headerStatusBarHeight = Platform.select<number | undefined>({ ios: 0, android: undefined })

  public static headerTitleAlign: 'center' = 'center'
}
