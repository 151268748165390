import { IRequestResponse, RequestStatus } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { RequestHelper } from 'src/helpers/RequestHelper'
import { st } from 'src/locales'
import { RouterStore } from 'src/stores/RouterStore'
import { Pathname } from 'src/types/homeRoot'
import { HomeInfoBar } from './HomeInfoBar'

interface IProps {
  request: IRequestResponse
}

export class ActiveRequestInfoBar extends Component<IProps> {
  public renderRequestStatus(request: IRequestResponse) {
    switch (request.status) {
      case RequestStatus.Accepted:
        return RequestHelper.getAcceptedStatusText(request)
      case RequestStatus.Arriving:
        return st.components.requestCard.driverArriving()
      case RequestStatus.InProgress:
        return RequestHelper.getInProgressStatusText(request)
      case RequestStatus.Processing:
      case RequestStatus.ServiceDisruption:
        return st.components.requestCard.loading()
      default:
        return st.components.requestCard.onYourWay()
    }
  }

  public render() {
    const { request } = this.props
    return (
      <HomeInfoBar
        infoText={this.renderRequestStatus(request)}
        onPress={() => RouterStore.goToScreen({ pathname: Pathname.RequestActive })}
      />
    )
  }
}
