import React from 'react'
import { SafeAreaView, StyleSheet, View } from 'react-native'
import { Animations } from 'src/assets/Animations'
import { LottieAnimation } from 'src/components/lottieAnimation/LottieAnimation'
import { MODAL_MAX_WIDTH } from 'src/navigation/consts/NavigationHelper'

const ANIMATION_WIDTH = MODAL_MAX_WIDTH / 2

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    margin: 16,
    height: 200,
  },
  iconContainer: {
    alignItems: 'center',
  },
})

export const PurchaseConfirmedView = (): JSX.Element => (
  <SafeAreaView style={styles.container}>
    <View style={styles.iconContainer}>
      <LottieAnimation source={Animations.confirmation} autoPlay={true} loop={false} width={ANIMATION_WIDTH} />
    </View>
  </SafeAreaView>
)
