import React from 'react'
import { FloatingLabel } from 'src/components/FloatingLabel'
import { ImageMarker } from 'src/components/mapMarkers/ImageMarker'
import { ILabelPolylineMarkerProps } from 'src/components/mapMarkers/MapMarkerProps'
import { getAnchorForMarker, getCenterOffsetForMarker, getWebCenterOffset } from 'src/helpers/MapHelper'
import { START_PIN_SIZE } from 'src/helpers/MapMarkerHelper'
import { st } from 'src/locales'
import { IMarker, MarkerTypes } from 'src/types'

const LABEL_WIDTH_ENGLISH = 32

export const StartLabelMarker = (props: ILabelPolylineMarkerProps) => {
  const { coordinates, location } = props

  const labelMarker: IMarker = {
    name: MarkerTypes.StartLabel,
    coordinate: location,
    child: <FloatingLabel title={st.components.journey.startTag()} />,
    anchor: getAnchorForMarker(coordinates, location, LABEL_WIDTH_ENGLISH),
    centerOffset: getCenterOffsetForMarker(coordinates, location, LABEL_WIDTH_ENGLISH),
    webCenterOffset: getWebCenterOffset({
      markerLocations: coordinates,
      location,
      labelWidth: LABEL_WIDTH_ENGLISH,
      pinSize: START_PIN_SIZE,
    }),
    pointerEvents: 'none',
    style: { zIndex: 1 },
  }

  return <ImageMarker key={labelMarker.name} {...labelMarker} />
}
