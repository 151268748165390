import React from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { st } from 'src/locales'
import { AbstractButton } from './AbstractButton'

const styles = StyleSheet.create({
  buttonWrapper: {
    marginTop: 15,
  },
})

interface IProps {
  isLoading: boolean
  testButtonId: string
  handleSubmit: () => void
}

export const FormSubmitButton = ({ testButtonId, isLoading, handleSubmit }: IProps) => (
  <View style={styles.buttonWrapper}>
    <AbstractButton
      title={st.common.buttonConfirm()}
      onPress={handleSubmit}
      testID={testButtonId}
      loading={isLoading}
      textColor={colors.white}
      backgroundColor={colors.selectedBlue}
    />
  </View>
)
