import { IFleetStub } from '@sparelabs/api-client'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAsync } from 'react-async-hook'
import { ActivityIndicator, ImageURISource, Platform, SectionList, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { SettingsListItem } from 'src/components/settings/SettingsListItem'
import { st } from 'src/locales'
import { ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { IUserFleetAgreementStore, UserFleetAgreementStore } from 'src/stores/UserFleetAgreementStore'
import { ISection } from 'src/types/section'
import { OsType } from 'src/util/types'

const styles = StyleSheet.create({
  list: {
    backgroundColor: 'white',
  },
  title: {
    fontSize: 16,
    lineHeight: 20,
    color: colors.gray90,
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 12,
    paddingBottom: 8,
  },
  activity: {
    marginTop: 32,
  },
  itemList: {
    flex: 1,
    backgroundColor: colors.gray4,
  },
  separator: {
    backgroundColor: colors.gray3,
    height: 1,
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 10,
  },
  initials: {
    fontSize: 20,
    backgroundColor: 'transparent',
    textAlign: 'center',
    color: colors.gray1,
  },
  initialsWrapper: {
    height: 40,
    width: 40,
    borderRadius: 10,
    backgroundColor: '#DCDCDC',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

type Props = ParamsListAccount[ScreenName.FleetAgreementList] & {
  userFleetAgreementStore: IUserFleetAgreementStore
  handleNavigateFleetAgreementDetails: (params: ParamsListAccount[ScreenName.FleetAgreementsDetails]) => void
}

export const FleetAgreementsView = observer((props: Props) => {
  useAsync(
    async () => props.userFleetAgreementStore.loadingStore.execute(props.userFleetAgreementStore.refreshStoreData()),
    []
  )

  const updateFleetAgreementsListCallback = () => props.userFleetAgreementStore.refreshStoreData()

  const showFleetAgreementDetails = async (fleet: { id: string; data: IFleetStub }) => {
    props.handleNavigateFleetAgreementDetails({
      fleet,
      updateFleetAgreementsListCallback,
    })
  }

  const renderLogo = (logoUrl: string | null): ImageURISource | undefined => (logoUrl ? { uri: logoUrl } : undefined)

  const renderIcon = (fleet: IFleetStub): JSX.Element | undefined =>
    fleet.logoUrl ? undefined : (
      <View style={styles.initialsWrapper}>
        <Text style={styles.initials}>
          {fleet.name[0]}
          {fleet.name[1]}
        </Text>
      </View>
    )

  const renderFleetAgreementsSection = (): ISection[] => {
    const userFleetAgreements = props.userFleetAgreementStore.userFleetAgreements
    if (userFleetAgreements.length === 0) {
      return [
        {
          title: st.screens.fleetAgreementList.descriptionNoFleets(),
          data: [],
        },
      ]
    }

    const fleetAgreementsListItems: JSX.Element[] = []

    for (const agreement of userFleetAgreements) {
      const fleet = agreement.fleetStub

      if (agreement.agreed) {
        const groupItem = (
          <SettingsListItem
            key={agreement.id}
            id={agreement.id}
            title={fleet.name}
            image={renderLogo(fleet.logoUrl)}
            iconComponent={renderIcon(fleet)}
            imageStyle={styles.image}
            onPressItem={() => showFleetAgreementDetails({ id: agreement.id, data: fleet })}
          />
        )
        fleetAgreementsListItems.push(groupItem)
      }
    }

    const title =
      fleetAgreementsListItems.length === 0
        ? st.screens.fleetAgreementList.descriptionNoFleets()
        : st.screens.fleetAgreementList.description()

    return [
      {
        title,
        data: fleetAgreementsListItems,
      },
    ]
  }

  return props.userFleetAgreementStore.loadingStore.isLoading() ? (
    <ActivityIndicator style={styles.activity} color='black' />
  ) : (
    <View style={styles.itemList}>
      <SectionList
        ItemSeparatorComponent={Platform.OS === OsType.iOS ? () => <View style={[styles.separator]} /> : null}
        style={styles.list}
        sections={renderFleetAgreementsSection()}
        renderItem={({ item }) => item}
        renderSectionHeader={({ section: { title } }) => <Text style={styles.title}>{title}</Text>}
        stickySectionHeadersEnabled={false}
      />
    </View>
  )
})

const userFleetAgreementStore = new UserFleetAgreementStore()

export const FleetAgreementList = (props: ScreenPropsAccount<ScreenName.FleetAgreementList>) => (
  <FleetAgreementsView
    {...props.route.params}
    userFleetAgreementStore={userFleetAgreementStore}
    handleNavigateFleetAgreementDetails={(params) => {
      props.navigation.navigate(ScreenName.FleetAgreementsDetails, params)
    }}
  />
)
