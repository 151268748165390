import React from 'react'
import { Image, Platform, SafeAreaView, StyleSheet, View } from 'react-native'
import { RetryButton } from 'src/components/login/RetryButton'
import { WhitelabelHelper } from 'src/helpers/WhitelabelHelper'
import { OsType } from 'src/util/types'

const styles = StyleSheet.create({
  loginContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'white',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
  container: {
    flex: 1,
  },
  bottomContainer: {
    flex: 1,
    width: '100%',
    paddingVertical: 16,
    display: 'flex',
    justifyContent: Platform.OS === OsType.Web ? 'flex-start' : 'flex-end',
    gap: 16,
  },
  logoContainer: {
    marginTop: 80,
    width: 250,
    height: 200,
  },
  contentContainer: {
    flex: 1,
    maxWidth: 500,
    width: '100%',
    alignItems: 'center',
  },
})

interface IProps {
  handleRetryPress: () => void
  isLoading: boolean
}

export const NoConnection = ({ handleRetryPress, isLoading }: IProps): JSX.Element => (
  <SafeAreaView style={styles.container}>
    <View style={styles.loginContainer}>
      <View style={styles.contentContainer}>
        <Image resizeMode='contain' style={styles.logoContainer} source={WhitelabelHelper.getLogoSource()} />
        <View style={styles.bottomContainer}>
          <RetryButton
            onRetryPress={async () => {
              handleRetryPress()
            }}
            isLoading={isLoading}
          />
        </View>
      </View>
    </View>
  </SafeAreaView>
)
