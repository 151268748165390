import React from 'react'
import { ImageMarker } from 'src/components/mapMarkers/ImageMarker'
import { IMarkerProps } from 'src/components/mapMarkers/MapMarkerProps'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { ANDROID_ANCHOR, END_PIN_OFFSET, END_PIN_SIZE, mapMarkerStyles } from 'src/helpers/MapMarkerHelper'
import { IMarker, MarkerTypes } from 'src/types'

export const EndPinMarker = (props: IMarkerProps) => {
  const endPinMarker: IMarker = {
    name: MarkerTypes.EndPin,
    coordinate: props.location,
    anchor: ANDROID_ANCHOR,
    imageStyle: mapMarkerStyles.endPin,
    webCenterOffset: { offsetLeft: -END_PIN_OFFSET, offsetTop: -END_PIN_OFFSET },
    child: <SvgIconWrapper icon={SvgIcon.EndPin} widthFixed={END_PIN_SIZE} heightFixed={END_PIN_SIZE} />,
  }
  return <ImageMarker {...endPinMarker} />
}
