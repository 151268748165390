import Location from 'expo-location'
import React from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { AutoSuggestHelper } from 'src/helpers/AutoSuggestHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { LocationStore } from 'src/stores/LocationStore'
import { OnboardingStore } from 'src/stores/OnboardingStore'
import { SearchFieldType, SearchScreenStore } from 'src/stores/SearchScreenStore'

const styles = StyleSheet.create({
  searchText: {
    flex: 1,
    fontSize: 20,
    fontWeight: '600',
    color: colors.textDark,
  },
  searchButton: {
    borderRadius: 5,
    flex: 1,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
    backgroundColor: colors.searchGray,
    width: '100%',
  },
  searchBarContainer: {
    borderRadius: 5,
    height: 45,
    marginTop: 10,
    marginBottom: 3,
    marginHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchIcon: {
    height: 19,
    width: 19,
  },
})

interface IProps {
  handleNavigateOnboarding: (params: ParamsListRoot[ScreenName.Onboarding]) => void
  navigateToSearch: () => void
  currentLocation: Location.LocationObject | null
}

export const HomeSearchBar = (props: IProps) => {
  const onSearchClick = async () => {
    // Clear search before entering
    SearchScreenStore.clear()
    const permissionDenied = await LocationStore.permissionsDenied()
    if (props.currentLocation && !permissionDenied) {
      const locationSuggestion = AutoSuggestHelper.mapPositionToAutoSuggestion(props.currentLocation)
      SearchScreenStore.setAutoSuggestion(locationSuggestion, SearchFieldType.Origin)
      SearchScreenStore.setCurrentSearchField(SearchFieldType.Destination)
    }

    // Show on boarding if necessary, otherwise navigate straight to the search screen
    if (OnboardingStore.shouldShowOnboardingBeforeTrip()) {
      props.handleNavigateOnboarding({
        onFinish: () => props.navigateToSearch(),
      })
    } else {
      props.navigateToSearch()
    }
  }

  return (
    <View style={styles.searchBarContainer}>
      <TouchableOpacity
        testID='searchBar'
        onPress={onSearchClick}
        style={styles.searchButton}
        accessible={true}
        accessibilityLabel={st.components.searchBar.searchAccessibilityLabel()}
      >
        <Text style={styles.searchText}>{st.components.searchBar.searchPlaceholder()}</Text>
        <Image source={Images.magnifyingGlass} style={styles.searchIcon} />
      </TouchableOpacity>
    </View>
  )
}
