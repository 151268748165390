import { IFareStub, IRequestResponse, ITipPolicyResponse, TipPolicyType } from '@sparelabs/api-client'
import { TipValidation } from '@sparelabs/domain-logic'
import { st } from 'src/locales'
import { TipPolicyStore } from 'src/stores/TipPolicyStore'
import { TipStore } from 'src/stores/TipStore'
import { moment } from './Moment'
export class TipHelper {
  public static tipPercentage = (cost: number, amount: number): string => Math.round((100 * amount) / cost).toString()

  public static isTipPolicyValid = async (request: IRequestResponse): Promise<boolean> => {
    const tipPolicy = await TipPolicyStore.getTipPolicyResponse(request)

    if (!tipPolicy) {
      return false
    }

    /**
     * We only need to do this validation here, the other tip policy types are validated on the API but
     * for percentage tips we can only do the validation here once we know the fare
     */
    if (tipPolicy.type === TipPolicyType.PercentageOnly) {
      return !TipValidation.isAmountBelowMinimum(
        TipHelper.getTipAmountFromPercentage(request.fare.cost, tipPolicy.smallestTipPercentageSuggestion || 0),
        request.fare.currency
      )
    }

    return true
  }

  public static getTipScreenTitle = (request: IRequestResponse, isCustomTipScreen: boolean): string => {
    const date = request.scheduledPickupTs ? moment.unix(request.scheduledPickupTs).format('ll') : null
    if (date) {
      return date
    } else if (isCustomTipScreen) {
      return st.screens.tip.customTipTitle()
    }
    return st.screens.tip.addATipTitle()
  }

  public static getTipAmountFromPercentage = (cost: number, percentage: number): number =>
    Math.ceil(cost * (percentage / 100))

  public static getButtonTipValue = (
    fare: IFareStub,
    tipPolicy: ITipPolicyResponse,
    amount: number | null,
    percent: number | null
  ): number | null => {
    const fixedAmount = amount ?? null
    const percentAmount = percent ? Math.round(fare.cost * (percent / 100)) : null

    switch (tipPolicy.type) {
      case TipPolicyType.FixedOnly:
        return fixedAmount
      case TipPolicyType.PercentageOnly:
        return percentAmount
      case TipPolicyType.PercentageThreshold:
        return tipPolicy.switchToPercentageThreshold && fare.cost >= tipPolicy.switchToPercentageThreshold
          ? percentAmount
          : fixedAmount
      default:
        return null
    }
  }

  public static shouldShowPercentage = (fareAmount: number, tipPolicy: ITipPolicyResponse): boolean => {
    if (
      tipPolicy.type === TipPolicyType.PercentageOnly ||
      (tipPolicy.switchToPercentageThreshold &&
        tipPolicy.type === TipPolicyType.PercentageThreshold &&
        fareAmount >= tipPolicy.switchToPercentageThreshold)
    ) {
      return true
    }
    return false
  }

  public static checkRiderCanTip = async (request: IRequestResponse) => {
    const riderHasTipped = await TipStore.getTipResponse(request.id)
    const validTipPolicy = await TipHelper.isTipPolicyValid(request)
    return !riderHasTipped && validTipPolicy
  }
}
