import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { colors } from 'src/assets/colors'
import { Moment } from 'src/helpers/Moment'
import { st } from 'src/locales'

const styles = StyleSheet.create({
  alert: {
    color: colors.red,
    fontSize: 15,
    lineHeight: 20,
  },
})

interface IProps {
  time?: Moment
}

export const DstSprintForwardHint = ({ time }: IProps): JSX.Element | null => {
  if (!time) {
    return null
  }

  const showHint = time.isDST() !== time.clone().add(1, 'hour').isDST()

  if (showHint) {
    const DstHour = time.clone().startOf('hour').hour() + 1

    const start = `${DstHour}:00 ${time.format('A')}`
    const end = `${DstHour}:59 ${time.format('A')}`

    return <Text style={styles.alert}>{st.components.scheduleEstimate.dst.springForward.hint({ start, end })}</Text>
  }

  return null
}
