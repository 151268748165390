import React from 'react'
import { FlatList, StyleSheet, useWindowDimensions, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { IListViewItem, ListViewItem } from './ListViewItem'

const styles = StyleSheet.create({
  separator: {
    backgroundColor: colors.gray3,
    height: 1,
  },
})

interface IProps {
  data: IListViewItem[]
  loading?: boolean
  listHeaderComponent?: JSX.Element
  listFooterComponent?: JSX.Element
  testID?: string
}

export const ListView = (props: IProps): JSX.Element => {
  const { height: windowHeight } = useWindowDimensions()

  const keyExtractor = (item) => item.id
  const renderItem = ({ item }: { item: IListViewItem }) => (
    <ListViewItem loading={props.loading} selected={false} data={item} testID={item.title} />
  )
  const getSeparatorComponent = ({ highlighted }) => (
    <View style={[styles.separator, highlighted && { marginLeft: 0 }]} />
  )
  // Workaround to get list scrolling
  const getListStyle = () =>
    StyleSheet.create({
      list: {
        backgroundColor: colors.gray4,
        flex: 1,
        maxHeight: windowHeight,
      },
    })

  return (
    <FlatList
      ItemSeparatorComponent={getSeparatorComponent}
      style={getListStyle().list}
      data={props.data}
      extraData={props}
      keyExtractor={(item) => keyExtractor(item)}
      renderItem={renderItem}
      ListHeaderComponent={props.listHeaderComponent}
      ListFooterComponent={props.listFooterComponent}
    />
  )
}
