import React from 'react'
import { TouchableOpacity } from 'react-native'
import { ITouchableButtonWrapperProps } from 'src/components/touchableButtonWrapper/TouchableButtonWrapperTypes'
import styled from 'styled-components'

const StyledHoverWrapper = styled.div`
  opacity: 1;
  &:hover {
    opacity: 0.3;
  }
`

export const TouchableButtonWrapper = (props: ITouchableButtonWrapperProps) => (
  <StyledHoverWrapper>
    <TouchableOpacity
      disabled={props.disabled}
      style={props.style}
      onPress={props.onPress}
      accessibilityRole='button'
      accessibilityLabel={props.label}
      testID={props.testID}
    >
      {props.children}
    </TouchableOpacity>
  </StyledHoverWrapper>
)
