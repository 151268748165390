import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'

interface IProps {
  color: string
}

const styles = StyleSheet.create({
  card: {
    height: 12,
    width: 18,
    borderRadius: 2,
    position: 'relative',
  },
  stripe: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 0.5,
    top: 2,
    left: 2,
    width: 10,
    height: 2,
  },
})

export class FarePassIcon extends Component<IProps> {
  public render() {
    const { color } = this.props

    return (
      <View style={[styles.card, { backgroundColor: color }]}>
        <View style={styles.stripe} />
      </View>
    )
  }
}
