import { IRequestResponse } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { Header } from 'src/components/headers/Header'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'

interface IProps {
  request: Pick<IRequestResponse, 'matchCutoffTs'>
}

interface IWaitInfo {
  timeText: string
  showTimer: boolean
}

interface IState {
  waitInfo: IWaitInfo
}

const REFRESH_FREQUENCY_MS = 1000 // once a second
const TIMER_THRESHOLD_MINUTES = 10

export const computeWaitInfo = (matchCutoffTs: number): { timeText: string; showTimer: boolean } => {
  const duration = moment.duration(Math.max(0, matchCutoffTs - moment().unix()), 'seconds')
  if (duration.asMinutes() > TIMER_THRESHOLD_MINUTES) {
    return {
      timeText: moment.unix(matchCutoffTs).format('LT'),
      showTimer: false,
    }
  }
  // Add zero padding to the number of seconds
  const seconds = duration.seconds()
  return {
    timeText: `${duration.minutes()}:${seconds < 10 ? '0' : ''}${seconds}`,
    showTimer: true,
  }
}

export class RequestWaitText extends Component<IProps, IState> {
  private interval: NodeJS.Timeout | null = null
  constructor(props: IProps) {
    super(props)
    this.state = {
      waitInfo: computeWaitInfo(props.request.matchCutoffTs),
    }
  }

  public UNSAFE_componentWillMount() {
    this.interval = setInterval(this.updateWaitText, REFRESH_FREQUENCY_MS)
  }

  public componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  public render() {
    if (this.state.waitInfo.showTimer) {
      return <Header headerText={st.request.processingWait.findingARide({ timer: this.state.waitInfo.timeText })} />
    }
    return (
      <Header
        headerText={st.request.processingWait.moreDetailsAtFuture({ futureTime: this.state.waitInfo.timeText })}
      />
    )
  }

  private readonly updateWaitText = () => {
    this.setState({
      waitInfo: computeWaitInfo(this.props.request.matchCutoffTs),
    })
  }
}
