import { IFarePassStub } from '@sparelabs/api-client'
import { DAY } from '@sparelabs/time'

export const findExpiryProgress = (createdAt: number, farePass: IFarePassStub, currentUnixTime: number): number => {
  if (farePass.validDaysCount !== null) {
    return Math.max(0, Math.min(1, (currentUnixTime - createdAt) / (DAY * farePass.validDaysCount)))
  }
  return 0
}

export const findBalanceProgress = (amountRedeemed, farePass): number => {
  if (farePass.amount !== null) {
    return Math.max(0, Math.min(1, (farePass.amount - amountRedeemed) / farePass.amount))
  }
  return 0
}
