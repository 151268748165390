import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { st } from 'src/locales'

const styles = StyleSheet.create({
  aboutRideHeading: {
    textTransform: 'uppercase',
    color: colors.gray70,
    fontSize: 14,
    fontWeight: '700',
    marginBottom: 10,
  },
  aboutRideDescription: {
    color: colors.gray90,
    fontSize: 16,
    fontWeight: '400',
  },
})

interface IProps {
  heading?: string
  description?: string
  children?: JSX.Element
  provider?: string
  serviceName?: string
}

export function AboutRide(props: IProps): JSX.Element {
  const { provider = '', serviceName = '' } = props
  return (
    <View>
      <Text style={styles.aboutRideHeading}>{props.heading ?? st.components.requestCard.aboutRide.heading()}</Text>
      <Text style={styles.aboutRideDescription}>
        {props.description ??
          st.components.requestCard.aboutRide.description({
            provider,
            serviceName,
          })}
      </Text>
      {props.children}
    </View>
  )
}
