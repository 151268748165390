import React, { Component } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { TertiaryButton } from 'src/components/buttons/TertiaryButton'
import { TextField } from 'src/components/textField/TextField'
import { st } from 'src/locales'
import { ParamsListAccount, ScreenName, ScreenPropsAccount } from 'src/navigation'
import { PromoStore } from 'src/stores/PromoStore'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray4,
    flex: 1,
  },
  containerCell: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  textField: {
    fontSize: 17,
  },
  textFieldContainer: {
    paddingVertical: 24,
  },
})

interface IState {
  code: string
  isError: boolean
}

type Props = ParamsListAccount[ScreenName.AddPromo] & {
  handleDismiss: () => void
}

export class AddPromoView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      code: '',
      isError: false,
    }
  }

  public textChanged = (code: string) => {
    this.setState({ code, isError: false })
  }

  public onHandleSubmit = async () => {
    try {
      await PromoStore.redeemPromo(this.state.code)
      this.props.handleDismiss()
    } catch (e) {
      this.setState({ isError: true })
    }
  }

  public render() {
    let errorText: string | null = null
    if (this.state.isError) {
      errorText = st.screens.addPromo.addError()
    }
    return (
      <ScrollView keyboardShouldPersistTaps='always' keyboardDismissMode='on-drag' style={styles.container}>
        <View style={styles.containerCell}>
          <View style={styles.textFieldContainer}>
            <TextField
              style={styles.textField}
              returnKeyType='done'
              autoCapitalize='none'
              autoCorrect={false}
              label={st.screens.addPromo.codePlaceholder()}
              error={errorText ?? undefined}
              tintColor={colors.primaryColor}
              value={this.state.code}
              onChangeText={(code) => this.textChanged(code)}
            />
          </View>
          <TertiaryButton
            title={st.common.buttonConfirm()}
            onPress={this.onHandleSubmit}
            loading={PromoStore.loading}
          />
        </View>
      </ScrollView>
    )
  }
}

export const AddPromo = (props: ScreenPropsAccount<ScreenName.AddPromo>) => (
  <AddPromoView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
