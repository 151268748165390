export const colors = {
  primaryColor: '#007dba',
  secondaryColor: '#00205B',
  red: '#FE5858',
  brightRed: '#FE5858',
  green: '#46C580',
  green10: '#E7F2EF',
  green30: '#B2DFCD',
  green50: '#3FAE82',
  green70: '#198168',
  green50Fill: 'rgba(63, 174, 130, 0.1)',
  selectedBlue: '#2E8DDE',
  selectedBlueFill: '#2e8dde26',
  selectedBlueDisabled: '#abd1f2',
  selectedPurple: '#8F2EDE',
  selectedPurpleFill: '#8F2EDE26',
  textLight: 'white',
  textMedium: '#666666',
  borderGray: '#eee',
  textDark: '#444444',
  borderColor: '#e4e4e4',
  darkGrayBlue: '#2c475f',
  titleBlue: '#6a7797',
  radioBorder: '#e4e4e4',
  radioLabel: '#888',
  sectionBlue: '#f6f8fc',
  gray1: '#999999',
  gray2: '#EEEEEE',
  gray3: '#F3F3F3',
  gray4: '#F8F8F8',
  gray5: '#888888',
  gray6: '#222222',
  shadowColor: 'rgba(212,212,212, .5)',
  darkShadowColor: 'rgba(0, 0, 0, 0.2)',
  zoneBorder: '#4EA6FE',
  zoneFill: 'rgba(59, 153, 252, .05)',
  searchGray: '#efefef',
  purple: '#7d57b5',
  grayTag: '#f1f1f1',
  transparent: 'transparent',
  yellow: '#fecfaf',
  paleRed: '#e47084',
  borderBlue: '#ECECEC',
  navigatorOverlay: 'rgba(0,0,0, 0.5)',
  // New colors
  // TODO use new @sparelabs/colors library
  blue70: '#2C475F',
  blue50: '#2E8DDE',
  blue30: '#ABD1F2',
  blue20: '#DEE8FC',
  blue10: '#F6F8FC',
  blueAlt30: '#C2C7DA',
  blueAlt50: '#6774A3',
  red10: '#FEE0E7',
  red30: '#F2ADB6',
  red50: '#DE3348',
  red70: '#7D3042',
  gray90: '#222222',
  gray70: '#666666',
  white: '#FFFFFF',
  gray50: '#999999',
  gray30: '#E4E4E4',
  coolGray10: '#E1E4EF',
  black: '#000000',
  purple70: '#352C5F',
  purple50: '#5137E0',
  purple20: '#E3DEFC',
  purple10: '#F7F6FC',
  lyftPink: '#FF00BF',
  pink10: '#FFD6F5',
  pink70: '#80005F',
}
