import { IFixedRouteLeg, IJourney, IJourneyStop, IOnDemandLeg, IWalkingLeg, LegMode } from '@sparelabs/api-client'
import { IPoint } from '@sparelabs/geography'
import { last } from 'lodash'
import React from 'react'
import { View } from 'react-native'
import { ErrorBoundary } from 'src/components/error/ErrorBoundary'
import { DrivingPolyline } from 'src/components/mapMarkers/DrivingPolyline'
import { DropoffLabelMarker } from 'src/components/mapMarkers/DropoffLabelMarker'
import { DropoffPinMarker } from 'src/components/mapMarkers/DropoffPinMarker'
import { EndLabelMarker } from 'src/components/mapMarkers/EndLabelMarker'
import { EndPinMarker } from 'src/components/mapMarkers/EndPinMarker'
import { FixedLegLabelMarker } from 'src/components/mapMarkers/FixedLegLabelMarker'
import { FixedLegPinMarker } from 'src/components/mapMarkers/FixedLegPinMarker'
import { PickupLabelMarker } from 'src/components/mapMarkers/PickupLabelMarker'
import { PickupPinMarker } from 'src/components/mapMarkers/PickupPinMarker'
import { StartLabelMarker } from 'src/components/mapMarkers/StartLabelMarker'
import { StartPinMarker } from 'src/components/mapMarkers/StartPinMarker'
import { TransitPolyline } from 'src/components/mapMarkers/TransitPolyline'
import { WalkingPolyline } from 'src/components/mapMarkers/WalkingPolyline'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { st } from 'src/locales'
import {
  getPolyline,
  getRouteColour,
  getTransitPolylineCoordinates,
  mapWalkingLegToWalkingInfo,
} from 'src/screens/rideOptions/JourneyMapHelper'
import { MarkerTypes } from 'src/types'
import { ISimpleJourneyRoute } from 'src/types/journey'

interface IProps {
  simpleJourneyRoute: ISimpleJourneyRoute
  selectedJourney: IJourney
}

const renderFixedLegMarkers = (
  transitLeg: IFixedRouteLeg,
  markerType: MarkerTypes,
  shouldRenderFirstMarker: boolean
) => {
  const routeColour = getRouteColour(transitLeg.routeColor)
  const polylineCoordinates = getTransitPolylineCoordinates(transitLeg)

  const markerLocation: IPoint =
    markerType === MarkerTypes.BoardLabel
      ? transitLeg.stops[0].location
      : transitLeg.stops[transitLeg.stops.length - 1].location

  const labelTitle =
    markerType === MarkerTypes.BoardLabel ? st.components.journey.boardTag() : st.components.journey.exitTag()

  return (
    <>
      {shouldRenderFirstMarker && (
        <FixedLegLabelMarker
          label={labelTitle}
          coordinates={polylineCoordinates}
          location={markerLocation}
          markerType={markerType}
        />
      )}
      <FixedLegPinMarker location={markerLocation} routeColour={routeColour} />
    </>
  )
}

const renderFixedRouteLeg = (transitLeg: IFixedRouteLeg) => {
  const stops: IJourneyStop[] = transitLeg.stops.slice()
  // we should always at least have 2 stops in a transit leg
  if (stops.length < 2) {
    return null
  }

  // we don't need markers of the first and last stops since they are the board and exit markers
  stops.shift()
  stops.pop()

  return (
    <View key={`fixed-route-${transitLeg.routeName}`}>
      <ErrorBoundary>{renderFixedLegMarkers(transitLeg, MarkerTypes.BoardLabel, false)}</ErrorBoundary>
      <ErrorBoundary>
        <TransitPolyline transitLeg={transitLeg} />
      </ErrorBoundary>
      <ErrorBoundary>{renderFixedLegMarkers(transitLeg, MarkerTypes.ExitLabel, false)}</ErrorBoundary>
    </View>
  )
}

const renderWalkingLeg = (walkingLeg: IWalkingLeg) => (
  <ErrorBoundary key={`${walkingLeg.mode}-${walkingLeg.startTime.ts}`}>
    <WalkingPolyline walkingInfo={mapWalkingLegToWalkingInfo(walkingLeg)} />
  </ErrorBoundary>
)

const renderOnDemandLeg = (onDemandLeg: IOnDemandLeg) => {
  const locations = getPolyline(onDemandLeg)
  return (
    <View key={`on-demand-${onDemandLeg.startTime.ts}`}>
      <ErrorBoundary>
        <DrivingPolyline
          pickupLocation={onDemandLeg.start.location}
          dropoffLocation={onDemandLeg.end.location}
          renderPolyline={true}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <PickupPinMarker location={onDemandLeg.start.location} />
      </ErrorBoundary>
      <ErrorBoundary>
        <DropoffPinMarker location={onDemandLeg.end.location} />
      </ErrorBoundary>
      <ErrorBoundary>
        <PickupLabelMarker
          location={onDemandLeg.start.location}
          coordinates={locations}
          scheduledPickupTime={onDemandLeg.startTime.ts}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <DropoffLabelMarker location={onDemandLeg.end.location} coordinates={locations} />
      </ErrorBoundary>
    </View>
  )
}

const renderJourneyLegPolylinesAndMarkers = (selectedJourney: IJourney) => {
  const walkingLegs = selectedJourney?.legs.filter((leg): leg is IWalkingLeg => leg.mode === LegMode.Walk)
  const onDemandLegs = selectedJourney?.legs.filter((leg): leg is IOnDemandLeg => leg.mode === LegMode.OnDemand)
  const fixedRouteLegs = selectedJourney?.legs.filter(
    (leg): leg is IFixedRouteLeg => leg.mode === LegMode.Bus || leg.mode === LegMode.Train || leg.mode === LegMode.Tram
  )

  const [firstFixedLeg, ...restFixedLegs] = fixedRouteLegs

  return [
    walkingLegs.length > 0 ? walkingLegs.map((leg) => renderWalkingLeg(leg)) : null,
    onDemandLegs.length > 0 ? onDemandLegs.map((leg) => renderOnDemandLeg(leg)) : null,
    firstFixedLeg ? renderFixedRouteLeg(firstFixedLeg) : null,
    restFixedLegs.length > 0 ? restFixedLegs.map((leg) => renderFixedRouteLeg(leg)) : null,
  ]
}

const renderConstantMarkers = (props: IProps) => {
  const { simpleJourneyRoute, selectedJourney } = props

  const firstLeg = selectedJourney.legs[0]
  const lastLeg = last(selectedJourney.legs)

  if (!firstLeg || !lastLeg) {
    return null
  }

  const showStartPin = !MapMarkerHelper.doesLegOverlapLocation(
    simpleJourneyRoute.requestedPickupLocation,
    firstLeg,
    MarkerTypes.StartPin
  )

  const showEndPin = !MapMarkerHelper.doesLegOverlapLocation(
    simpleJourneyRoute.requestedDropoffLocation,
    lastLeg,
    MarkerTypes.EndPin
  )

  return (
    <>
      <ErrorBoundary key='start'>
        {showStartPin && <StartPinMarker location={simpleJourneyRoute.requestedPickupLocation} />}
        <StartLabelMarker
          location={simpleJourneyRoute.requestedPickupLocation}
          coordinates={MapMarkerHelper.getAllCoordinates(simpleJourneyRoute)}
        />
      </ErrorBoundary>
      <ErrorBoundary key='end'>
        {showEndPin && <EndPinMarker location={simpleJourneyRoute.requestedDropoffLocation} />}
        <EndLabelMarker
          location={simpleJourneyRoute.requestedDropoffLocation}
          coordinates={MapMarkerHelper.getAllCoordinates(simpleJourneyRoute)}
        />
      </ErrorBoundary>
    </>
  )
}

export const JourneyLegsMap = (props: IProps) => (
  <>
    {renderJourneyLegPolylinesAndMarkers(props.selectedJourney)}
    {renderConstantMarkers(props)}
  </>
)
