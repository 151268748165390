import { FieldLabel } from '@sparelabs/web-ui-components'
import React from 'react'
import { DateTimeInput } from 'src/components/datepicker/DateTimeInput'
import { DateTimeDisplay } from 'src/components/datepicker/DateTimeInputTypes'
import { IDateTimeFieldProps } from 'src/components/form/DateField/DatetimeFieldProps'
import { FieldContainer, FieldError } from 'src/components/form/FieldShared'
import { moment } from 'src/helpers/Moment'

export const DatetimeField = <T extends number | string>(props: IDateTimeFieldProps<T>): JSX.Element => (
  <FieldContainer>
    <FieldLabel>{props.label}</FieldLabel>
    <DateTimeInput
      date={props.value ? props.formatValue(props.value) : moment()}
      showTitle={false}
      display={DateTimeDisplay.Spinner}
      onHandleDateChange={(newMoment) => props.onValueChange(newMoment)}
      mode={props.mode}
    />
    <FieldError touched={props.touched} error={props.error} />
  </FieldContainer>
)
