import { Platform } from 'react-native'
import * as SentryExpo from 'sentry-expo'
import { Constants } from 'src/consts/Constants'
import packageJSON from '../../package.json'

const versionNumber = packageJSON.version
const sentry = Platform.select<typeof SentryExpo.Native | typeof SentryExpo.Browser>({
  ios: SentryExpo.Native,
  android: SentryExpo.Native,
  web: SentryExpo.Browser,
})

export class Sentry {
  public static sentryInstall = () => {
    // eslint-disable-next-line no-undef
    if (!__DEV__) {
      const release = `${Constants.APP_ID}-${versionNumber}`

      SentryExpo.init({
        dsn: 'https://9ae3c82eb755497fb07bfe02dff32c70@sentry.io/282344',
        enableAutoSessionTracking: true,
        release,
      })

      this.sentryAddEnvironmentTags()
    }
  }

  public static sentrySetUser = (userId: string, organization: { id: string; name: string }) => {
    sentry?.setUser({
      id: userId,
      extra: {
        organizationId: organization.id,
        organizationName: organization.name,
      },
    })
  }

  public static setTags = (tags: Record<string, number | string>) => {
    sentry?.setTags(tags)
  }

  public static captureMessage = (message: string) => {
    this.sentryAddEnvironmentTags()
    sentry?.captureMessage(message)
  }

  public static setExtra = (key: string, extra: unknown) => {
    sentry?.setExtra(key, extra)
  }

  public static captureException = (error: any) => {
    this.sentryAddEnvironmentTags()
    sentry?.captureException(error)
  }

  private static readonly sentryAddEnvironmentTags = () => {
    this.setTags({
      os: `${Platform.OS} ${Platform.Version}`,
      version: `${versionNumber}-${Constants.NATIVE_BUILD_VERSION}`,
    })
  }
}
