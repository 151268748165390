import React, { ReactNode } from 'react'
import { StyleSheet, View } from 'react-native'
import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context'

const getStyles = (insets: EdgeInsets) =>
  StyleSheet.create({
    buttonWrapper: {
      paddingHorizontal: 16,
      marginBottom: Math.max(16, insets.bottom),
    },
  })

interface IProps {
  children: ReactNode
}

export const EstimateCardButtonWrapper = (props: IProps) => {
  const insets = useSafeAreaInsets()
  return <View style={getStyles(insets).buttonWrapper}>{props.children}</View>
}
