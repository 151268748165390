import React, { FunctionComponent } from 'react'
import { SafeAreaView, StyleSheet, Text } from 'react-native'
import { colors } from 'src/assets/colors'
import { st } from 'src/locales'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  text: {
    margin: 50,
    color: colors.textMedium,
  },
})

export const ErrorView: FunctionComponent = () => (
  <SafeAreaView style={styles.container}>
    <Text style={styles.text}>{st.errors.errorOccurred()}</Text>
  </SafeAreaView>
)
