import { AddressInput } from '@sparelabs/web-ui-components'
import React from 'react'
import { HostStore } from 'src/api/HostStore'
import { IAddressField, IAddressFieldProps } from 'src/components/form/AddressField/AddressFieldProps'
import { handleError } from 'src/helpers/ErrorHelpers'
import { FieldContainer, FieldError } from '../FieldShared'

export const AddressField: IAddressField = (props: IAddressFieldProps) => (
  <FieldContainer>
    <AddressInput
      onChange={props.onChange}
      value={props.value}
      apiUrl={HostStore.getHost()}
      onError={(error) => handleError({ error })}
    />
    <FieldError touched={props.touched} error={props.error} />
  </FieldContainer>
)
