import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'
import { SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { st } from 'src/locales'

const ButtonDiameter = 40

const styles = StyleSheet.create({
  container: {
    paddingBottom: 15,
    marginTop: 30,
    maxWidth: 250,
  },
  touchable: {
    shadowColor: colors.darkShadowColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3,
    shadowOpacity: 1,
    elevation: 2, // For android shadow
    height: ButtonDiameter,
    borderRadius: ButtonDiameter / 2,
  },
  contentContainer: {
    flexDirection: 'row',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
  title: {
    fontSize: 14,
    color: colors.textLight,
  },
})

interface IProps {
  title: string
  icon: [IconPrefix, IconName]
  backgroundColor?: string
  onPress: () => void
}

export const AnnouncementHomeButton = (props: IProps) => {
  const renderContent = () => (
    <>
      <View style={styles.icon}>
        <FontAwesomeIconWrapper icon={props.icon} color={colors.textLight} size={16} />
      </View>
      <Text style={styles.title} numberOfLines={2}>
        {props.title}
      </Text>
    </>
  )

  const getTouchableStyle = () => [
    styles.touchable,
    props.backgroundColor ? { backgroundColor: props.backgroundColor } : null,
  ]

  return (
    <SafeAreaView style={styles.container}>
      <TouchableOpacity
        style={getTouchableStyle()}
        onPress={props.onPress}
        testID='announcementBtn'
        accessibilityRole='button'
        accessibilityHint={st.components.announcementHomeButton.accessibilityHint()}
      >
        <View style={styles.contentContainer}>{renderContent()}</View>
      </TouchableOpacity>
    </SafeAreaView>
  )
}
