import React from 'react'
import { colors } from 'src/assets/colors'
import { IconCircle } from 'src/components/IconCircle'
import { ImageMarker } from 'src/components/mapMarkers/ImageMarker'
import { IFixedLegPinMarkerProps } from 'src/components/mapMarkers/MapMarkerProps'
import { ANDROID_ANCHOR } from 'src/helpers/MapMarkerHelper'
import { IMarker, MarkerTypes } from 'src/types'

export const FixedLegPinMarker = (props: IFixedLegPinMarkerProps) => {
  const fixedLegPin: IMarker = {
    name: MarkerTypes.FixedLegPin,
    coordinate: props.location,
    anchor: ANDROID_ANCHOR,
    child: (
      <IconCircle icon='circle' color={colors.white} size={18} backgroundColor={props.routeColour} innerIconSize={6} />
    ),
  }
  return <ImageMarker {...fixedLegPin} />
}
