import React from 'react'
import { StyleSheet, TextInput } from 'react-native'
import { colors } from 'src/assets/colors'

const getStyles = () =>
  StyleSheet.create({
    textInput: {
      borderRadius: 10,
      borderWidth: 1,
      borderColor: colors.gray2,
      paddingTop: 8,
      paddingHorizontal: 8,
      fontSize: 15,
      flex: 1,
      minHeight: 100,
    },
  })

interface ITextInputBoxProps {
  value: string
  placeHolder: string
  onChangeText: (entry: string) => void
}

export const TextInputBox = ({ value, placeHolder, onChangeText }: ITextInputBoxProps) => (
  <TextInput
    style={getStyles().textInput}
    multiline={true}
    editable={true}
    underlineColorAndroid='transparent'
    placeholder={placeHolder}
    numberOfLines={2}
    onChangeText={onChangeText}
    value={value}
    textAlignVertical='top'
  />
)
