export enum Pathname {
  Home = '/',
  Search = '/search',
  RideOptions = '/estimate',
  RideOptionConfirmOrigin = '/estimate/confirm-origin',
  RideOptionsConfirmService = '/estimate/confirm-service',
  LyftPassLink = '/lyft-pass-link',
  Journey = '/journey',
  JourneyRideOptions = '/journey/estimate',
  JourneyConfirmService = '/journey/estimate/confirm-service',
  RequestActive = '/requests/active',
  RequestLastCompleted = '/requests/last-completed',
}
