import React, { Component } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import { colors } from 'src/assets/colors'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { OnboardingStore } from 'src/stores/OnboardingStore'
import { ServiceStore } from 'src/stores/ServiceStore'
import { OnDemandOnboarding } from '../components/onboarding/OnDemandOnboarding'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.sectionBlue,
  },
})

type Props = ParamsListRoot[ScreenName.Onboarding] & {
  handleDismiss: () => void
}

interface IState {
  pageIndex: number
}

export class OnboardingView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      pageIndex: 0,
    }
  }

  public handleIndexChanged(index) {
    this.setState({
      pageIndex: index,
    })
  }

  public handleFinish = async () => {
    /**
     * Mark onboarding as completed so the user is not forced to look through it again
     */
    OnboardingStore.setOnboardingAsCompleted()
    if (this.props.onFinish) {
      this.props.onFinish()
    }
    this.props.handleDismiss()
  }

  public renderOnboarding() {
    const brandName = AuthenticatorHelper.getOrganization().name
    return (
      <OnDemandOnboarding
        brandName={brandName}
        showPooling={ServiceStore.isPoolingEnabled}
        onFinish={this.handleFinish}
      />
    )
  }

  public render() {
    return <SafeAreaView style={styles.container}>{this.renderOnboarding()}</SafeAreaView>
  }
}

export const Onboarding = (props: ScreenPropsRoot<ScreenName.Onboarding>) => (
  <OnboardingView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
