import { IEstimateOutput, IRequestPostBody } from '@sparelabs/api-client'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { EstimatesUserInputParsed } from 'src/types'

export class RequestPostBodyBuilder {
  public static build = (
    estimatesUserInput: EstimatesUserInputParsed,
    estimate: IEstimateOutput,
    estimateMetadataInput: Record<string, unknown>
  ): IRequestPostBody => ({
    riderId: AuthenticatorHelper.getUser().id,
    estimateId: estimate.id,
    requestedPickupAddress: estimatesUserInput.requestedPickupAddress,
    requestedPickupLocation: estimate.requestedPickupLocation,
    requestedDropoffAddress: estimatesUserInput.requestedDropoffAddress,
    requestedDropoffLocation: estimate.requestedDropoffLocation,
    riders: estimate.riders,
    accessibilityFeatures: estimate.accessibilityFeatures,
    metadata: estimateMetadataInput,
    lockedToDutyId: estimate.lockedToDutyId,
  })
}
