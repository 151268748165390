import React from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { st } from 'src/locales'
import { OsType } from 'src/util/types'
import { PrimaryButton } from '../buttons/PrimaryButton'

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    margin: 0,
    marginBottom: 32,
    padding: 0,
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.sectionBlue,
  },
  imageContainer: {
    flex: 1,
    marginTop: 20,
  },
  buttonContainer: {
    height: 60,
    padding: 20,
  },
  descriptionContainer: {
    flex: 1,
    paddingTop: 30,
    alignItems: 'center',
    flexDirection: 'column',
    paddingHorizontal: Platform.OS === OsType.Web ? 60 : 30,
    justifyContent: 'flex-start',
  },
  pageTitle: {
    fontSize: 21,
    paddingBottom: 10,
    color: colors.selectedBlue,
    textAlign: 'center',
    fontWeight: '600',
    letterSpacing: 0.9,
  },
  description: {
    flex: 1,
    fontSize: 16,
    color: colors.selectedBlue,
    textAlign: 'center',
    letterSpacing: 0.7,
  },
})

// Do not give title as a prop: https://github.com/leecade/react-native-swiper/issues/618
interface IProps {
  pageTitle: string
  description: string
  image: SvgIcon
  onPress: () => void
  buttonText?: string
}

export const PageContainer = (props: IProps) => (
  <View style={styles.pageContainer}>
    <View style={styles.imageContainer}>
      <SvgIconWrapper heightFixed={300} widthFixed={300} icon={props.image} />
    </View>
    <View style={styles.descriptionContainer}>
      <Text style={styles.pageTitle}>{props.pageTitle}</Text>
      <Text style={styles.description}>{props.description}</Text>
    </View>
    <View style={styles.buttonContainer}>
      <PrimaryButton title={props.buttonText || st.screens.onBoarding.nextButton()} onPress={props.onPress} />
    </View>
  </View>
)
