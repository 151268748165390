export enum LyftRideType {
  Lux = 'lyft_lux',
  LyftPremier = 'lyft_premier',
  LyftLine = 'lyft_line',
  LyftLuxSuv = 'lyft_luxsuv',
  Lyft = 'lyft',
  LyftPlus = 'lyft_plus',
}

export interface ILyftEstimate {
  currency: string
  display_name: string
  estimated_cost_cents_max: number
  estimated_cost_cents_min: number
  estimated_duration_seconds: number
  is_valid_estimate: boolean
  ride_type: LyftRideType
}

export interface ILyftResponse {
  body: {
    cost_estimates: ILyftEstimate[]
  }
}
