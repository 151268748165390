import { RouteProp } from '@react-navigation/native'
import { InitialState, Route } from '@react-navigation/routers'
import { Platform } from 'react-native'
import { Constants } from 'src/consts/Constants'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { OsType } from 'src/util/types'

export enum RedirectParams {
  Stripe = 'stripe_redirect',
}

export enum RedirectValues {
  CardSetup = 'card_setup',
}

export enum NavigationPathname {
  Redirect = '/redirect',
}

// local definition replicating definition used in react-navigation, since it is not exported
export interface IRoute extends Omit<Route<string>, 'key'> {
  state?: InitialState
}

export const ROOT_HOME_ROUTES: IRoute[] = [{ name: ScreenName.RootHome }]
export const PAYMENT_METHOD_ROUTES = [
  {
    name: ScreenName.GroupAccount,
    state: {
      routes: [{ name: ScreenName.Account }, { name: ScreenName.PaymentMethods }],
    },
  },
]

export class NavigationStateHelper {
  public static getRootLoginRoutes(): Route<keyof ParamsListRoot> {
    let rootLoginRoutes: Route<keyof ParamsListRoot>
    if (
      AuthenticatorHelper.globalApp &&
      AuthenticatorHelper.globalApp.linkedOrganizations.length === 1 &&
      !Constants.SHOW_ALL_ORGANIZATIONS
    ) {
      const setRootLogin: RouteProp<ParamsListRoot, ScreenName.RootLogin> = {
        key: ScreenName.RootLogin,
        name: ScreenName.RootLogin,
        params: {
          title: ScreenName.RootLogin,
          authOrganization: AuthenticatorHelper.globalApp.linkedOrganizations[0],
        },
      }
      rootLoginRoutes = setRootLogin
    } else {
      const setOrganizationRoute: Route<keyof ParamsListRoot> = {
        key: ScreenName.RootSetOrganization,
        name: ScreenName.RootSetOrganization,
      }
      rootLoginRoutes = setOrganizationRoute
    }
    return rootLoginRoutes
  }

  public static getInitialState(isLoggedIn: boolean): InitialState {
    // use spread operator to make deep copy of array to prevent unintended behaviors
    const routes: IRoute[] = isLoggedIn ? [...ROOT_HOME_ROUTES] : [this.getRootLoginRoutes()]

    if (isLoggedIn && Platform.OS === OsType.Web && window.location.pathname === NavigationPathname.Redirect) {
      const urlParams = new URLSearchParams(window.location.search)
      const redirect = urlParams.get(RedirectParams.Stripe)
      if (redirect === RedirectValues.CardSetup) {
        /**
         * TODO: Handle redirects after an estimate is made
         */
        routes.push(...PAYMENT_METHOD_ROUTES)
      }
    }

    return { routes }
  }
}
