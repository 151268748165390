import { MapBoxContainer } from '@sparelabs/web-ui-components'
import deepEqual from 'deep-equal'
import { fromJS } from 'immutable'
import { observer } from 'mobx-react-lite'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import MapGL, { FlyToInterpolator } from 'react-map-gl'
import { StyleSheet } from 'react-native'
import { IMap, IMapProps } from 'src/components/map/MapTypes'
import { getLatitudeLongitudeDelta } from 'src/helpers/MapHelper'
import { UIStateStore } from 'src/stores/UIStore'
import { getNewViewportValues, IInteractionState, IViewport, onInteractionStateChange } from './MapWebHelper'
import SpareMapStyle from './spare-labs-map'

const DEFAULT_LATITUDE = 32.870778
const DEFAULT_LONGITUDE = -96.982711
const TRANSITION_DURATION_MAP_ZOOM = 750
const MAPBOX_TOKEN =
  'pk.eyJ1IjoiYWluZGVldi1zcGFyZWxhYnMiLCJhIjoiY2o0aGlqcW15MDJ3cTJxcnQ1Y3gyeTR6cCJ9.cpQl5MMJSSktfiTtdoSUBQ'

const styles = StyleSheet.create({
  map: {
    position: 'absolute',
    zIndex: 0,
  },
})

const interpolator = new FlyToInterpolator()

export const Map: IMap = observer((props: PropsWithChildren<IMapProps>): JSX.Element => {
  const [viewport, setViewport] = useState<IViewport>({
    latitude: props.latitude ?? DEFAULT_LATITUDE,
    longitude: props.longitude ?? DEFAULT_LONGITUDE,
    zoom: 14,
    bearing: 0,
    pitch: 0,
    width: 1,
    height: 1,
    transitionInterpolator: null,
  })

  const onViewportChange = (newViewport: IViewport) => {
    setViewport({ ...newViewport })
    if (props.onRegionChange) {
      props.onRegionChange({
        latitude: newViewport.latitude,
        longitude: newViewport.longitude,
        ...getLatitudeLongitudeDelta(),
      })
    }
  }

  const previousMapState = useRef(props.mapState)

  // for dynamic changes to location on the map
  useEffect(() => {
    if (
      props.mapState &&
      props.mapState.coordinates.length > 0 &&
      !deepEqual(previousMapState.current, props.mapState)
    ) {
      previousMapState.current = props.mapState
      const { shouldShowLandscapeWeb, screenHeight, screenWidth } = UIStateStore
      const { longitude, latitude, zoom } = getNewViewportValues(
        props.mapState,
        viewport,
        shouldShowLandscapeWeb,
        screenHeight,
        screenWidth
      )
      setViewport({
        ...viewport,
        latitude,
        longitude,
        zoom,
        transitionInterpolator: interpolator,
        transitionDuration: TRANSITION_DURATION_MAP_ZOOM,
      })
    }
  }, [props.mapState, viewport])

  return (
    <MapBoxContainer>
      <MapGL
        {...viewport}
        style={styles.map}
        width='100vw'
        height='100vh'
        mapStyle={fromJS(SpareMapStyle)}
        dragPan={!props.disableMovement}
        doubleClickZoom={!props.disableMovement}
        scrollZoom={!props.disableMovement}
        onViewportChange={(viewport: IViewport) => onViewportChange(viewport)}
        onInteractionStateChange={(newTransitionState: IInteractionState) => {
          onInteractionStateChange(newTransitionState, props, viewport)
        }}
        attributionControl={false}
        mapboxApiAccessToken={MAPBOX_TOKEN}
      >
        {props.children}
      </MapGL>
    </MapBoxContainer>
  )
})
