import React from 'react'
import { Dimensions, Platform, StyleSheet, View } from 'react-native'
import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { OsType } from 'src/util/types'

// These numbers were obtained through trial and error by placing a pin the middle
// of the map region and aligning this floating pin over it.
const getPinOffset = (insets: EdgeInsets): number => {
  if (Platform.OS === OsType.Android) {
    return 42
  }
  // This is bit of hacky check of whether or not we are on an iPhone with safe area views
  // Seems that the middle of the map is slightly shifted on these phones
  if (insets.bottom > 0) {
    return 33
  }
  return 30
}

const Screen = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

const getStyles = (pinOffset: number) =>
  StyleSheet.create({
    pinContainer: {
      position: 'absolute',
      marginTop: Screen.height / 2 - pinOffset,
      marginLeft: Screen.width / 2 - 12,
    },
  })

/**
 * This component renders a pin in the middle of the screen at the point that the map is centered on
 */
export const SetLocationPin = () => {
  const insets = useSafeAreaInsets()
  const pinOffset = getPinOffset(insets)
  const styles = getStyles(pinOffset)
  return (
    <View style={styles.pinContainer}>
      <SvgIconWrapper icon={SvgIcon.SetLocationPin} widthFixed={24} heightFixed={40} />
    </View>
  )
}
