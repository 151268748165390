import { IOrganizationResponse } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { HomeInfoBar } from './HomeInfoBar'

interface IProps {
  handleNavigateWebView: (params: ParamsListRoot[ScreenName.WebViewModal]) => void
  organization: IOrganizationResponse
}

export class BrandInfoBar extends Component<IProps> {
  public handlePressLearnMore = async () => {
    const uri = this.props.organization.faqUrl
    if (uri) {
      this.props.handleNavigateWebView({ uri })
    }
  }

  public render() {
    if (this.props.organization.faqUrl) {
      const brandName = this.props.organization.name
      const infoText = st.components.brandInfoBar.header({ brandName })
      return <HomeInfoBar infoText={infoText} onPress={this.handlePressLearnMore} />
    }
    return null
  }
}
