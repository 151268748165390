import { Alert, AlertButton, AlertOptions, Platform } from 'react-native'
import { WebAlertStore } from 'src/stores/WebAlertStore'
import { OsType } from 'src/util/types'

export class AlertHelper {
  public static alert(title: string, message?: string, buttons?: AlertButton[], options?: AlertOptions): void {
    if (Platform.OS === OsType.Web) {
      WebAlertStore.setAlert(title, message, buttons)
      return
    }

    Alert.alert(title, message, buttons, options)
  }
}
