import React, { Component } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 8,
    flexDirection: 'row',
    shadowColor: colors.darkShadowColor,
    elevation: 2,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3,
    shadowOpacity: 1,
    alignItems: 'center',
  },
  subtitleContainer: {
    overflow: 'hidden',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  text: {
    padding: 8,
    fontSize: 18,
    textAlign: 'center',
  },
  title: {
    color: colors.gray90,
  },
  subtitle: {
    backgroundColor: colors.blue10,
    color: colors.blue50,
  },
  image: {
    width: 12,
    height: 20,
    marginLeft: 10,
  },
})

interface IProps {
  title: string
  subtitle?: string
  editable?: boolean
}

export class FloatingLabel extends Component<IProps> {
  public render() {
    return (
      <View style={styles.container}>
        <Text style={[styles.text, styles.title]}>{this.props.title}</Text>
        {this.props.subtitle ? (
          <View style={styles.subtitleContainer}>
            <Text style={[styles.text, styles.subtitle]}>{this.props.subtitle}</Text>
          </View>
        ) : null}
        {this.props.editable ? <Image style={styles.image} source={Images.floatingLabelArrow} /> : null}
      </View>
    )
  }
}
