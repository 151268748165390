import React, { ReactNode } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { colors } from 'src/assets/colors'

const getStyles = () =>
  StyleSheet.create({
    buttonWrapper: {
      padding: 16,
      backgroundColor: 'white',
      borderTopWidth: 0.333,
      borderTopColor: colors.gray50,
    },
  })

interface IProps {
  styles?: StyleProp<ViewStyle>
  children?: ReactNode
}

export const ButtonWrapper = (props: IProps) => (
  <View style={[getStyles().buttonWrapper, props.styles ? props.styles : null]}>{props.children}</View>
)
