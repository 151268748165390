import * as ImagePicker from 'expo-image-picker'
import { Platform } from 'react-native'
import { st } from 'src/locales'
import { OsType } from 'src/util/types'

export class ImagePickerHelper {
  public static async pickCamera(): Promise<{ blob: Blob; uri: string } | null> {
    if (Platform.OS !== OsType.Web) {
      const { status } = await ImagePicker.requestCameraPermissionsAsync()
      if (status !== 'granted') {
        alert(st.screens.permissions.cameraAndPhotoAccess())
        return null
      }
    }
    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.5,
      allowsMultipleSelection: false,
    })
    if (!result.cancelled) {
      return { uri: result.uri, blob: new Blob() }
    }
    return null
  }

  public static async pickMediaLibrary(): Promise<{ blob: Blob; uri: string } | null> {
    if (Platform.OS !== OsType.Web) {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== 'granted') {
        alert(st.screens.permissions.cameraAndPhotoAccess())
        return null
      }
    }
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.5,
      allowsMultipleSelection: false,
    })
    if (!result.cancelled) {
      return { uri: result.uri, blob: new Blob() }
    }
    return null
  }
}
