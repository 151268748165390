import React, { FunctionComponent } from 'react'
import { ActivityIndicator, SafeAreaView, StyleSheet } from 'react-native'
import { colors } from 'src/assets/colors'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  loadingIndicator: {
    padding: 50,
  },
})

export const StripeSessionLoadingIndicator: FunctionComponent = () => (
  <SafeAreaView style={styles.container}>
    <ActivityIndicator color={colors.black} size='small' style={styles.loadingIndicator} />
  </SafeAreaView>
)
