import { IRequestResponse } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  serviceIcon: {
    height: 32,
    width: 32,
    borderRadius: 16,
  },
  divider: {
    width: 8,
  },
  text: {
    fontSize: 16,
    color: colors.gray90,
  },
})

interface IProps {
  request: IRequestResponse
}

export class RequestBrand extends Component<IProps> {
  public getServiceIconAndName() {
    const { request } = this.props
    const { photoUrl: serviceIconUri, name: serviceName } = request.serviceBrand
    return { serviceIconUri, serviceName }
  }

  public render() {
    const { serviceIconUri, serviceName } = this.getServiceIconAndName()
    return (
      <View style={styles.container}>
        <Image style={styles.serviceIcon} source={{ uri: serviceIconUri }} />
        <View style={styles.divider} />
        <Text style={styles.text}>{serviceName}</Text>
      </View>
    )
  }
}
