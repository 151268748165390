import { FontAwesomeIconStyle, IEstimateOutput } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'
import { roundSecondsToMinutesMinOne } from 'src/helpers/TimeHelper'
import { st } from 'src/locales'

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: colors.sectionBlue,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  info: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    flex: 12,
  },
  icon: {
    alignSelf: 'center',
    width: 40,
    flex: 1,
  },
  walkingText: {
    color: colors.titleBlue,
    fontSize: 16,
  },
})

interface IProps {
  estimate: IEstimateOutput
}

export class EstimateCardWalkingDirections extends Component<IProps> {
  public render() {
    const { estimate } = this.props
    if (MapMarkerHelper.showPickupRadius(estimate)) {
      return this.optimizedStopsDirections()
    } else if (!MapMarkerHelper.showStartLocation(estimate)) {
      return null
    }
    return this.walkingDirections(estimate)
  }

  private optimizedStopsDirections() {
    return (
      <View style={styles.container}>
        <View style={styles.info}>
          <Text style={[styles.walkingText]}>{st.components.rideOptionsCardHeader.pickupArea()}</Text>
        </View>
        <View style={styles.icon}>
          <FontAwesomeIconWrapper icon={[FontAwesomeIconStyle.Solid, 'walking']} size={28} color={colors.blue70} />
        </View>
      </View>
    )
  }

  private walkingDirections(estimate: IEstimateOutput) {
    const walkingDuration = roundSecondsToMinutesMinOne(estimate.pickupWalkingDuration)

    return (
      <View style={styles.container}>
        <View style={styles.info}>
          <Text style={[styles.walkingText, { marginRight: 2 }]}>
            {st.components.rideOptionsCardHeader.walkingDuration({ walkingDuration })}
          </Text>
          <Text style={[styles.walkingText, { fontWeight: 'bold' }]}>{estimate.scheduledPickupAddress}</Text>
        </View>
        <View style={styles.icon}>
          <FontAwesomeIconWrapper icon={[FontAwesomeIconStyle.Solid, 'walking']} size={28} color={colors.blue70} />
        </View>
      </View>
    )
  }
}
