import { createStackNavigator } from '@react-navigation/stack'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { st } from 'src/locales'
import { ScreenName } from 'src/navigation'
import { getScreenOptionsWithBackButton } from 'src/navigation/consts/NavigationHelper'
import { NavigatorSettings } from 'src/navigation/consts/NavigatorSettings'
import { ParamsListScheduledTripList } from 'src/navigation/types/ScreenParamsListTypes'
import { ScheduledTrip } from 'src/screens/request/ScheduledRequests/ScheduledTrip'
import { ScheduledTripsList } from 'src/screens/request/ScheduledRequests/ScheduledTripsList'

const Stack = createStackNavigator<ParamsListScheduledTripList>()

export const NavigationStackScheduledTrips = observer(() => (
  <Stack.Navigator
    screenOptions={{
      headerStatusBarHeight: NavigatorSettings.headerStatusBarHeight,
    }}
  >
    <Stack.Screen
      name={ScreenName.ScheduledTripsList}
      component={ScheduledTripsList}
      options={({ navigation }) => getScreenOptionsWithBackButton(navigation, st.screens.scheduledTrips.title())}
    />
    <Stack.Screen
      name={ScreenName.ScheduledTrip}
      component={ScheduledTrip}
      options={({ navigation }) => ({
        ...getScreenOptionsWithBackButton(navigation, st.screens.scheduledTrip.title()),
        // The presentation of this screen appears as a modal without this override of the presentation style
        presentation: 'card',
      })}
    />
  </Stack.Navigator>
))
