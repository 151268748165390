import axios from 'axios'
import { Linking, Platform } from 'react-native'
import semver from 'semver'
import { getSupportedClientVersion } from 'src/api'
import { Constants } from 'src/consts/Constants'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { st } from 'src/locales'
import { AlertButtonStyle, OsType } from 'src/util/types'

export class AppVersionHelper {
  public static alertShowing: boolean = false

  public static canOpenAppUrl = async (url: string | null): Promise<boolean> => (url ? Linking.canOpenURL(url) : false)

  public static getAppStoreUrl = async (): Promise<string | null> => {
    if (Platform.OS === OsType.Android) {
      return `https://play.google.com/store/apps/details?id=${Constants.APP_ID}`
    }
    return AppVersionHelper.getAppleAppStoreUrlFromBundle()
  }

  public static isAppOutdated = async (): Promise<boolean> => {
    // Minimum version from API
    const res = await getSupportedClientVersion()

    // Return true if the current version is less than the minimum version
    if (res && res.minVersion && semver.lt(Constants.APP_VERSION, res.minVersion)) {
      return true
    }

    return false
  }

  public static checkIsAppOutdated = async () => {
    // If app is outdated then show a link to app store if it exists and the user can open the URL
    if (await AppVersionHelper.isAppOutdated()) {
      const appStoreUrl = await AppVersionHelper.getAppStoreUrl()
      const canOpenAppUrl = await AppVersionHelper.canOpenAppUrl(appStoreUrl)

      if (!AppVersionHelper.alertShowing) {
        AppVersionHelper.alertShowing = true
        AlertHelper.alert(
          st.appVersion.alertTitle(),
          canOpenAppUrl
            ? st.appVersion.alertMessage({ appName: Constants.APP_NAME })
            : st.appVersion.alertMessageAlternative({ appName: Constants.APP_NAME }),
          [
            {
              style: AlertButtonStyle.Default,
              text: canOpenAppUrl ? st.appVersion.update() : st.common.alertOk(),
              onPress: () => AppVersionHelper.openAppStoreUrl(appStoreUrl, canOpenAppUrl),
            },
          ],
          { cancelable: false }
        )
      }
    }
  }

  public static openAppStoreUrl = async (appStoreUrl: string | null, canOpenAppUrl: boolean) => {
    if (appStoreUrl && canOpenAppUrl) {
      await Linking.openURL(appStoreUrl)
    }

    AppVersionHelper.alertShowing = false
  }

  // This fetches the app URL from the iTunes API, based on the bundle ID
  // Note that this will not work on staging or dev builds
  private static readonly getAppleAppStoreUrlFromBundle = async (): Promise<string | null> => {
    const response = await axios.get(`http://itunes.apple.com/lookup?bundleId=${Constants.APP_ID}`)
    if (response.data && response.data.resultCount > 0) {
      return response.data.results[0].trackViewUrl
    }
    return null
  }
}
