import { useHeaderHeight } from '@react-navigation/elements'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { KeyboardAvoidingView, Platform, ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

interface IProps {
  children: JSX.Element | undefined | Array<JSX.Element | undefined>
  verticalOffset?: number
  containerStyle?: { [key: string]: string | number }
}

export interface IKeyboardSafeScrollView {
  scrollTo: (y: number) => void
}

export const KeyboardSafeScrollView = forwardRef(
  ({ children, containerStyle, verticalOffset = 0 }: IProps, ref): JSX.Element => {
    const headerHeight = useHeaderHeight()
    const insets = useSafeAreaInsets()
    const scrollRef = useRef<ScrollView>(null)

    useImperativeHandle(ref, () => ({
      scrollTo(y: number) {
        scrollRef.current?.scrollTo({
          y,
          animated: true,
        })
      },
    }))

    return (
      <View style={{ flex: 1, ...containerStyle }}>
        <KeyboardAvoidingView
          behavior='padding'
          style={{ flex: 1 }}
          keyboardVerticalOffset={headerHeight + insets.top + verticalOffset}
          enabled={Platform.OS === 'ios'}
        >
          <ScrollView ref={scrollRef}>{children}</ScrollView>
        </KeyboardAvoidingView>
      </View>
    )
  }
)

KeyboardSafeScrollView.displayName = 'KeyboardSafeScrollView'
