import { FieldLabel, MultipleChoiceInput } from '@sparelabs/web-ui-components'
import React from 'react'
import { FieldContainer, FieldError } from 'src/components/form/FieldShared'
import { IMultipleChoiceField, IMultipleChoiceProps } from './MultipleChoiceFieldProps'

export const MultipleChoiceField: IMultipleChoiceField = (props: IMultipleChoiceProps) => {
  const selectedChoices = props.choices
    .filter((choice) => props.value?.includes(choice.value))
    .map((choice) => choice.value)
  return (
    <FieldContainer>
      <FieldLabel>{props.label}</FieldLabel>
      <MultipleChoiceInput {...props} values={selectedChoices} />
      <FieldError touched={props.touched} error={props.error} />
    </FieldContainer>
  )
}
