import { ConnectedAccountType } from '@sparelabs/api-client'
import { maxBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { FunctionComponent } from 'react'
import { ErrorView } from 'src/components/error/ErrorView'
import { PaymentFlowHelper } from 'src/helpers/payments/PaymentFlowHelper'
import { ParamsListAddPaymentMethod, ScreenName, ScreenPropsAddPaymentMethod } from 'src/navigation'
import { StripeSessionView } from 'src/screens/payments/stripeSessionsView/StripeSessionView'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'

type Props = ParamsListAddPaymentMethod[ScreenName.AddStripePaymentMethodSession] & {
  handleDismiss: () => void
}

export const AddStripePaymentMethodSessionView: FunctionComponent<Props> = observer((props: Props) => {
  const publishableKey = PaymentFlowHelper.getStripePublishableKey()
  if (!publishableKey) {
    return <ErrorView />
  }
  return (
    <StripeSessionView
      stripeMethodType={props.stripeMethodType}
      publishableKey={publishableKey}
      onSuccess={async () => {
        /**
         * Since we don't call the spare API directly to add the payment method, there is
         * no clean way to retrieve the paymentMethodId.
         *
         * What we do is update all the payment methods and the select the most recently added Stripe method from the list
         */
        await PaymentMethodStore.getPaymentMethods()
        const newestPaymentMethod = maxBy(
          PaymentMethodStore.paymentMethods.filter((pm) => pm.connectedAccountType === ConnectedAccountType.Stripe),
          (pm) => pm.createdAt
        )
        if (newestPaymentMethod) {
          await props.onPaymentMethodSuccessfullyAdded(newestPaymentMethod.id)
        }
      }}
      onCancel={() => {
        props.handleDismiss()
      }}
    />
  )
})

export const AddStripePaymentMethodSession = (
  props: ScreenPropsAddPaymentMethod<ScreenName.AddStripePaymentMethodSession>
) => <AddStripePaymentMethodSessionView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
