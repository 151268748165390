import { observer } from 'mobx-react-lite'
import React, { ReactNode } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { colors } from 'src/assets/colors'
import { UIStateStore } from 'src/stores/UIStore'

const styles = StyleSheet.create({
  container: {
    bottom: 0,
    paddingTop: 16,
    backgroundColor: colors.white,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    shadowColor: colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3,
    shadowOpacity: 1,
    elevation: 3,
  },
  content: {
    flex: 1,
    flexBasis: 'auto',
  },
  informationBannerOverride: {
    paddingTop: 0,
  },
  webOverride: {
    borderRadius: 8,
  },
})

export const FloatingCard = observer(
  (props: { children?: ReactNode; customStyle?: StyleProp<ViewStyle>; informationBanner?: JSX.Element }) => (
    <View
      style={[
        styles.container,
        props.informationBanner ? styles.informationBannerOverride : {},
        props.customStyle,
        UIStateStore.shouldShowLandscapeWeb ? styles.webOverride : {},
      ]}
    >
      <View style={styles.content}>{props.informationBanner}</View>
      <View style={styles.content}>{props.children}</View>
    </View>
  )
)
