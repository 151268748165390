import React, { Component } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { ButtonWrapper } from 'src/components/buttons/ButtonWrapper'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { DateTimeInput } from 'src/components/datepicker/DateTimeInput'
import { DateTimeDisplay } from 'src/components/datepicker/DateTimeInputTypes'
import { FieldModalWrapper, ModalHint } from 'src/components/form/ModalShared'
import { moment, Moment } from 'src/helpers/Moment'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'
import { OsType } from 'src/util/types'

interface IState {
  selectedMoment: Moment
}

const getStyles = () =>
  StyleSheet.create({
    bodyContainer: {
      backgroundColor: colors.blue10,
      flex: 1,
    },
    dateTimePickerWrapper: {
      flex: 1,
      padding: 16,
      backgroundColor: 'white',
    },
    dateTimePickerWrapperAndroid: {
      padding: 16,
    },
  })

type Props = ParamsListRoot[ScreenName.DatetimeModal] & {
  handleDismiss: () => void
}

export class DatetimeView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)
    this.state = { selectedMoment: props.initialMoment ?? moment() }
  }

  public render() {
    const styles = getStyles()
    const { hint, mode, onSubmit, handleDismiss } = this.props
    return (
      <FieldModalWrapper>
        <View style={styles.bodyContainer}>
          <ModalHint hint={hint} />
          <View
            style={[
              styles.dateTimePickerWrapper,
              ...(Platform.OS === OsType.Android ? [styles.dateTimePickerWrapperAndroid] : []),
            ]}
          >
            <DateTimeInput
              showTitle={true}
              date={this.state.selectedMoment}
              display={DateTimeDisplay.Spinner}
              onHandleDateChange={(selectedMoment) => this.setState({ selectedMoment })}
              mode={mode}
            />
          </View>
        </View>
        <ButtonWrapper>
          <PrimaryButton
            title={st.common.done()}
            onPress={async () => {
              onSubmit(this.state.selectedMoment)
              handleDismiss()
            }}
          />
        </ButtonWrapper>
      </FieldModalWrapper>
    )
  }
}

export const DatetimeModal = (props: ScreenPropsRoot<ScreenName.DatetimeModal>) => (
  <DatetimeView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
