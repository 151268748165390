import { IconName } from '@fortawesome/pro-solid-svg-icons'
import React, { Component } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { IconCircle } from '../IconCircle'

const styles = StyleSheet.create({
  buttonContainer: {
    width: '20%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 3,
  },
})

interface IProps {
  onPress?: () => void
  active: boolean
  icon: IconName
}

export class FavoriteTypeIconButton extends Component<IProps> {
  public render() {
    return (
      <View style={styles.buttonContainer}>
        <TouchableOpacity onPress={this.props.onPress}>
          <IconCircle
            icon={this.props.icon}
            color={this.getIconColor()}
            size={45}
            backgroundColor={this.getBackgroundColor()}
          />
        </TouchableOpacity>
      </View>
    )
  }

  private getIconColor() {
    if (this.props.active) {
      return 'white'
    }
    return colors.textDark
  }

  private getBackgroundColor() {
    if (this.props.active) {
      return colors.selectedBlue
    }
    return 'white'
  }
}
