import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { PaymentMethodType } from 'src/helpers/payments/PaymentMethodHelper'

export class PaymentProviderHelper {
  public static getValidProviders(paymentMethodTypeRequired?: PaymentMethodType[]) {
    // Make sure that we are only checking the valid providers for this operation
    // as sometimes we want to restrict payment method types
    return AuthenticatorHelper.getOrganization().paymentProviders.filter((p) => {
      if (paymentMethodTypeRequired) {
        return paymentMethodTypeRequired.includes(p.connectedAccountType)
      }
      return true
    })
  }
}
