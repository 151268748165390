import { Currency } from '@sparelabs/currency'
import React from 'react'
import { AccessibilityRole, Image, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'
import { getDropoffTimeString } from 'src/helpers/EstimateHelper'
import { FareRenderer } from 'src/helpers/FareRenderer'
import { moment } from 'src/helpers/Moment'
import { getPressInDelay } from 'src/helpers/RideOptionsCardHelper'
import { st } from 'src/locales'
import { ILyftEstimate, LyftRideType } from 'src/types/lyftTypes'
import { ExternalRideOptions } from 'src/types/rideOptions'

const IMAGE_SIZE = 52

const styles = StyleSheet.create({
  bodyContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderBottomWidth: 1,
    borderColor: colors.borderBlue,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  selected: {
    borderLeftColor: colors.blue50,
    borderLeftWidth: 4,
    backgroundColor: colors.blue10,
  },
  deselected: {
    paddingLeft: 4,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  bodyTitle: {
    fontSize: 16,
    color: colors.gray90,
    fontWeight: '600',
  },
  bodySubtitle: {
    fontSize: 14,
    color: colors.gray70,
  },
  serviceIcon: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
    backgroundColor: 'white',
    borderRadius: IMAGE_SIZE / 2,
  },
  dropoffServiceContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 8,
    paddingRight: 8,
  },
  fareContainer: {
    paddingHorizontal: 8,
    paddingTop: 8,
  },
  fareText: {
    fontSize: 16,
    color: colors.gray90,
  },
  disabled: {
    color: colors.gray50,
    fontSize: 14,
  },
})

interface IProps {
  handleLyftPress: () => void
  selectedRideOptionId: string | null
  estimates: ILyftEstimate[]
  accessibilityRole?: AccessibilityRole
  accessibilityLabel?: string
  accessibilityHint?: string
}

export function LyftPassCard({
  handleLyftPress,
  selectedRideOptionId,
  estimates,
  accessibilityHint,
  accessibilityLabel,
  accessibilityRole,
}: IProps): JSX.Element | null {
  const renderPrice = (estimatedCostMin: number, estimatedCostMax: number) => (
    <View style={styles.fareContainer}>
      <Text style={styles.fareText}>
        {`${FareRenderer.renderPriceAmount({
          fare: {
            cost: estimatedCostMin,
            discount: 0,
            total: estimatedCostMin,
            currency: Currency.CAD,
          },
        })}-${FareRenderer.renderPriceAmount({
          fare: {
            cost: estimatedCostMax,
            discount: 0,
            total: estimatedCostMax,
            currency: Currency.CAD,
          },
        })}`}
      </Text>
    </View>
  )

  const renderServiceAndDropoff = (estimatedDropOff: number): JSX.Element => {
    const dropOffTime = moment().add(estimatedDropOff, 'seconds').format('LT')
    return (
      <View style={styles.dropoffServiceContainer}>
        <Text style={[styles.bodyTitle]}>{st.components.rideOptionsCardBody.lyftPass()}</Text>
        <Text style={styles.bodySubtitle}>{getDropoffTimeString(dropOffTime, dropOffTime, dropOffTime, false)}</Text>
      </View>
    )
  }

  const estimate = estimates.find((estimate) => estimate.ride_type === LyftRideType.Lyft)
  if (!estimate) {
    return null
  }

  return (
    <TouchableListItemWrapper
      onPress={handleLyftPress}
      delayPressIn={getPressInDelay()}
      accessibilityHint={accessibilityHint}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole={accessibilityRole}
      accessibilityState={{ selected: selectedRideOptionId === ExternalRideOptions.Lyft }}
    >
      <View style={[styles.infoContainer, selectedRideOptionId === ExternalRideOptions.Lyft && styles.selected]}>
        <View style={styles.bodyContainer}>
          <Image style={styles.serviceIcon} source={Images.lyftLogo} />
          <View style={styles.container}>
            {renderServiceAndDropoff(estimate.estimated_duration_seconds)}
            {renderPrice(estimate.estimated_cost_cents_min, estimate.estimated_cost_cents_max)}
          </View>
        </View>
      </View>
    </TouchableListItemWrapper>
  )
}
