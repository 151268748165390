import React, { useEffect, useState } from 'react'
import { AnnouncementHelper } from 'src/helpers/AnnouncementHelper'
import { Poller } from 'src/helpers/Poller'
import { MINUTE_IN_MS } from 'src/helpers/TimeHelper'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { AnnouncementHomeButton } from 'src/screens/home/AnnouncementHomeButton'
import { AnnouncementStore } from 'src/stores/AnnouncementStore'
import { LocationStore } from 'src/stores/LocationStore'

const ANNOUNCEMENT_POLL_TIMEOUT = 2 * MINUTE_IN_MS

interface IProps {
  handleNavigateListAnnouncements: () => void
  handleNavigateAcknowledgeableAnnouncement: (
    params: ParamsListRoot[ScreenName.AcknowledgeableAnnouncementModal]
  ) => void
}

export const AnnouncementHomeView = (props: IProps) => {
  /**
   * Poll for announcements while this view is open
   */
  const [isAcknowledging, setIsAcknowledging] = useState(false)

  const acknowledgeAnnouncements = () => {
    /**
     * If we are not already acknowledging an announcement,
     */
    if (!isAcknowledging) {
      for (const announcement of AnnouncementStore.announcements) {
        if (announcement.isAcknowledgementRequired && !AnnouncementStore.isAcknowledged(announcement.id)) {
          setIsAcknowledging(true)
          props.handleNavigateAcknowledgeableAnnouncement({
            announcement,
            onAcknowledge: () => {
              setIsAcknowledging(false)
              /**
               * Once we have finished acknowledging the announcements,
               * we can check if there are more left by running this function again
               */
              acknowledgeAnnouncements()
            },
          })
          break
        }
      }
    }
  }

  useEffect(() => {
    const poller = new Poller(async () => {
      await AnnouncementStore.getAnnouncements(LocationStore.currentLocation)
      acknowledgeAnnouncements()
    })
    poller.start(ANNOUNCEMENT_POLL_TIMEOUT, true)
    return () => {
      poller.stop()
    }
  }, [])

  const announcementCount = AnnouncementStore.announcements.length
  if (announcementCount > 0) {
    const { icon, title, color } = AnnouncementStore.getFirstAnnouncement()
    const announcementTitle =
      announcementCount > 1 ? st.components.announcementButton.title({ announcementCount }) : title
    return (
      <AnnouncementHomeButton
        onPress={props.handleNavigateListAnnouncements}
        title={announcementTitle}
        icon={AnnouncementHelper.getAnnouncementIcon(icon)}
        backgroundColor={color}
      />
    )
  }
  return null
}
