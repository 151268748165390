import { Language } from '@sparelabs/api-client'
import { LanguageKey } from '@sparelabs/translate'
import * as Localization from 'expo-localization'
import { moment } from 'src/helpers/Moment'

export class LanguageHelper {
  public static getCurrentLanguageCode(): LanguageKey {
    const locale = this.getCurrentLanguageCodeFull()
    return locale ? locale.substring(0, 2) : 'en'
  }

  public static getCurrentLanguageCodeFull(): LanguageKey {
    return Localization.locale ?? 'en'
  }

  // Process the locale to ensure setting the locale in moment doesn't fail - see https://github.com/moment/moment/issues/3624 for context
  public static getMomentLocale(): string {
    let locale = this.getCurrentLanguageCodeFull()

    // Will fix crashes caused by "en-GB" (instead of "en-gb") not being found
    locale = locale.toLowerCase()

    // Check if the locale is included in the array returned by `locales()` which (in this case) tells us which locales moment will support
    if (moment.locales().includes(locale)) {
      return locale
    } else if (moment.locales().includes(locale.substring(0, 2))) {
      // Check if the first two letters of the locale are included in the array returned by `locales()` which (in this case) tells us which
      // locales moment will support will fixes crashes caused by "en-US" not being found, as we'll tell moment to load "en" instead
      return locale.substring(0, 2)
    }
    // use "en" as a fallback if we can't find any other locale
    return 'en'
  }

  public static getFormattedName(firstName: string, lastName: string): string {
    const languageCode = this.getCurrentLanguageCode()
    switch (languageCode) {
      case Language.Japanese:
        return `${lastName} ${firstName}`
      default:
        return `${firstName} ${lastName}`
    }
  }

  public static getFormattedCount(type: string, count: number | string): string {
    const languageCode = this.getCurrentLanguageCode()
    switch (languageCode) {
      case Language.Japanese:
        return `${type} ${count}`
      default:
        return `${count} ${type}`
    }
  }

  public static getLocalizedNoun(noun: string): string {
    const languageCode = this.getCurrentLanguageCode()
    switch (languageCode) {
      case Language.German:
        return noun
      default:
        return noun.toLowerCase()
    }
  }
}
