import React from 'react'
import { ImageMarker } from 'src/components/mapMarkers/ImageMarker'
import { IMarkerProps } from 'src/components/mapMarkers/MapMarkerProps'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import {
  ANDROID_ANCHOR,
  mapMarkerStyles,
  PICKUP_DROPOFF_PIN_OFFSET,
  PICKUP_DROPOFF_PIN_SIZE,
} from 'src/helpers/MapMarkerHelper'
import { IMarker, MarkerTypes } from 'src/types'

export const DropoffPinMarker = (props: IMarkerProps) => {
  const dropoffPinMarker: IMarker = {
    name: MarkerTypes.DropoffPin,
    anchor: ANDROID_ANCHOR,
    coordinate: props.location,
    imageStyle: mapMarkerStyles.pickupDropoffPin,
    webCenterOffset: { offsetLeft: -PICKUP_DROPOFF_PIN_OFFSET, offsetTop: -PICKUP_DROPOFF_PIN_OFFSET },
    child: (
      <SvgIconWrapper
        widthFixed={PICKUP_DROPOFF_PIN_SIZE}
        heightFixed={PICKUP_DROPOFF_PIN_SIZE}
        icon={SvgIcon.DropoffPin}
      />
    ),
  }
  return <ImageMarker {...dropoffPinMarker} />
}
