import { NavigationProp } from '@react-navigation/native'
import { AddressFieldName, AddressFieldNameNoCompanyAndPhone, AddressFormatter, IAddress } from '@sparelabs/address'
import { ICustomFieldChoice } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { ScrollView, View } from 'react-native'
import { HostStore } from 'src/api/HostStore'
import { colors } from 'src/assets/colors'
import { ButtonWrapper } from 'src/components/buttons/ButtonWrapper'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { FieldError } from 'src/components/form/FieldShared'
import { FieldModalWrapper, ModalHint } from 'src/components/form/ModalShared'
import { SingleChoiceField } from 'src/components/form/SingleChoiceField/SingleChoiceField'
import { TextField } from 'src/components/textField/TextField'
import { st } from 'src/locales'
import { LanguageHelper } from 'src/locales/LanguageHelper'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'

interface IState {
  format: AddressFieldName[][]
  labelTable: Record<AddressFieldNameNoCompanyAndPhone, string> | undefined
  zoneChoices: ICustomFieldChoice[]
  address?: Partial<IAddress>
  addressFormatter: AddressFormatter
  countryChoices: ICustomFieldChoice[]
}

type Props = ParamsListRoot[ScreenName.Address] & {
  // only to be used for fields, other navigation actions should be explicitly specified
  navigation: NavigationProp<ParamsListRoot>
}

export class AddressModalView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      format: [],
      labelTable: undefined,
      zoneChoices: [],
      address: this.props.value,
      addressFormatter: new AddressFormatter(HostStore.getHost(), LanguageHelper.getCurrentLanguageCodeFull()),
      countryChoices: [],
    }
  }

  public async componentDidMount() {
    const { address, addressFormatter } = this.state
    const countries = await addressFormatter.getCountries()
    this.setState({ countryChoices: countries.map((country) => ({ value: country.code, label: country.name })) })
    if (address && address.country) {
      await this.updateStates(address.country)
    }
  }

  public async componentDidUpdate(prevProps, prevState) {
    const { address } = this.state
    if (address && address.country && prevState.address.country !== address.country) {
      await this.updateStates(address.country)
    }
  }

  private async updateStates(countryCode: string) {
    const { addressFormatter } = this.state
    const country = await addressFormatter.getCountry(countryCode)
    this.setState({ format: await addressFormatter.getOrderedFields(countryCode) })
    this.setState({ zoneChoices: country.zones.map((zone) => ({ value: zone.code, label: zone.name })) })
    this.setState({ labelTable: addressFormatter.createLabelTable(country) })
  }

  public render() {
    const { fieldKey, touched, error, keyboardType, hint, disabled, onChange } = this.props
    const { format, labelTable, zoneChoices, address, countryChoices } = this.state

    const setStateTable: Record<AddressFieldNameNoCompanyAndPhone, (arg: string) => void> = {
      [AddressFieldName.FirstName]: (firstName) => this.setState({ address: { ...address, firstName } }),
      [AddressFieldName.LastName]: (lastName) => this.setState({ address: { ...address, lastName } }),
      [AddressFieldName.Country]: (country) => this.setState({ address: { ...address, country } }),
      [AddressFieldName.City]: (city) => this.setState({ address: { ...address, city } }),
      [AddressFieldName.PostalCode]: (zip) => this.setState({ address: { ...address, zip } }),
      [AddressFieldName.Zone]: (province) => this.setState({ address: { ...address, province } }),
      [AddressFieldName.Address1]: (address1) => this.setState({ address: { ...address, address1 } }),
      [AddressFieldName.Address2]: (address2) => this.setState({ address: { ...address, address2 } }),
    }

    const stateTable: Record<AddressFieldNameNoCompanyAndPhone, string> = {
      [AddressFieldName.FirstName]: address?.firstName ?? '',
      [AddressFieldName.LastName]: address?.lastName ?? '',
      [AddressFieldName.Country]: address?.country ?? '',
      [AddressFieldName.City]: address?.city ?? '',
      [AddressFieldName.PostalCode]: address?.zip ?? '',
      [AddressFieldName.Zone]: address?.province ?? '',
      [AddressFieldName.Address1]: address?.address1 ?? '',
      [AddressFieldName.Address2]: address?.address2 ?? '',
    }

    const renderField = (field: AddressFieldName, labelTable: Record<AddressFieldNameNoCompanyAndPhone, string>) => (
      <View>
        {field === AddressFieldName.Country ? (
          <SingleChoiceField
            navigation={this.props.navigation}
            fieldKey={field}
            label={labelTable[field]}
            value={stateTable[field]}
            onChange={setStateTable[field]}
            choices={countryChoices}
          />
        ) : field === AddressFieldName.Zone ? (
          <SingleChoiceField
            navigation={this.props.navigation}
            fieldKey={field}
            label={labelTable[field]}
            value={stateTable[field]}
            onChange={setStateTable[field]}
            choices={zoneChoices}
          />
        ) : (
          <TextField
            value={stateTable[field]}
            textColor={colors.gray90}
            fontSize={16}
            label={labelTable[field]}
            baseColor={colors.gray70}
            labelFontSize={16}
            tintColor={touched && error ? colors.red : colors.primaryColor}
            onChangeText={setStateTable[field]}
            keyboardType={keyboardType ?? 'default'}
            disabled={disabled}
            testID={fieldKey}
          />
        )}
      </View>
    )

    return (
      <FieldModalWrapper>
        <ModalHint hint={hint} />
        <View style={{ flex: 1, padding: 20, backgroundColor: colors.white }}>
          <ScrollView style={{ flex: 1 }}>
            {labelTable &&
              format.map((row) =>
                row
                  .filter((field) => field !== AddressFieldName.Phone && field !== AddressFieldName.Company)
                  .map((field) => renderField(field, labelTable))
              )}
            <FieldError touched={touched} error={error} />
          </ScrollView>
        </View>
        <ButtonWrapper>
          <PrimaryButton
            title={st.common.done()}
            onPress={async () => {
              onChange({ ...address })
              this.props.navigation.goBack()
            }}
          />
        </ButtonWrapper>
      </FieldModalWrapper>
    )
  }
}

export const AddressModal = (props: ScreenPropsRoot<ScreenName.Address>) => <AddressModalView {...props.route.params} />
