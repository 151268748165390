import React, { PropsWithChildren } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'

const getStyles = () =>
  StyleSheet.create({
    hintText: {
      color: colors.gray50,
      paddingVertical: 5,
    },
    errorText: {
      color: colors.red,
    },
    row: {
      marginTop: 36,
      marginBottom: 8,
      borderBottomWidth: 1,
      borderBottomColor: colors.gray30,
    },
    rowWithValue: {
      marginTop: 15,
    },
    label: {
      color: colors.gray70,
      fontSize: 16,
      paddingBottom: 4,
    },
    value: {
      color: colors.gray90,
      fontSize: 16,
      paddingBottom: 4,
    },
  })

export const FieldHint = (props: { hint?: string }) =>
  props.hint ? <Text style={getStyles().hintText}>{props.hint}</Text> : null

export const FieldError = ({ touched, error }: { touched?: boolean; error?: string }) =>
  touched && error ? <Text style={getStyles().errorText}>{error}</Text> : null

export const FieldContainer = (props: PropsWithChildren<{}>) => (
  <View style={{ paddingBottom: 16 }}>{props.children}</View>
)

/**
 * Looks and feels like a text input field, but in reality is just a placeholder to trigger a modal.
 */
export const FakeTextField = (props: {
  label: string
  value: string
  disabled?: boolean
  onPress: () => void | Promise<void>
}) => {
  const styles = getStyles()
  return (
    <TouchableOpacity
      style={[styles.row, ...(props.value ? [styles.rowWithValue] : [])]}
      disabled={props.disabled}
      onPress={props.onPress}
    >
      <Text style={styles.label}>{props.label}</Text>
      {Boolean(props.value) && <Text style={styles.value}>{props.value}</Text>}
    </TouchableOpacity>
  )
}
