import React from 'react'
import { SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'

const ButtonDiameter = 40

const styles = StyleSheet.create({
  container: {
    width: ButtonDiameter,
    marginTop: 30,
    marginRight: 8,
  },
  touchable: {
    shadowColor: colors.darkShadowColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3,
    shadowOpacity: 1,
    elevation: 2, // For android shadow
    width: ButtonDiameter,
    height: ButtonDiameter,
    backgroundColor: 'white',
    borderRadius: ButtonDiameter / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 15,
    width: 15,
  },
})

interface IProps {
  onPress: () => void
}

export const EmptyScheduledRequests = () => <View style={styles.container} />

export const HomeScheduledRequestsButton = (props: IProps) => (
  <SafeAreaView style={styles.container}>
    <TouchableOpacity style={styles.touchable} onPress={props.onPress}>
      <FontAwesomeIconWrapper icon='calendar-alt' style={styles.image} size={16} color={colors.selectedBlue} />
    </TouchableOpacity>
  </SafeAreaView>
)
