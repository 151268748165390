import React from 'react'
import { colors } from 'src/assets/colors'
import { AbstractButton, IButtonProps } from './AbstractButton'

export const CancelButton = (props: IButtonProps) => (
  <AbstractButton
    loading={props.loading}
    disabled={props.disabled}
    shadow={props.shadow}
    onPress={props.onPress}
    title={props.title}
    subtitle={props.subtitle}
    testID={props.testID}
    textColor={colors.red70}
    backgroundColor={colors.red10}
    paddingHorizontal={props.paddingHorizontal}
  />
)
