import React from 'react'
import { TouchableOpacity } from 'react-native'
import { colors } from 'src/assets/colors'
import { ITouchableListItemWrapperProps } from 'src/components/touchableListItemWrapper/TouchableListItemWrapperTypes'
import styled from 'styled-components'

const StyledHoverWrapper = styled.div`
  background-color: ${colors.white};

  &:focus,
  &:hover {
    background-color: ${colors.borderBlue};
  }
`

export const TouchableListItemWrapper = (props: ITouchableListItemWrapperProps) => (
  <StyledHoverWrapper>
    <TouchableOpacity
      disabled={props.disabled}
      style={props.style}
      testID={props.testPropertiesKey}
      delayPressIn={props.delayPressIn}
      {...props}
    >
      {props.children}
    </TouchableOpacity>
  </StyledHoverWrapper>
)
