import { NavigationProp } from '@react-navigation/native'
import { DependencyList, useEffect } from 'react'

enum AllowedNavigationActions {
  Navigate = 'NAVIGATE',
  Reset = 'RESET',
  Pop = 'POP',
}

export const useNavigationPreventBackButton = (
  condition: () => boolean,
  navigation: NavigationProp<any>,
  list: DependencyList
) => {
  useEffect(() => {
    navigation.addListener('beforeRemove', (e) => {
      if (
        e.data.action.type !== AllowedNavigationActions.Navigate &&
        e.data.action.type !== AllowedNavigationActions.Reset &&
        e.data.action.type !== AllowedNavigationActions.Pop &&
        condition()
      ) {
        e.preventDefault()
      }
    })
  }, [navigation, ...list])
}
