import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome'
import React, { Component } from 'react'

export interface IFontAwesomeWrapperProps {
  icon: [IconPrefix, IconName] | IconName
  style?: FontAwesomeIconStyle
  size: number
  color?: string
  testID?: string
}

export class FontAwesomeIconWrapper extends Component<IFontAwesomeWrapperProps> {
  public render() {
    const { size, style, color, testID } = this.props

    // Majority of the icons we are using should be FontAwesome Solid
    // Only need to pass in the prefix as a prop if there is an exception to the rule
    const icon: [IconPrefix, IconName] = this.props.icon instanceof Array ? this.props.icon : ['fas', this.props.icon]

    return <FontAwesomeIcon icon={icon} size={size} style={style} color={color} testID={testID} />
  }
}
