import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: colors.selectedBlueFill,
    padding: 15,
  },
  titleText: {
    fontSize: 13,
    color: colors.primaryColor,
  },
  valueText: {
    fontSize: 17,
    color: colors.textDark,
    paddingTop: 5,
  },
})

interface IProps {
  title: string
  value: string
}

export class ViewTextInput extends Component<IProps> {
  public render() {
    return (
      <View style={styles.container}>
        <Text style={styles.titleText}>{this.props.title}</Text>
        <Text style={styles.valueText}>{this.props.value}</Text>
      </View>
    )
  }
}
