import { IconName } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'

const OUTER_CIRCLE_SIZE = 36

const styles = StyleSheet.create({
  iconCircle: {
    alignItems: 'center',
    justifyContent: 'center',
    width: OUTER_CIRCLE_SIZE,
    height: OUTER_CIRCLE_SIZE,
    borderRadius: 50,
    flexShrink: 0,
  },
})

interface IProps {
  icon: IconName
  backgroundColor: string
  color?: string
  size?: number
  style?: StyleProp<ViewStyle>
  innerIconSize?: number
}
export const IconCircle = (props: IProps) => (
  <View
    style={[
      styles.iconCircle,
      {
        backgroundColor: props.backgroundColor,
        ...(props.size ? { width: props.size, height: props.size, borderRadius: props.size / 2 } : {}),
      },
      props.style,
    ]}
  >
    <FontAwesomeIconWrapper icon={props.icon} color={props.color || colors.white} size={getIconSize(props)} />
  </View>
)

const getIconSize = (props: IProps): number => {
  if (props.innerIconSize) {
    return props.innerIconSize
  }
  return props.size ? props.size / 2 : OUTER_CIRCLE_SIZE / 2
}
