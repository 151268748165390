import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { RadioButton } from 'src/components/buttons/RadioButton'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    backgroundColor: 'transparent',
  },
  cell: {
    height: 64,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    flexBasis: 'auto',
    backgroundColor: 'white',
  },
  cellLeft: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
  },
  radioContainer: {
    paddingRight: 16,
  },
  editButton: {
    fontSize: 18,
    marginRight: 8,
    color: colors.blue50,
  },
  touchTarget: {
    padding: 16,
  },
  title: {
    fontSize: 16,
    lineHeight: 20,
    color: colors.gray90,
    paddingLeft: 16,
  },
})

interface IOwnProps {
  onPressItem: (id: string) => void
  loading?: boolean
  id: string
  withHeader?: boolean
  title: string
  testID?: string
  subTitle?: string
  value: boolean
}

export const SelectItem = (props: IOwnProps) => {
  const { id, loading, title, value, withHeader } = props
  const onPress = () => {
    props.onPressItem(id)
  }
  return (
    <View style={withHeader ? styles.container : null} accessible={true}>
      <TouchableListItemWrapper disabled={loading} style={styles.cell} onPress={onPress}>
        <View style={styles.cellLeft}>
          <RadioButton value={value} onPress={onPress} />
          <Text style={styles.title}>{title}</Text>
        </View>
      </TouchableListItemWrapper>
    </View>
  )
}
