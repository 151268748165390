import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { createShimmerPlaceholder, ShimmerPlaceholderProps } from 'react-native-shimmer-placeholder'

const ShimmerPlaceholder = createShimmerPlaceholder(LinearGradient)

// TODO EXPO WEB ONLY the animation does not seem to loop properly on web but works fine on ios
export const Shimmer = (props: ShimmerPlaceholderProps) => (
  <ShimmerPlaceholder {...props}>{props.children}</ShimmerPlaceholder>
)
