import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { PhoneConfirmation } from 'src/components/phoneConfirmation/PhoneConfirmation'
import { handleError } from 'src/helpers/ErrorHelpers'
import { LoginStyle } from 'src/screens/login/LoginStyle'

const styles = StyleSheet.create({
  cellContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  descriptionContainer: {
    alignSelf: 'flex-start',
  },
  description: {
    fontSize: 15,
    color: colors.textDark,
    paddingVertical: 20,
  },
})

interface IConfirmPhoneNumberFormProps {
  handleSubmit: (confirmationCode: string) => Promise<void>
  inputDescription: string
}

export const ConfirmPhoneNumberForm = observer(({ handleSubmit, inputDescription }: IConfirmPhoneNumberFormProps) => {
  const [loading, setLoading] = useState(false)

  const handlePressConfirm = async (confirmationCode: string) => {
    try {
      setLoading(true)
      await handleSubmit(confirmationCode)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      handleError({ error: error as Error })
    }
  }

  return (
    <View style={styles.cellContainer}>
      <View style={LoginStyle.contentContainer}>
        <View style={styles.descriptionContainer}>
          <Text style={styles.description}>{inputDescription}</Text>
        </View>
        <PhoneConfirmation handlePressConfirm={handlePressConfirm} isLoading={loading} />
      </View>
    </View>
  )
})
