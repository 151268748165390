import React from 'react'
import { FloatingLabel } from 'src/components/FloatingLabel'
import { ImageMarker } from 'src/components/mapMarkers/ImageMarker'
import { IFixedLegLabelMarkerProps } from 'src/components/mapMarkers/MapMarkerProps'
import { getAnchorForMarker, getCenterOffsetForMarker } from 'src/helpers/MapHelper'
import { IMarker } from 'src/types'

const MARKER_WIDTH = 64

export const FixedLegLabelMarker = (props: IFixedLegLabelMarkerProps) => {
  const { label, markerType, location, coordinates } = props

  const labelMarker: IMarker = {
    name: markerType,
    coordinate: location,
    child: <FloatingLabel title={label} />,
    centerOffset: getCenterOffsetForMarker(coordinates, location, MARKER_WIDTH),
    anchor: getAnchorForMarker(coordinates, location, MARKER_WIDTH),
    pointerEvents: 'none',
    style: { zIndex: 1 },
  }

  return <ImageMarker {...labelMarker} />
}
