import { CurrencyHelper } from '@sparelabs/currency'
import { FareSemantics, IFareTarget } from '@sparelabs/domain-logic'
import { st } from 'src/locales'

export class FareRenderer {
  public static renderPriceAmount = ({ fare }: Pick<IFareTarget, 'fare'>): string =>
    CurrencyHelper.format(fare.total, fare.currency)

  public static renderZeroFare = (target: IFareTarget): string =>
    FareRenderer.isFree(target) ? st.helpers.payment.free() : st.helpers.payment.noCharge()

  private static readonly isFree = ({ fare, fareRedemptions }: IFareTarget): boolean =>
    FareSemantics.isFreeBeforeDiscounts({ fare }) ||
    (!FareSemantics.requiresPayment({ fare }) && fareRedemptions.every(FareSemantics.usesPromo))
}
