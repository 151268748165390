import React, { ReactNode } from 'react'
import { Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { colors } from 'src/assets/colors'
import { OsType } from 'src/util/types'

const getStyles = () =>
  StyleSheet.create({
    modalWrapper: {
      flex: 1,
      justifyContent: 'space-between',
    },
    choicesContainer: {
      backgroundColor: 'white',
      flexGrow: 0,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 22,
      paddingHorizontal: 20,
      borderBottomColor: colors.borderColor,
      borderBottomWidth: 1,
    },
    hintRow: {
      alignItems: 'flex-end',
      paddingTop: 24,
      paddingBottom: 6,
    },
    label: {
      marginLeft: 20,
      marginRight: 20,
      fontSize: 16,
      color: colors.gray90,
    },
    androidLabel: {
      marginLeft: 28,
    },
  })

export const FieldModalWrapper = (props: { children?: ReactNode }) => (
  <SafeAreaView edges={['bottom']} style={getStyles().modalWrapper}>
    {props.children}
  </SafeAreaView>
)

export const ChoicesContainer = (props: { children?: ReactNode }) => (
  <ScrollView style={getStyles().choicesContainer}>{props.children}</ScrollView>
)

export const ChoiceRow = (props: { children?: ReactNode; onPress: () => void }) => (
  <TouchableOpacity style={getStyles().row} onPress={props.onPress}>
    {props.children}
  </TouchableOpacity>
)

export const ModalHint = (props: { hint?: string }) =>
  props.hint ? (
    <View style={[getStyles().row, getStyles().hintRow]}>
      <Text>{props.hint}</Text>
    </View>
  ) : null

export const ChoiceLabel = (props: { children?: ReactNode }) => (
  <Text style={[getStyles().label, ...(Platform.OS === OsType.Android ? [getStyles().androidLabel] : [])]}>
    {props.children}
  </Text>
)
