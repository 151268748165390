import React, { Component } from 'react'
import { Platform, StyleProp, ViewStyle } from 'react-native'
import Swiper from 'react-native-swiper'
import { SvgIcon } from 'src/components/SvgIconWrapper'
import { st } from 'src/locales'
import { OsType } from 'src/util/types'
import { PageContainer } from './PageContainer'

const NUM_ONBOARDING_PAGES = 5

interface IState {
  pageIndex: number
}

interface IProps {
  brandName: string
  showPooling: boolean
  onFinish: () => void
}

export class OnDemandOnboarding extends Component<IProps, IState> {
  private swiper: any = null
  constructor(props: IProps) {
    super(props)
    this.state = {
      pageIndex: 0,
    }
  }

  public handleIndexChanged = (index: number) => {
    this.setState({
      pageIndex: index,
    })
  }

  public handleNext = () => {
    if (this.state.pageIndex <= NUM_ONBOARDING_PAGES) {
      this.swiper.scrollBy(1, true)
    }
  }

  public render() {
    const { brandName, showPooling } = this.props
    return (
      <Swiper
        ref={(ref) => {
          this.swiper = ref
        }}
        showsButtons={Platform.OS === OsType.Web}
        loop={false}
        showsPagination={true}
        dotStyle={this.getDotStyle()}
        activeDotStyle={this.getDotStyle()}
        onIndexChanged={this.handleIndexChanged}
      >
        <PageContainer
          image={SvgIcon.Onboarding1}
          onPress={this.handleNext}
          pageTitle={st.screens.onBoarding.p1Title({ brandName })}
          description={
            showPooling ? st.screens.onBoarding.p1Description() : st.screens.onBoarding.p1DescriptionNoPooling()
          }
        />
        <PageContainer
          image={SvgIcon.Onboarding2}
          onPress={this.handleNext}
          pageTitle={st.screens.onBoarding.p2Title()}
          description={st.screens.onBoarding.p2Description()}
        />
        <PageContainer
          image={SvgIcon.Onboarding3}
          onPress={this.handleNext}
          pageTitle={st.screens.onBoarding.p3Title()}
          description={st.screens.onBoarding.p3Description()}
        />
        {showPooling && (
          <PageContainer
            image={SvgIcon.Onboarding4}
            onPress={this.handleNext}
            pageTitle={st.screens.onBoarding.p4Title()}
            description={st.screens.onBoarding.p4Description({ brandName })}
          />
        )}
        <PageContainer
          image={SvgIcon.Onboarding5}
          onPress={this.props.onFinish}
          buttonText={st.screens.onBoarding.finishButton()}
          pageTitle={st.screens.onBoarding.p5Title()}
          description={st.screens.onBoarding.p5Description({ brandName })}
        />
      </Swiper>
    )
  }

  private readonly getDotStyle = (): StyleProp<ViewStyle> => ({
    marginBottom: 80,
  })
}
