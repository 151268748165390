import { IPaymentMethodResponse, IPaymentProviderDefOutput, PaymentMethodBrand } from '@sparelabs/api-client'
import React from 'react'
import { ImageRequireSource } from 'react-native'
import { Images } from 'src/assets/Images'
import { SvgIcon, SvgIconWrapper } from 'src/components/SvgIconWrapper'
import { st } from 'src/locales'
import { ConnectedAccountType } from '../../util/types'

export enum VirtualConnectedAccountType {
  Cash = 'cash',
}

interface ICardInfo {
  displayName: string
  /**
   * Key for icon used in the "Credit Card" view
   */
  cardViewIconKey?: string
  itemImage?: ImageRequireSource
  itemComponentIcon?: JSX.Element
}

const PaymentMethodBrandMap: Record<PaymentMethodBrand | 'cash', ICardInfo> = {
  cash: {
    displayName: st.screens.selectPaymentMethod.cashTitle(),
    itemImage: Images.wallet,
  },
  [PaymentMethodBrand.Unknown]: { displayName: 'Unknown', itemImage: Images.creditCard },
  [PaymentMethodBrand.Visa]: { displayName: 'Visa', cardViewIconKey: 'visa', itemImage: Images.creditCard },
  [PaymentMethodBrand.MasterCard]: {
    displayName: 'Mastercard',
    cardViewIconKey: 'master-card',
    itemImage: Images.creditCard,
  },
  [PaymentMethodBrand.Amex]: { displayName: 'Amex', cardViewIconKey: 'american-express', itemImage: Images.creditCard },
  [PaymentMethodBrand.Discover]: { displayName: 'Discover', cardViewIconKey: 'discover', itemImage: Images.creditCard },
  [PaymentMethodBrand.Jcb]: { displayName: 'JCB', cardViewIconKey: 'jcb', itemImage: Images.creditCard },
  [PaymentMethodBrand.Diners]: {
    displayName: 'Diners Club',
    cardViewIconKey: 'diners-club',
    itemImage: Images.creditCard,
  },
  [PaymentMethodBrand.Unionpay]: { displayName: 'Diners Club', itemImage: Images.creditCard },
  [PaymentMethodBrand.Sepa]: {
    displayName: 'SEPA',
    cardViewIconKey: 'sepa',
    itemComponentIcon: <SvgIconWrapper widthFixed={50} heightFixed={30} icon={SvgIcon.SepaIcon} />,
  },
  [PaymentMethodBrand.Nimoca]: {
    displayName: st.screens.addPaymentMethod.nimoca(),
    cardViewIconKey: 'nimoca',
    itemComponentIcon: <SvgIconWrapper widthFixed={50} heightFixed={30} icon={SvgIcon.NimocaIcon} />,
  },
  [PaymentMethodBrand.GoPass]: { displayName: 'GoPass', itemImage: Images.creditCard },
}

export type PaymentMethodType = ConnectedAccountType | VirtualConnectedAccountType

export interface ICashPaymentMethodType {
  default: boolean
  connectedAccountType: VirtualConnectedAccountType.Cash
  brand: 'cash' // special brand for cash
  expiryMonth: null
  expiryYear: null
  last4Digits: ''
  id: null
}

export type PaymentMethodItem = ICashPaymentMethodType | IPaymentMethodResponse

export class PaymentMethodHelper {
  public static getShortPaymentMethodName = (paymentMethod: PaymentMethodItem): string => {
    if (paymentMethod.connectedAccountType === VirtualConnectedAccountType.Cash) {
      return st.screens.selectPaymentMethod.cashTitle()
    }
    return `${PaymentMethodBrandMap[paymentMethod.brand]?.displayName} • ${paymentMethod.last4Digits}`
  }

  public static getCardViewIconKey = (paymentMethod: PaymentMethodItem): string | null | undefined =>
    PaymentMethodBrandMap[paymentMethod.brand]?.cardViewIconKey

  public static getIconForPaymentMethod = (paymentMethod: PaymentMethodItem): ImageRequireSource | undefined =>
    PaymentMethodBrandMap[paymentMethod.brand]?.itemImage

  public static getIconComponentForPaymentMethod = (paymentMethod: PaymentMethodItem): JSX.Element | undefined =>
    PaymentMethodBrandMap[paymentMethod.brand]?.itemComponentIcon

  public static isPaymentProvider = (
    provider: IPaymentProviderDefOutput | undefined
  ): provider is IPaymentProviderDefOutput => (provider as IPaymentProviderDefOutput).connectedAccountType !== undefined

  public static removeNonNumbersFromString = (text: string): string => text.replace(/[^0-9]/g, '')

  // Returns the month as a number from a string like MM/YY
  public static extractMonthFromExpiryString = (expiry: string): number => parseInt(expiry.substring(0, 2), 10)

  // Returns the year as a number from a string like MM/YY
  public static extractYearFromExpiryString = (expiry: string): number => parseInt(expiry.substring(3, 5), 10)

  public static buildCashPaymentMethod = (isDefaultPaymentMethod: boolean): ICashPaymentMethodType => ({
    default: isDefaultPaymentMethod,
    connectedAccountType: VirtualConnectedAccountType.Cash,
    brand: 'cash',
    expiryMonth: null,
    expiryYear: null,
    last4Digits: '',
    id: null,
  })
}
