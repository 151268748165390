import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { ScreenName } from 'src/navigation'
import {
  getNonDismissibleScreenOptions,
  getScreenOptionsWithBackButton,
  getScreenOptionsWithCloseButton,
} from 'src/navigation/consts/NavigationHelper'
import { NavigatorSettings } from 'src/navigation/consts/NavigatorSettings'
import { ParamsListReview } from 'src/navigation/types/ScreenParamsListTypes'
import { ReviewScreen } from 'src/screens/review/ReviewScreen'
import { AddTip } from 'src/screens/tip/AddTipScreen'
import { CustomTipScreen } from 'src/screens/tip/CustomTipScreen'

const Stack = createStackNavigator<ParamsListReview>()

export const NavigationStackReview = () => (
  <Stack.Navigator screenOptions={{ headerStatusBarHeight: NavigatorSettings.headerStatusBarHeight }}>
    <Stack.Screen
      name={ScreenName.Review}
      component={ReviewScreen}
      options={({ navigation, route }) =>
        route.params.canRiderTip
          ? getNonDismissibleScreenOptions(route.params.title)
          : getScreenOptionsWithCloseButton(navigation, route.params.title)
      }
    />
    <Stack.Screen
      name={ScreenName.AddTip}
      component={AddTip}
      options={({ route }) => getNonDismissibleScreenOptions(route.params.title)}
    />
    <Stack.Screen
      name={ScreenName.CustomTipScreen}
      component={CustomTipScreen}
      options={({ navigation, route }) => getScreenOptionsWithBackButton(navigation, route.params.title)}
    />
  </Stack.Navigator>
)
