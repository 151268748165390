import {
  ChargeStatus,
  ConnectedAccountType,
  IChargePostBody,
  IChargeResponse,
  IPaymentMethodResponse,
  IStripeClientSafeInfo,
  PaymentFlow,
} from '@sparelabs/api-client'
import { IFailure, SuccessOrFailure } from '@sparelabs/core'
import { ErrorName } from '@sparelabs/error-types'
import { getAuthedApi } from 'src/api'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { IAxiosErrorResponse } from 'src/helpers/ErrorHelpers'
import { PaymentMethodHelper } from 'src/helpers/payments/PaymentMethodHelper'
import { IStripeConfirmIntentHelper, StripeConfirmIntentHelper } from 'src/helpers/payments/StripeConfirmIntentHelper'
import { st } from 'src/locales'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'

export interface IPaymentFailureDetails {
  type: PaymentFailureType
  message: string
}

export enum PaymentFailureType {
  PriceChange = 'PriceChange',
  PaymentMethodError = 'PaymentMethodError',
  HandleActionError = 'HandleActionError',
}

const errorMap: Record<PaymentFailureType, string> = {
  [PaymentFailureType.PriceChange]: st.payments.paymentErrorPriceChange(),
  [PaymentFailureType.PaymentMethodError]: st.payments.paymentErrorPaymentMethod(),
  [PaymentFailureType.HandleActionError]: st.payments.paymentErrorHandleAction(),
}

export type PurchaseInput = { paymentMethodId: string | null } | { chargeId: string }
type HandlePurchaseCallback<T> = (purchaseInput: PurchaseInput) => Promise<T>

/**
 * This class exists to simplify purchase logic for other parts of the code base.
 *
 * It will attempt to use the ClientHandled payment flow if possible and fall back to
 * the ServerHandled payment flow if not.
 *
 * Any payment related errors will be parsed and passed back as a failure reason,
 * and any other unrelated errors will be thrown, so caller should wrap in a try catch
 */
export class PaymentFlowHelper {
  public static async purchase<T>(
    paymentMethod: Pick<IPaymentMethodResponse, 'id' | 'supportedPaymentFlows'> | null,
    purchaseInfo: Pick<IChargePostBody, 'amount' | 'currency'>,
    handlePurchase: HandlePurchaseCallback<T>
  ): Promise<SuccessOrFailure<T, IPaymentFailureDetails>> {
    if (
      purchaseInfo.amount > 0 &&
      paymentMethod &&
      paymentMethod.supportedPaymentFlows.includes(PaymentFlow.ClientHandled)
    ) {
      return this.purchaseWithClientBasedFlow({ ...purchaseInfo, paymentMethodId: paymentMethod.id }, handlePurchase)
    }
    return this.purchaseWithServerBasedFlow(paymentMethod?.id ?? null, handlePurchase)
  }

  public static async purchaseWithServerBasedFlow<T>(
    paymentMethodId: string | null,
    handlePurchase: HandlePurchaseCallback<T>
  ): Promise<SuccessOrFailure<T, IPaymentFailureDetails>> {
    try {
      const purchase = await handlePurchase({ paymentMethodId })
      return { success: true, value: purchase }
    } catch (error) {
      return this.parseOrThrowError(error as IAxiosErrorResponse)
    }
  }

  public static async purchaseWithClientBasedFlow<T>(
    chargeInput: IChargePostBody,
    handlePurchase: HandlePurchaseCallback<T>
  ): Promise<SuccessOrFailure<T, IPaymentFailureDetails>> {
    const charge = await getAuthedApi().charges.post(chargeInput)
    try {
      await this.handleRequiresAction(charge)
    } catch (error) {
      return {
        success: false,
        reason: {
          type: PaymentFailureType.HandleActionError,
          message: errorMap[PaymentFailureType.HandleActionError],
        },
      }
    }
    try {
      const purchase = await handlePurchase({ chargeId: charge.id })
      return { success: true, value: purchase }
    } catch (error) {
      return this.parseOrThrowError(error as IAxiosErrorResponse)
    }
  }

  private static async handleRequiresAction(
    charge: Pick<IChargeResponse, 'status' | 'actionRequired' | 'paymentMethodId'>
  ) {
    if (charge.status !== ChargeStatus.RequiresAction || !charge.actionRequired) {
      return
    }
    const paymentMethod = PaymentMethodStore.paymentMethods.find((pm) => pm.id === charge.paymentMethodId)
    if (!paymentMethod) {
      throw new Error('Expected to find payment method in store')
    }
    if (paymentMethod.connectedAccountType === ConnectedAccountType.Stripe) {
      const publishableKey = this.getStripePublishableKey()
      if (!publishableKey) {
        throw new Error('Expected to find a stripe publishable key')
      }
      const confirmIntentHelper: IStripeConfirmIntentHelper = new StripeConfirmIntentHelper()
      await confirmIntentHelper.confirmIntent(publishableKey, charge.actionRequired?.clientSecret)
    } else {
      throw new Error('Handling requires action for this connected account type is not supported')
    }
  }

  public static getStripePublishableKey(): string | null {
    if (AuthenticatorHelper.organization) {
      const provider = AuthenticatorHelper.organization.paymentProviders.find(
        (p) => p.connectedAccountType === ConnectedAccountType.Stripe
      )
      if (PaymentMethodHelper.isPaymentProvider(provider)) {
        return (provider.clientSafe as IStripeClientSafeInfo).publishableApiKey
      }
    }
    return null
  }

  public static throwPaymentFailureReasonAlert(details: IPaymentFailureDetails) {
    this.throwAlert(st.payments.paymentErrorTitle(), details.message)
  }

  private static throwAlert(title: string, message: string) {
    AlertHelper.alert(
      title,
      message,
      [
        {
          text: st.common.alertOk(),
        },
      ],
      { cancelable: false }
    )
  }

  private static parseOrThrowError(error: IAxiosErrorResponse): IFailure<IPaymentFailureDetails> {
    const errorName = error.response?.data?.name
    if (errorName === ErrorName.InsufficientChargeError) {
      return {
        success: false,
        reason: {
          type: PaymentFailureType.PriceChange,
          message: errorMap[PaymentFailureType.PriceChange],
        },
      }
    } else if (errorName === ErrorName.PaymentMethodError) {
      return {
        success: false,
        reason: {
          type: PaymentFailureType.PaymentMethodError,
          message: error.message ?? errorMap[PaymentFailureType.PaymentMethodError],
        },
      }
    }
    throw error
  }
}
