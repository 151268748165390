import React from 'react'
import { SVGOverlay } from 'react-map-gl'
import { IPolygonProps } from 'src/components/mapMarkers/MapMarkerProps'
import { geoJsonPolygonToCoordList } from 'src/helpers/MapHelper'
import { IMarkerCoordinates } from 'src/types'

const getStyles = ({ strokeWidth, strokeColor, fillColor }: IPolygonProps) => ({
  strokeWidth,
  stroke: strokeColor,
  fill: fillColor,
  fillOpacity: 0.35,
})

export const Polygon = (props: IPolygonProps) => (
  <SVGOverlay
    redraw={({ project }) =>
      Array.from(props.zoneMap.entries()).map(([id, zone]) => {
        const zoneArea = geoJsonPolygonToCoordList(zone.area) || []
        return (
          <g key={`hole-${id}`} style={{ pointerEvents: 'visibleStroke' }}>
            <polygon
              style={getStyles(props)}
              points={zoneArea
                .map((coordinate: IMarkerCoordinates) => project([coordinate.longitude, coordinate.latitude]))
                .join(' ')}
            />
          </g>
        )
      })
    }
  />
)
