import AsyncStorage from '@react-native-async-storage/async-storage'
import { AsyncStorageKey } from 'src/consts/AsyncStorageKey'
import { v4 } from 'uuid'

/**
 * clientId is an identifier that is desined to stay constant throughout the app
 * installation regardless of the user that is using the application
 */
export class ClientIdStore {
  public static getId = async (): Promise<string> => {
    const clientId = await AsyncStorage.getItem(AsyncStorageKey.ClientId)
    if (clientId) {
      return clientId
    }
    const newClientId = v4()
    await AsyncStorage.setItem(AsyncStorageKey.ClientId, newClientId)
    return newClientId
  }
}
