import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'

export interface ICheckBoxProps {
  onPressed: () => void
  selected: boolean
}

const styles = StyleSheet.create({
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.selectedBlue,
    borderWidth: 2,
    borderRadius: 5,
    height: 20,
    width: 20,
  },
  boxSelected: {
    borderColor: colors.selectedBlue,
    backgroundColor: colors.selectedBlue,
  },
  boxUnselected: {
    borderColor: colors.borderColor,
  },
})

export const CheckBox = (props: ICheckBoxProps) => (
  <View style={[styles.box, props.selected ? styles.boxSelected : styles.boxUnselected]}>
    {props.selected ? <FontAwesomeIcon icon='check' size={12} color='white' /> : null}
  </View>
)
