import { FieldLabel, SingleChoiceInput } from '@sparelabs/web-ui-components'
import React from 'react'
import { FieldContainer, FieldError } from 'src/components/form/FieldShared'
import { ISingleChoiceField, ISingleChoiceFieldProps } from './SingleChoiceFieldProps'

export const SingleChoiceField: ISingleChoiceField = (props: ISingleChoiceFieldProps) => (
  <FieldContainer>
    <FieldLabel>{props.label}</FieldLabel>
    <SingleChoiceInput
      {...props}
      onChange={(value) => {
        if (value) {
          props.onChange(value)
        }
      }}
    />
    <FieldError touched={props.touched} error={props.error} />
  </FieldContainer>
)
