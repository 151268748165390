import { IFavoriteLocationResponse } from '@sparelabs/api-client'
import React from 'react'
import { AutoSuggestHelper } from 'src/helpers/AutoSuggestHelper'
import { ParamsListRoot, ScreenName } from 'src/navigation'
import { LocationStore } from 'src/stores/LocationStore'
import { RouterStore } from 'src/stores/RouterStore'
import { SearchFieldType, SearchScreenStore } from 'src/stores/SearchScreenStore'
import { Pathname } from 'src/types/homeRoot'
import { BaseCard } from '../cards/BaseCard'
import { HomeFavIconRow } from './HomeFavIconRow'
import { HomeSearchBar } from './HomeSearchBar'

interface IProps {
  handleNavigateOnboarding: (params: ParamsListRoot[ScreenName.Onboarding]) => void
  handleNavigateSetFavoriteLocation: (params: ParamsListRoot[ScreenName.SetFavoriteLocation]) => void
}

export const HomeTripPlannerCard = (props: IProps) => {
  const navigateToSearch = async () => {
    await RouterStore.goToScreen({ pathname: Pathname.Search })
  }

  const updateSearchWithFavorite = (favoriteLocation: IFavoriteLocationResponse) => {
    const favoriteAutoSuggestion = AutoSuggestHelper.mapFavoriteToAutoSuggestion(favoriteLocation)
    SearchScreenStore.clearField(SearchFieldType.Origin)
    SearchScreenStore.setCurrentSearchField(SearchFieldType.Origin)
    SearchScreenStore.setAutoSuggestion(favoriteAutoSuggestion, SearchFieldType.Destination)
  }

  const renderFavorites = () => {
    const favorites = (
      <HomeFavIconRow
        handleNavigateSetFavoriteLocation={props.handleNavigateSetFavoriteLocation}
        currentLocation={LocationStore.getCurrentLocationWithPermission()}
        navigateToSearch={navigateToSearch}
        isLocationPermissionGranted={LocationStore.isGranted()}
        updateSearchWithFavorite={(favoriteLocation) => updateSearchWithFavorite(favoriteLocation)}
      />
    )
    return favorites
  }

  return (
    <BaseCard>
      <HomeSearchBar
        currentLocation={LocationStore.getCurrentLocationWithPermission()}
        handleNavigateOnboarding={props.handleNavigateOnboarding}
        navigateToSearch={navigateToSearch}
      />
      {renderFavorites()}
    </BaseCard>
  )
}
