import bbox from '@turf/bbox'
import { multiPoint } from '@turf/helpers'
import { FlyToInterpolator, WebMercatorViewport } from 'react-map-gl'
import { TRANSITION_EVENTS } from 'react-map-gl/src/components/interactive-map'
import { IMapProps } from 'src/components/map/MapTypes'
import { getLatitudeLongitudeDelta } from 'src/helpers/MapHelper'
import { IMapState } from 'src/types'

const DEFAULT_ZOOM = 16

export interface IInteractionState {
  inTransition: boolean
  isDragging: boolean
  isPanning: boolean
  isRotating: boolean
  isZooming: boolean
}

export interface IViewport {
  latitude: number
  longitude: number
  zoom: number
  bearing: number
  pitch: number
  width: number
  height: number
  transitionInterpolator: FlyToInterpolator | null
  transitionInterruption?: TRANSITION_EVENTS
  transitionDuration?: number
  altitude?: number
  maxZoom?: number
  minZoom?: number
  maxPitch?: number
  minPitch?: number
  transitionEasing?: (t: number) => number
}

export const isInteractionFinished = (transitionState: IInteractionState): boolean =>
  !transitionState.isPanning &&
  !transitionState.isDragging &&
  !transitionState.isZooming &&
  !transitionState.isRotating &&
  !transitionState.inTransition

export const onInteractionStateChange = (
  newTransitionState: IInteractionState,
  props: IMapProps,
  viewport: IViewport
): void => {
  if (isInteractionFinished(newTransitionState)) {
    props.onRegionChangeComplete({
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      ...getLatitudeLongitudeDelta(),
    })
  } else if (newTransitionState.isDragging) {
    if (props.onPanDrag) {
      props.onPanDrag()
    }
  }
}

export const getPadding = (shouldShowLandscapeWeb, screenHeight: number, screenWidth: number) => ({
  top: screenHeight * 0.1,
  // Extra padding added depending on landscape vs. portrait mode
  bottom: shouldShowLandscapeWeb ? screenHeight * 0.1 : screenHeight * 0.4,
  left: shouldShowLandscapeWeb ? screenWidth * 0.4 : screenWidth * 0.1,
  right: screenWidth * 0.1,
})

export interface IRegionChangeCoordinates {
  latitude: number
  longitude: number
  zoom: number
}

export const getNewViewportValues = (
  mapState: IMapState,
  oldViewport: IViewport,
  shouldShowLandscapeWeb: boolean,
  screenHeight: number,
  screenWidth: number
): IRegionChangeCoordinates => {
  const { coordinates } = mapState

  if (coordinates.length === 1) {
    return {
      latitude: coordinates[0].latitude,
      longitude: coordinates[0].longitude,
      zoom: DEFAULT_ZOOM,
    }
  }

  const boundingBoxCoordinates = coordinates.map((coordinate) => [coordinate.longitude, coordinate.latitude])
  const [minLong, minLat, maxLong, maxLat] = bbox(multiPoint(boundingBoxCoordinates))
  const vp = new WebMercatorViewport(oldViewport)
  return vp.fitBounds(
    [
      [minLong, minLat],
      [maxLong, maxLat],
    ],
    {
      padding: getPadding(shouldShowLandscapeWeb, screenHeight, screenWidth),
    }
  )
}
