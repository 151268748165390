import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { ButtonWrapper } from 'src/components/buttons/ButtonWrapper'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { CheckBox } from 'src/components/checkBox/CheckBox'
import { ChoiceLabel, ChoiceRow, ChoicesContainer, FieldModalWrapper, ModalHint } from 'src/components/form/ModalShared'
import { st } from 'src/locales'
import { ParamsListRoot, ScreenName, ScreenPropsRoot } from 'src/navigation'

const getStyles = () =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.blue10,
    },
  })

type Props = ParamsListRoot[ScreenName.MultipleChoiceModal] & {
  handleDismiss: () => void
}

interface IState {
  selected: string[]
}

export class MultipleChoiceModalView extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)
    this.state = { selected: props.selected }
  }

  public render() {
    const styles = getStyles()
    const { choices, hint, onSubmit } = this.props
    return (
      <FieldModalWrapper>
        <View style={styles.container}>
          <ModalHint hint={hint} />
          <ChoicesContainer>
            {choices.map((choice) => (
              <ChoiceRow key={choice.value} onPress={() => this.onPressChoice(choice.value)}>
                <CheckBox
                  selected={this.state.selected.includes(choice.value)}
                  onPressed={() => this.onPressChoice(choice.value)}
                />
                <ChoiceLabel>{choice.label}</ChoiceLabel>
              </ChoiceRow>
            ))}
          </ChoicesContainer>
        </View>
        <ButtonWrapper>
          <PrimaryButton
            title={st.common.done()}
            onPress={async () => {
              onSubmit(this.state.selected)
              this.props.handleDismiss()
            }}
          />
        </ButtonWrapper>
      </FieldModalWrapper>
    )
  }

  private onPressChoice(pressedValue: string) {
    if (this.state.selected.includes(pressedValue)) {
      this.setState({ selected: this.state.selected.filter((value) => value !== pressedValue) })
    } else {
      this.setState({ selected: [...this.state.selected, pressedValue] })
    }
  }
}

export const MultipleChoiceModal = (props: ScreenPropsRoot<ScreenName.MultipleChoiceModal>) => (
  <MultipleChoiceModalView {...props.route.params} handleDismiss={() => props.navigation.goBack()} />
)
