import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons'
import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 14,
  },
  iconStyle: {
    margin: 0,
  },
  divider: {
    width: 16,
  },
  text: {
    flex: 1,
    fontSize: 16,
    flexWrap: 'wrap',
  },
})

interface IProps {
  icon: [IconPrefix, IconName]
  color: string
  backgroundColor: string
  text: string
}

export class GenericStatusBar extends Component<IProps> {
  public render() {
    const { icon, color, text, backgroundColor } = this.props
    return (
      <View style={[styles.container, { backgroundColor }]}>
        <FontAwesomeIconWrapper style={styles.iconStyle} icon={icon} color={color} size={16} />
        <View style={styles.divider} />
        <Text style={[styles.text, { color }]}>{text}</Text>
      </View>
    )
  }
}
