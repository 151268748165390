// eslint-disable-next-line no-restricted-imports
import momentOriginal from 'moment-timezone'
// @ts-ignore
import momentWithLocales from 'moment/min/moment-with-locales'

// eslint-disable-next-line no-restricted-imports
export { Moment } from 'moment-timezone'

/**
 * moment library with locales
 *
 * This helper exists to solve 2 problems:
 *  - its easy to import moment without locales and cause runtime errors
 *  - importing moment with locales does not give us the TS types
 *
 */
export const moment: typeof momentOriginal = momentWithLocales
