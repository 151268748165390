import React, { Component } from 'react'
import { SecondaryButton } from 'src/components/buttons/SecondaryButton'
import { st } from 'src/locales'
import { LoadingStore } from 'src/stores/LoadingStore'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'

interface IProps {
  handleAfterDelete: () => void
  paymentMethodId: string
}

export class PaymentDeleteButton extends Component<IProps> {
  private readonly loadingStore = new LoadingStore()
  public handleMakeDefault = async () => {
    await this.loadingStore.execute(PaymentMethodStore.deletePaymentMethod(this.props.paymentMethodId))
    this.props.handleAfterDelete()
  }

  public render() {
    return (
      <SecondaryButton
        loading={this.loadingStore.isLoading()}
        title={st.screens.viewPaymentMethod.remove()}
        onPress={this.handleMakeDefault}
      />
    )
  }
}
