import React from 'react'
import { IDimensions, validateDimensions } from 'src/helpers/SvgIconWrapperHelper'
import CurrentAddressPin from '../../assets/img/current-address.svg'
import CurrentLocationPin from '../../assets/img/current-location.svg'
import DropoffPin from '../../assets/img/dropoffPin.svg'
import EndPin from '../../assets/img/endLocationIcon.svg'
import FleetAgreementIcon from '../../assets/img/fleetAgreementIcon.svg'
import NimocaCardBack from '../../assets/img/nimoca-card-back.svg'
import NimocaCardFront from '../../assets/img/nimoca-card-front.svg'
import NimocaMascot from '../../assets/img/nimoca-mascot.svg'
import NimocaIcon from '../../assets/img/nimocaIcon.svg'
import Onboarding1 from '../../assets/img/onboarding1.svg'
import Onboarding2 from '../../assets/img/onboarding2.svg'
import Onboarding3 from '../../assets/img/onboarding3.svg'
import Onboarding4 from '../../assets/img/onboarding4.svg'
import Onboarding5 from '../../assets/img/onboarding5.svg'
import PickupPin from '../../assets/img/pickupPin.svg'
import GoogleLabel from '../../assets/img/powered_by_google_on_white.svg'
import SepaIcon from '../../assets/img/sepaDebit.svg'
import SetLocationPin from '../../assets/img/setLocationPin.svg'
import Smile from '../../assets/img/smile.svg'
import StartPin from '../../assets/img/startLocation.svg'
import StopPin from '../../assets/img/stop-pin.svg'
import Vehicle from '../../assets/img/vehicle.svg'

export enum SvgIcon {
  PickupPin,
  DropoffPin,
  StartPin,
  EndPin,
  SetLocationPin,
  Vehicle,
  NimocaCardBack,
  NimocaCardFront,
  NimocaMascot,
  NimocaIcon,
  SepaIcon,
  CurrentLocationPin,
  CurrentAddressPin,
  StopPin,
  GoogleLabel,
  Onboarding1,
  Onboarding2,
  Onboarding3,
  Onboarding4,
  Onboarding5,
  Smile,
  FleetAgreementIcon,
}

export interface ISvgIconProps {
  icon: SvgIcon
  heightFixed?: number
  widthFixed?: number
  heightPercentage?: number
  widthPercentage?: number
}

export const SvgIconWrapper = (props: ISvgIconProps) => {
  const { heightFixed, widthFixed, heightPercentage, widthPercentage } = props
  const { width, height }: IDimensions = validateDimensions(heightFixed, widthFixed, heightPercentage, widthPercentage)

  switch (props.icon) {
    case SvgIcon.PickupPin:
      return <PickupPin width={width} height={height} />
    case SvgIcon.DropoffPin:
      return <DropoffPin width={width} height={height} />
    case SvgIcon.StartPin:
      return <StartPin width={width} height={height} />
    case SvgIcon.EndPin:
      return <EndPin width={width} height={height} />
    case SvgIcon.SetLocationPin:
      return <SetLocationPin width={width} height={height} />
    case SvgIcon.Vehicle:
      return <Vehicle width={width} height={height} />
    case SvgIcon.NimocaCardFront:
      return <NimocaCardFront width={width} height={height} />
    case SvgIcon.NimocaCardBack:
      return <NimocaCardBack width={width} height={height} />
    case SvgIcon.NimocaIcon:
      return <NimocaIcon width={width} height={height} />
    case SvgIcon.NimocaMascot:
      return <NimocaMascot width={width} height={height} />
    case SvgIcon.SepaIcon:
      return <SepaIcon width={width} height={height} />
    case SvgIcon.Onboarding1:
      return <Onboarding1 width={width} height={height} />
    case SvgIcon.Onboarding2:
      return <Onboarding2 width={width} height={height} />
    case SvgIcon.Onboarding3:
      return <Onboarding3 width={width} height={height} />
    case SvgIcon.Onboarding4:
      return <Onboarding4 width={width} height={height} />
    case SvgIcon.Onboarding5:
      return <Onboarding5 width={width} height={height} />
    case SvgIcon.CurrentLocationPin:
      return <CurrentLocationPin width={width} height={height} />
    case SvgIcon.StopPin:
      return <StopPin width={width} height={height} />
    case SvgIcon.GoogleLabel:
      return <GoogleLabel width={width} height={height} />
    case SvgIcon.Smile:
      return <Smile width={width} height={height} />
    case SvgIcon.CurrentAddressPin:
      return <CurrentAddressPin width={width} height={height} />
    case SvgIcon.FleetAgreementIcon:
      return <FleetAgreementIcon width={width} height={height} />
  }

  return null
}
