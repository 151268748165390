import {
  IAbstractLeg,
  IFixedRouteLeg,
  IJourney,
  IJourneyEstimateQueryParams,
  IWalkingLeg,
  LegMode,
} from '@sparelabs/api-client'
import { ArcBuilder, GeometryType, ILineString, IPoint, PolylineUtils } from '@sparelabs/geography'
import { last } from 'lodash'
import { colors } from 'src/assets/colors'
import { isWalkingOnlyJourney } from 'src/components/journey/JourneyHelper'
import { IWalkingPolylineInfo } from 'src/components/mapMarkers/WalkingPolylineInfoBuilder'
import { geoJsonToCoords, lineStringToCoords } from 'src/helpers/MapHelper'
import { IMarkerCoordinates } from 'src/types'
import { ISimpleJourneyRoute } from 'src/types/journey'

export const buildSimpleJourneyRoute = (
  selectedJourney: IJourney,
  journeyInput: IJourneyEstimateQueryParams
): ISimpleJourneyRoute | null => {
  if (selectedJourney && journeyInput) {
    const { pickupWalkingDuration, pickupWalkingPolyline } = getWalkingStartInfo(selectedJourney)
    const { dropoffWalkingDuration, dropoffWalkingPolyline } = getWalkingEndInfo(selectedJourney)
    const isWalkingOnly: boolean = isWalkingOnlyJourney(selectedJourney)
    return {
      requestedPickupLocation: {
        type: GeometryType.Point,
        coordinates: [journeyInput.startLongitude, journeyInput.startLatitude],
      },
      requestedDropoffLocation: {
        type: GeometryType.Point,
        coordinates: [journeyInput.endLongitude, journeyInput.endLatitude],
      },
      scheduledPickupLocation: isWalkingOnly ? null : getJourneyStartLocation(selectedJourney),
      scheduledDropoffLocation: isWalkingOnly ? null : getJourneyEndLocation(selectedJourney),
      pickupWalkingDuration,
      dropoffWalkingDuration,
      pickupWalkingPolyline,
      dropoffWalkingPolyline,
      isWalkingOnlyJourney: isWalkingOnly,
    }
  }
  return null
}

export const getJourneyStartLocation = (journey: IJourney): IPoint => {
  let firstLeg = journey.legs[0]

  // We want the location of the first transit or on-demand leg for the
  // scheduledPickupLocation field because walking legs do not include a start location
  if (firstLeg.mode === LegMode.Walk) {
    if (journey.legs.length > 1) {
      firstLeg = journey.legs[1]
      if (firstLeg.mode === LegMode.OnDemand) {
        return firstLeg.start.location
      } else if (firstLeg.mode !== LegMode.Walk) {
        return firstLeg.stops[0].location
      }
    }

    throw new Error('Error getting start location for journey')
  }

  if (firstLeg.mode === LegMode.OnDemand) {
    return firstLeg.start.location
  }

  return firstLeg.stops[0].location
}

export const getJourneyEndLocation = (journey: IJourney): IPoint => {
  let lastLeg = last(journey.legs)

  if (!lastLeg) {
    throw new Error('Error getting end location for journey')
  }

  // We want the location of the last transit or on-demand leg for the
  // scheduledDropoffLocation field because walking legs do not include an end location
  if (lastLeg.mode === LegMode.Walk) {
    if (journey.legs.length > 1) {
      lastLeg = journey.legs[journey.legs.length - 2]
      if (lastLeg.mode === LegMode.OnDemand) {
        return lastLeg.end.location
      } else if (lastLeg.mode !== LegMode.Walk) {
        return lastLeg.stops[lastLeg.stops.length - 1].location
      }
    }
    throw new Error('Error getting end location for journey')
  }

  if (lastLeg.mode === LegMode.OnDemand) {
    return lastLeg.end.location
  }

  const lastStop = last(lastLeg.stops)
  if (lastStop) {
    return lastStop.location
  }

  throw new Error('Error getting end location for journey')
}

export const getWalkingStartInfo = (
  journey: IJourney
): { pickupWalkingDuration: number; pickupWalkingPolyline: ILineString | null } => {
  const firstLeg = journey.legs[0]
  if (firstLeg.mode !== LegMode.Walk) {
    return { pickupWalkingDuration: 0, pickupWalkingPolyline: null }
  }

  const pickupWalkingDuration = firstLeg.endTime.ts - firstLeg.startTime.ts
  const pickupWalkingPolyline: ILineString = PolylineUtils.decodePolyline(firstLeg.polyline)
  return { pickupWalkingDuration, pickupWalkingPolyline }
}

export const getWalkingEndInfo = (
  journey: IJourney
): { dropoffWalkingDuration: number; dropoffWalkingPolyline: ILineString | null } => {
  const lastLeg = last(journey.legs)
  if (!lastLeg || lastLeg.mode !== LegMode.Walk) {
    return { dropoffWalkingDuration: 0, dropoffWalkingPolyline: null }
  }

  const dropoffWalkingDuration = lastLeg.endTime.ts - lastLeg.startTime.ts
  const dropoffWalkingPolyline: ILineString = PolylineUtils.decodePolyline(lastLeg.polyline)
  return { dropoffWalkingDuration, dropoffWalkingPolyline }
}

export const getRouteColour = (routeColour: string | undefined): string =>
  routeColour ? '#' + routeColour : colors.blueAlt50

export const getRouteTextColour = (routeTextColour: string | undefined): string =>
  routeTextColour ? '#' + routeTextColour : colors.white

export const getTransitPolylineCoordinates = (transitLeg: IFixedRouteLeg): IMarkerCoordinates[] => {
  if (transitLeg.polyline) {
    const transitPolyline: IMarkerCoordinates[] = getPolyline(transitLeg)

    // the polyline doesn't fully connect the maker and the polyline
    const firstStop = transitLeg.stops[0]
    const lastStop = last(transitLeg.stops)

    if (firstStop && lastStop) {
      transitPolyline.unshift(geoJsonToCoords(firstStop.location))
      transitPolyline.push(geoJsonToCoords(lastStop.location))
    }

    return transitPolyline
  }
  // if no polyline was given, create the default between the stops location
  const boardStop = transitLeg.stops[0]
  const exitStop = last(transitLeg.stops)

  if (boardStop && exitStop) {
    return createDefaultPolylineArc(boardStop.location, exitStop.location)
  }

  return []
}

export const getPolyline = (journeyLeg: IAbstractLeg): IMarkerCoordinates[] => {
  const polyline = PolylineUtils.decodePolyline(journeyLeg.polyline)
  return lineStringToCoords(polyline)
}

export const createDefaultPolylineArc = (boardLocation: IPoint, exitLocation: IPoint): IMarkerCoordinates[] =>
  lineStringToCoords(ArcBuilder.buildArc(boardLocation, exitLocation))

export const mapWalkingLegToWalkingInfo = (walkingLeg: IWalkingLeg): IWalkingPolylineInfo => {
  const walkingPolyline: IMarkerCoordinates[] = getPolyline(walkingLeg)
  const walkingDuration = walkingLeg.endTime.ts - walkingLeg.startTime.ts

  const startLocation = walkingPolyline[0]
  const endLocation = last(walkingPolyline)

  if (!startLocation || !endLocation) {
    throw new Error('walking polyline was empty')
  }

  return {
    startLocation: {
      type: GeometryType.Point,
      coordinates: [startLocation.longitude, startLocation.latitude],
    },
    endLocation: {
      type: GeometryType.Point,
      coordinates: [endLocation.longitude, endLocation.latitude],
    },
    walkingPolyline,
    walkingDuration,
    // estimates from onDemandLegs have no radius associated with them
    hasRadius: false,
  }
}
