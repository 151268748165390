import React, { PropsWithChildren } from 'react'
import { Marker as WebMarker } from 'react-map-gl'
import { geoJsonToCoords } from 'src/helpers/MapHelper'
import { IMarker } from 'src/types'

export const Marker = (props: PropsWithChildren<IMarker>): JSX.Element => {
  const { coordinate, webCenterOffset, ...restProps } = props
  const { latitude, longitude } = geoJsonToCoords(coordinate)

  return (
    <WebMarker
      {...restProps}
      latitude={latitude}
      longitude={longitude}
      offsetLeft={webCenterOffset?.offsetLeft}
      offsetTop={webCenterOffset?.offsetTop}
    >
      {props.children}
    </WebMarker>
  )
}
