import AsyncStorage from '@react-native-async-storage/async-storage'
import { create, IHydrateResult } from 'mobx-persist'
import { HostStore } from 'src/api/HostStore'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { AnnouncementStore } from 'src/stores/AnnouncementStore'
import { FarePassStore } from 'src/stores/FarePassStore'
import { OnboardingStore } from 'src/stores/OnboardingStore'
import { RequestStore } from 'src/stores/RequestStore'
import { ServiceStore } from 'src/stores/ServiceStore'
import { FavoriteLocationStore } from './FavoriteLocationStore'
import { LocationStore } from './LocationStore'
import { PaymentMethodStore } from './PaymentMethodStore'
import { PromoStore } from './PromoStore'

interface IPersistedStore {
  name: string
}

const hydrateStoreConnection = create({ storage: AsyncStorage })
const hydrate = <T extends IPersistedStore>(store: T): IHydrateResult<T> => hydrateStoreConnection(store.name, store)

export class MobxStoreManager {
  public static hydrateMobxStores = async () => {
    await hydrate(OnboardingStore)
    await hydrate(HostStore)
    await hydrate(AuthenticatorHelper)
    await hydrate(FavoriteLocationStore)
    await hydrate(PromoStore)
    await hydrate(PaymentMethodStore)
    await hydrate(ServiceStore)
    await hydrate(LocationStore)
    await hydrate(FarePassStore)
    await hydrate(AnnouncementStore)
  }

  public static clearMobxStores = () => {
    AuthenticatorHelper.clear()
    FavoriteLocationStore.clear()
    PromoStore.clear()
    PaymentMethodStore.clear()
    ServiceStore.clear()
    LocationStore.clear()
    FarePassStore.clear()
    RequestStore.clear()
  }
}
