import React, { Component } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { st } from 'src/locales'
import { BaseCard } from '../cards/BaseCard'
import { LoadingShimmer } from '../LoadingShimmer'

const styles = StyleSheet.create({
  loadingImage: {
    width: 80,
    height: 80,
  },
  loadingText: {
    paddingTop: 20,
    fontSize: 15,
    color: colors.darkGrayBlue,
    fontWeight: '700',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 28,
    paddingBottom: 35,
  },
})

export class RideOptionsLoadingCard extends Component {
  public render() {
    return (
      <BaseCard>
        <LoadingShimmer />
        <View style={styles.loadingContainer}>
          <Image resizeMode='contain' source={Images.searchingRides} style={styles.loadingImage} />
          <Text style={styles.loadingText}>{st.components.rideOptionsCard.loading()}</Text>
        </View>
      </BaseCard>
    )
  }
}
