import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons'
import React, { Component, ReactNode } from 'react'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    marginBottom: 10,
    width: '100%',
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    overflow: 'hidden',
  },
  titleRightBorder: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  subtitleContainer: {
    overflow: 'hidden',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  pill: {
    shadowColor: colors.darkShadowColor,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3,
    shadowOpacity: 1,
  },
  message: {
    padding: 8,
    fontSize: 18,
  },
  icon: {
    marginLeft: 8,
  },
})

export class PillArea extends Component<{ children?: ReactNode }> {
  public render() {
    return <SafeAreaView style={styles.container}>{this.props.children}</SafeAreaView>
  }
}

interface IPillProps {
  backgroundColor: string
  textColor: string
  icon?: [IconPrefix, IconName]
  title: string
  subtitle?: string
  subtitleBackgroundColor?: string
  subtitleTextColor?: string
}

export class Pill extends Component<IPillProps> {
  public render() {
    return (
      <View style={styles.pill}>
        <View
          style={[
            styles.titleContainer,
            !this.props.subtitle ? styles.titleRightBorder : {},
            { backgroundColor: this.props.backgroundColor },
          ]}
        >
          {this.props.icon && (
            <FontAwesomeIconWrapper icon={this.props.icon} color={this.props.textColor} style={styles.icon} size={18} />
          )}
          <Text style={[styles.message, { color: this.props.textColor }]}>{this.props.title}</Text>
        </View>
        {this.props.subtitle && (
          <View style={styles.subtitleContainer}>
            <Text
              style={[
                styles.message,
                { backgroundColor: this.props.subtitleBackgroundColor, color: this.props.subtitleTextColor },
              ]}
            >
              {this.props.subtitle}
            </Text>
          </View>
        )}
      </View>
    )
  }
}
