import { toJS } from 'mobx'
import { handleError } from 'src/helpers/ErrorHelpers'
import { PaymentFlowHelper } from 'src/helpers/payments/PaymentFlowHelper'
import { RequestPostBodyBuilder } from 'src/helpers/RequestPostBodyBuilder'
import { ParamsListScheduledTripList, ScreenName } from 'src/navigation'
import { IEstimateInputStore } from 'src/stores/EstimateInputStore'
import { IEstimateStore } from 'src/stores/EstimateStore'
import { PaymentMethodStore } from 'src/stores/PaymentMethodStore'
import { RequestStore } from 'src/stores/RequestStore'
import { RouterStore } from 'src/stores/RouterStore'
import { Pathname } from 'src/types/homeRoot'

export const onPressRequestCreation = async (
  selectedRideOptionId: string | null,
  estimateStore: IEstimateStore,
  estimateInputStore: IEstimateInputStore,
  handleNavigateScheduleTrip: (params: ParamsListScheduledTripList[ScreenName.ScheduledTrip]) => void
): Promise<void> => {
  const selectedEstimate = estimateStore.getSelectedEstimate(selectedRideOptionId)
  const estimateInput = estimateInputStore.estimateInput
  if (!estimateStore.estimateServices || !estimateInput || !selectedEstimate) {
    return
  }

  // obtain payment method information
  const paymentMethod = estimateInput.paymentMethodId
    ? PaymentMethodStore.getPaymentMethodById(estimateInput.paymentMethodId)
    : null

  // craft the post body request for creating a new request
  const estimateMetadataInput = estimateInputStore.estimateMetadataInput ?? {}
  const postBody = RequestPostBodyBuilder.build(estimateInput, selectedEstimate, estimateMetadataInput)

  try {
    // attempt creating the request through the purchase helper, which will always choose the
    // relevant payment flow
    const result = await PaymentFlowHelper.purchase(
      paymentMethod ?? null,
      {
        amount: selectedEstimate.fare.total,
        currency: selectedEstimate.fare.currency,
      },
      async (purchaseInput) => RequestStore.createRequest({ ...postBody, ...purchaseInput })
    )
    if (!result.success) {
      // notify the customer about any payment related errors
      PaymentFlowHelper.throwPaymentFailureReasonAlert(result.reason)
      return
    }
    // navigate to the page to view the request details
    const request = result.value
    if (request && RequestStore.activeRequest && RequestStore.activeRequest.id === request.id) {
      const selectedEstimate = estimateStore.getSelectedEstimate(selectedRideOptionId)
      await RouterStore.goToScreen({
        pathname: Pathname.RequestActive,
        state: { selectedEstimateId: selectedEstimate?.id },
      })
    } else if (request) {
      await RouterStore.returnHome()
      handleNavigateScheduleTrip({
        request: toJS(request),
        showConfirmation: true,
      })
    }
    estimateInputStore.clearMetadata()
  } catch (error) {
    /**
     * We want to throw a UI alert to notify the rider about what went wrong here,
     * because it may be things related to having a duplicate trip or an invalid payment method
     */
    handleError({ error: error as Error })
  }
}
