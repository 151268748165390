import React, { Component } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { colors } from 'src/assets/colors'
import { st } from 'src/locales'
import { AutoSuggestTextInput } from './AutoSuggestTextInput'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    height: 20,
    width: 18,
    marginRight: 8,
  },
  inputBase: {
    backgroundColor: colors.white,
    borderBottomColor: colors.borderGray,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
})

interface IProps {
  onChangeText: (text) => void
  selected: boolean
  onFocus: (ref: any) => void
  autoFocus?: boolean
  isDropoff?: boolean
  value: string
  inputStyle?: StyleProp<ViewStyle>
}

interface IState {
  text: string
}

export class AutoSuggestInput extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      text: props.value,
    }
  }

  public handleChangeText = (text) => {
    this.setState({ text })
    if (this.props.onChangeText) {
      this.props.onChangeText(text)
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ text: nextProps.value })
  }

  public render() {
    const subtitle = this.props.isDropoff ? st.screens.search.whereTo() : st.screens.search.whereFrom()
    const searchTestId = this.props.isDropoff ? 'searchDestination' : 'searchStart'
    return (
      <View style={styles.container}>
        <View style={styles.inputBase}>
          <AutoSuggestTextInput
            testID={searchTestId}
            selected={this.props.selected}
            placeholder={subtitle}
            onChangeText={this.handleChangeText}
            value={this.state.text}
            onFocus={this.props.onFocus}
            autoFocus={this.props.autoFocus}
            inputStyle={this.props.inputStyle}
          />
        </View>
      </View>
    )
  }
}
