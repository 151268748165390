import React from 'react'
import { Image, Platform } from 'react-native'
import { Marker } from 'src/components/mapMarkers/marker/Marker'
import { IMarker } from 'src/types'
import { OsType } from '../../util/types'

export const ImageMarker = (props: IMarker): JSX.Element => (
  <Marker {...props} image={props.image && Platform.OS === OsType.Android ? props.image : undefined}>
    {props.child ?? null}
    {props.image && (Platform.OS === OsType.iOS || Platform.OS === OsType.Web) ? (
      <Image source={props.image} style={props.imageStyle} />
    ) : null}
  </Marker>
)
