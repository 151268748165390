import { useEffect, useState } from 'react'
import { AppVersionHelper } from 'src/helpers/AppVersionHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'

interface IHookData {
  isLoading: boolean
  isConnected: boolean
  updateAppAndLogout: () => Promise<void>
}

export const usePreLoginChecks = (): IHookData => {
  const [isLoading, setIsLoading] = useState(true)
  const [isConnected, setIsConnected] = useState(false)

  const updateAppAndLogout = async (): Promise<void> => {
    try {
      if (AuthenticatorHelper.userOrgToken) {
        await AuthenticatorHelper.logout()
      }
      if (!AuthenticatorHelper.globalApp) {
        await AuthenticatorHelper.fetchGlobalApp()
      }
      await AppVersionHelper.checkIsAppOutdated()
      setIsLoading(false)
      setIsConnected(true)
    } catch (error) {
      setIsLoading(false)
      handleError({ error: error as IErrorWithResponse })
    }
  }

  useEffect(() => {
    void updateAppAndLogout()
  }, [])

  return { isLoading, isConnected, updateAppAndLogout }
}
