import { IFarePassStub } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { StyleSheet, Text } from 'react-native'
import { colors } from 'src/assets/colors'
import { BaseCard } from 'src/components/cards/BaseCard'

interface IProps {
  farePass: IFarePassStub | null
}

const styles = StyleSheet.create({
  card: {
    padding: 24,
    height: 200,
    borderRadius: 16,
    marginHorizontal: 0,
    marginVertical: 4,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.textLight,
    lineHeight: 28,
  },
  description: {
    lineHeight: 24,
    marginTop: 8,
    fontSize: 14,
    color: colors.textLight,
  },
})

export class FarePassCard extends Component<IProps> {
  public render() {
    if (!this.props.farePass) {
      return null
    }
    const { name, description, color } = this.props.farePass

    return (
      <BaseCard containerStyle={[styles.card, { backgroundColor: color }]}>
        <Text style={styles.title}>{name}</Text>
        <Text style={styles.description}>{description}</Text>
      </BaseCard>
    )
  }
}
