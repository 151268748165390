import React, { Component } from 'react'
import { Image, StyleSheet, TouchableOpacity } from 'react-native'
import { Images } from 'src/assets/Images'

const styles = StyleSheet.create({
  touchable: {
    paddingVertical: 12,
    paddingLeft: 17,
    width: 55,
  },
  image: {
    height: 18,
    width: 18,
  },
})

interface IProps {
  onPress: () => void
}

export class CloseButton extends Component<IProps> {
  public render() {
    return (
      <TouchableOpacity style={styles.touchable} onPress={this.props.onPress}>
        <Image style={styles.image} source={Images.close} />
      </TouchableOpacity>
    )
  }
}
