import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons'
import React, { Component } from 'react'
import { Image, ImageSourcePropType, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'
import { colors } from 'src/assets/colors'
import { FontAwesomeIconWrapper } from 'src/components/FontAwesomeIcon'
import { BaseCard } from './BaseCard'

const styles = StyleSheet.create({
  imageContainer: {
    width: 50,
    marginTop: 20,
    height: 60,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    textAlign: 'center',
    padding: 30,
    paddingTop: 10,
    fontSize: 15,
    color: colors.textDark,
  },
})

export interface IIconType {
  file: [IconPrefix, IconName]
  size: number
  color: string
}

interface IProps {
  image?: ImageSourcePropType
  message: string
  overrideCardStyles?: StyleProp<ViewStyle>
  icon?: IIconType
}

export class GenericCard extends Component<IProps> {
  public render() {
    return (
      <BaseCard containerStyle={this.props.overrideCardStyles}>
        <View style={styles.container}>
          {this.props.image && !this.props.icon && (
            <Image resizeMode='contain' style={styles.imageContainer} source={this.props.image} />
          )}
          {this.props.icon && !this.props.image && (
            <FontAwesomeIconWrapper
              icon={this.props.icon.file}
              size={this.props.icon.size}
              color={this.props.icon.color}
              style={styles.imageContainer}
            />
          )}
          <Text style={styles.description}>{this.props.message}</Text>
        </View>
      </BaseCard>
    )
  }
}
