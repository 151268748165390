import { buildPoint } from '@sparelabs/geography'
import along from '@turf/along'
import { lineString } from '@turf/helpers'
import length from '@turf/length'
import deepEqual from 'deep-equal'
import { range } from 'lodash'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Marker as WebMarker } from 'react-map-gl'
import { geoJsonToCoords } from 'src/helpers/MapHelper'
import { IMarker, IMarkerCoordinates } from 'src/types/mapTypes'

const ANIMATION_DURATION_MS = 500

export const ImageMarkerAnimated = (props: PropsWithChildren<IMarker>): JSX.Element => {
  const initialLocation: IMarkerCoordinates = geoJsonToCoords(props.coordinate)
  const [currentLocation, setCurrentLocation] = useState<IMarkerCoordinates>({
    latitude: initialLocation.latitude,
    longitude: initialLocation.longitude,
  })
  const { coordinate, webCenterOffset, ...restProps } = props

  const previousProps = useRef(props)

  useEffect(() => {
    if (!deepEqual(previousProps.current.coordinate, props.coordinate)) {
      const previousLocation = previousProps.current.coordinate
      const newLocation = props.coordinate
      previousProps.current = props
      let startTime = 0

      /**
       * Create a polyline between our old location and new location
       * Set different distance intervals to know where to render the marker along the polyline
       */
      const line = lineString([previousLocation.coordinates, newLocation.coordinates])
      const distance = length(line)

      const distanceIntervals: number[] = range(0, distance, distance / ANIMATION_DURATION_MS)

      const arc: IMarkerCoordinates[] = distanceIntervals.map((interval: number) => {
        const segment = along(line, interval)
        return geoJsonToCoords(buildPoint(segment.geometry.coordinates[0], segment.geometry.coordinates[1]))
      })

      const animate = (timestamp: number) => {
        const runtime = timestamp - startTime
        const timeStep = Math.round(runtime)
        setCurrentLocation(arc[timeStep] || arc[arc.length - 1])
        if (timeStep <= ANIMATION_DURATION_MS) {
          window.requestAnimationFrame(animate)
        }
      }

      window.requestAnimationFrame((timeStamp) => {
        startTime = timeStamp
        animate(timeStamp)
      })
    }
  }, [props.coordinate])

  return (
    <WebMarker
      {...restProps}
      latitude={currentLocation.latitude}
      longitude={currentLocation.longitude}
      offsetLeft={webCenterOffset?.offsetLeft}
      offsetTop={webCenterOffset?.offsetTop}
    >
      {props.child}
    </WebMarker>
  )
}
