import { StackNavigationOptions } from '@react-navigation/stack'
import { Platform } from 'react-native'
import { colors } from 'src/assets/colors'
import { NavigationModalCloseButtonOptions } from 'src/navigation/components/NavigationModalCloseButton'
import { NavigationScreenBackButtonOptions } from 'src/navigation/components/NavigationScreenBackButton'
import { NavigatorSettings } from 'src/navigation/consts/NavigatorSettings'
import { UIStateStore } from 'src/stores/UIStore'
import { OsType } from 'src/util/types'

/*
 * Max width of the modal
 */
export const MODAL_MAX_WIDTH = 400

/*
 * Options used for a stack of screens
 */
export const getStackOptions = (shouldShowLandscapeWeb: boolean) => {
  // Presentation style for a stack of screens uses the modal style to be displayed.
  // On web, it must use the transparent version of the modal because we want to be able to
  // see the UI behind the partial modal
  const presentation: 'transparentModal' | 'modal' = shouldShowLandscapeWeb ? 'transparentModal' : 'modal'
  return {
    presentation,
  }
}

/*
 * Card styles used for all screens within a stack
 */
export const getScreenCardStyle = (shouldShowLandscapeWeb: boolean) =>
  shouldShowLandscapeWeb
    ? {
        width: MODAL_MAX_WIDTH,
        maxHeight: UIStateStore.screenHeight,
        backgroundColor: colors.white,
      }
    : {
        maxHeight: UIStateStore.screenHeight,
      }

export const getCardStyleInterpolator = () =>
  Platform.OS === OsType.Web
    ? () => ({
        containerStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
      })
    : undefined

export const getScreenOptionsWithBackButtonNoOverlay = (navigation: any, title: string): StackNavigationOptions => ({
  ...NavigationScreenBackButtonOptions(navigation, title),
  title,
  headerTitleAlign: NavigatorSettings.headerTitleAlign,
  cardStyle: getScreenCardStyle(UIStateStore.shouldShowLandscapeWeb),
})

export const getScreenOptionsWithBackButton = (navigation: any, title: string): StackNavigationOptions => ({
  ...getScreenOptionsWithBackButtonNoOverlay(navigation, title),
  cardStyleInterpolator: getCardStyleInterpolator(),
})

export const getScreenOptionsWithCloseButtonNoOverlay = (navigation: any, title: string): StackNavigationOptions => ({
  ...NavigationModalCloseButtonOptions(navigation, title),
  title,
  headerTitleAlign: NavigatorSettings.headerTitleAlign,
  cardStyle: getScreenCardStyle(UIStateStore.shouldShowLandscapeWeb),
})

export const getScreenOptionsWithCloseButton = (navigation: any, title: string): StackNavigationOptions => ({
  ...getScreenOptionsWithCloseButtonNoOverlay(navigation, title),
  cardStyleInterpolator: getCardStyleInterpolator(),
})

export const getNonDismissibleScreenOptions = (title: string): StackNavigationOptions => ({
  title,
  headerLeft: () => null,
  headerTitleAlign: NavigatorSettings.headerTitleAlign,
  gestureEnabled: false,
  cardStyle: getScreenCardStyle(UIStateStore.shouldShowLandscapeWeb),
  cardStyleInterpolator: getCardStyleInterpolator(),
})
