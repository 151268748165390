import { IRequestInsightsResponse } from '@sparelabs/api-client'
import { NumberFormatter } from '@sparelabs/math'

export const MAX_GRAPH_HEIGHT: number = 150
export class EmissionsDetailsHelper {
  public static isEmissionsDataValid(requestInsights: IRequestInsightsResponse | undefined): boolean {
    if (!requestInsights) {
      return false
    }
    if (
      // values should never be 0
      requestInsights.baselineCarbonEmissions === 0 ||
      requestInsights.requestCarbonEmissions === 0 ||
      requestInsights.totalCarbonEmissions === 0
    ) {
      return false
    }
    // Show emissions rating only when baseline is greater than a rider's contribution
    return requestInsights.baselineCarbonEmissions > requestInsights.requestCarbonEmissions
  }

  public static getEmissionsSavingPercentage(requestInsights: IRequestInsightsResponse | undefined): number {
    if (!requestInsights) {
      return 0
    }
    return NumberFormatter.decimalToRoundedPercentage(
      (requestInsights.baselineCarbonEmissions - requestInsights.requestCarbonEmissions) /
        requestInsights.baselineCarbonEmissions,
      0
    )
  }

  public static getRiderGraphHeight(requestInsights: IRequestInsightsResponse): number {
    return Math.round(
      (requestInsights.requestCarbonEmissions / requestInsights.baselineCarbonEmissions) * MAX_GRAPH_HEIGHT
    )
  }
}
