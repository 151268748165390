import { AppType, DeviceOS, DeviceStatus, IDeviceResponse } from '@sparelabs/api-client'
import ExpoConstants from 'expo-constants'
import * as Notifications from 'expo-notifications'
import { Platform } from 'react-native'
import { Constants } from 'src/consts/Constants'
import { handleError, IErrorWithResponse } from 'src/helpers/ErrorHelpers'
import { Logger } from 'src/helpers/Logger'
import { ClientIdStore } from 'src/stores/ClientIdStore'
import { getAuthedApi } from '../api'

export class NotificationsHelper {
  private static readonly logger = new Logger(this.name)

  private static readonly checkAndRequestPermissions = async (): Promise<boolean> => {
    const getResult = await Notifications.getPermissionsAsync()
    if (getResult.granted) {
      return true
    }
    const requestResult = await Notifications.requestPermissionsAsync()
    if (requestResult.granted) {
      return true
    }
    return false
  }

  public static requestPushAndUpdateDeviceInfo = async (): Promise<void> => {
    this.logger.debug('requesting push permissions and updating device push tokens')

    if (!(Platform.OS === DeviceOS.iOS || Platform.OS === DeviceOS.Android)) {
      this.logger.debug('not on iOS or Android, cannot setup push notifications')
      return
    }
    if (!ExpoConstants.isDevice) {
      this.logger.debug('not a real device, so we cannot setup push notifications')
      return
    }
    const hasPermission: boolean = await this.checkAndRequestPermissions()
    if (!hasPermission) {
      this.logger.debug('failed to get permissions for push notifications, archiving device')
      await this.archiveDevice()
      return
    }
    const expoToken = (await Notifications.getExpoPushTokenAsync()).data
    if (!expoToken) {
      this.logger.debug('failed to acquire token for push notifications')
      return
    }

    const apiClient = getAuthedApi()
    const appVersion = Constants.APP_VERSION
    const clientId: string = await ClientIdStore.getId()
    const response = await apiClient.devices.list({ clientId, status: DeviceStatus.Active })
    const existingDevice: IDeviceResponse | undefined = response.data[0]

    try {
      if (existingDevice) {
        this.logger.debug(`found existing device ${existingDevice.id}, patching it`)
        await apiClient.devices.patch(existingDevice.id, {
          firebaseToken: null,
          expoToken,
          deviceVersion: `${Platform.Version}`,
          appVersion,
          status: DeviceStatus.Active,
        })
      } else {
        this.logger.debug('creating a new device')
        await apiClient.devices.post({
          clientId,
          expoToken,
          firebaseIid: null,
          firebaseToken: null,
          deviceOS: Platform.OS as DeviceOS,
          deviceVersion: `${Platform.Version}`,
          appType: AppType.Rider,
          appName: Constants.APP_NAME,
          appVersion,
          mobileAppId: Constants.MOBILE_APP_ID,
        })
      }
    } catch (error) {
      this.logger.debug('encountered error while saving device information')
      handleError({ error: error as IErrorWithResponse, silent: true })
    }
  }

  public static archiveDevice = async (): Promise<void> => {
    try {
      const response = await getAuthedApi().devices.list({ clientId: await ClientIdStore.getId() })
      const existingDevice: IDeviceResponse | undefined = response.data[0]
      if (!existingDevice) {
        return
      }
      await getAuthedApi().devices.patch(existingDevice.id, { status: DeviceStatus.Inactive })
    } catch (error) {
      handleError({ error: error as IErrorWithResponse, silent: true })
    }
  }
}
