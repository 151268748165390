import React from 'react'
import { ActivityIndicator, Image, ImageURISource, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'
import { st } from 'src/locales'

export const LOGO_ITEM_HEIGHT = 70

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    backgroundColor: 'transparent',
  },
  cell: {
    minHeight: LOGO_ITEM_HEIGHT,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  logoContainer: {
    height: 56,
    width: 56,
    margin: 12,
    marginLeft: 'auto',
    borderRadius: 56 / 2,
  },
  textContainer: {
    flex: 1,
    marginVertical: 12,
    marginRight: 12,
  },
  title: {
    fontSize: 24,
    color: colors.textDark,
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 14,
    color: colors.textMedium,
  },
  cellRightIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cellLeft: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
  },
  testLabelContainer: {
    backgroundColor: colors.borderColor,
    justifyContent: 'center',
    height: 28,
    width: 52,
    borderRadius: 8,
    marginLeft: 8,
  },
  testLabelText: {
    color: colors.gray6,
    fontWeight: 'bold',
    textAlign: 'center',
    marginVertical: 'auto',
    fontSize: 14,
  },
})

interface IOwnProps {
  onPressItem: (id: string) => void
  loading?: boolean
  id: string
  withHeader?: boolean
  title: string
  subTitle?: string
  logo: ImageURISource
  testID?: string
  isTestOnly: boolean
}

export const OrganizationItem = (props: IOwnProps) => {
  const renderText = () => (
    <View style={[styles.textContainer, props.isTestOnly ? { marginRight: 56 } : {}]}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.title} numberOfLines={2}>
          {props.title}
        </Text>
        {props.isTestOnly && renderTestLabel()}
      </View>
      {props.subTitle ? <Text style={styles.subTitle}>{props.subTitle}</Text> : null}
    </View>
  )

  const renderTestLabel = () => (
    <View style={styles.testLabelContainer}>
      <Text style={styles.testLabelText}>{st.components.organizationItem.testLabel()}</Text>
    </View>
  )

  return (
    <View style={props.withHeader ? styles.container : null} testID={props.testID || props.title} accessible={true}>
      <TouchableListItemWrapper
        disabled={props.loading}
        style={styles.cell}
        onPress={() => props.onPressItem(props.id)}
      >
        <View style={styles.cellLeft}>{renderText()}</View>
        <View style={styles.cellRightIcon}>
          {props.loading ? (
            <ActivityIndicator color='black' style={styles.logoContainer} />
          ) : (
            <Image style={styles.logoContainer} resizeMode='cover' source={props.logo} />
          )}
        </View>
      </TouchableListItemWrapper>
    </View>
  )
}
