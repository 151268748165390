import React from 'react'
import { StyleSheet, View } from 'react-native'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { SecondaryButton } from 'src/components/buttons/SecondaryButton'
import { st } from 'src/locales'

interface IStandardLoginButtonsProps {
  handleLoginPress: () => void
  handleRegisterPress: () => void
}

export const StandardLoginButton = ({
  handleLoginPress,
  handleRegisterPress,
}: IStandardLoginButtonsProps): JSX.Element => (
  <>
    <View>
      <PrimaryButton testID='loginButton' title={st.screens.login.loginLabel()} onPress={() => handleLoginPress()} />
    </View>
    <View style={styles.registerButton}>
      <SecondaryButton title={st.screens.login.registerLabel()} onPress={() => handleRegisterPress()} />
    </View>
  </>
)

const styles = StyleSheet.create({
  registerButton: {
    marginTop: 10,
  },
})
