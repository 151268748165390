import React, { ReactNode, useEffect, useRef } from 'react'
import { Animated, StyleSheet } from 'react-native'

interface IProps {
  height: number
  isOpen: boolean
  children: ReactNode
}
const styles = StyleSheet.create({
  slidingView: {
    flex: 1,
    overflow: 'hidden',
  },
  subView: {
    flex: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'transparent',
  },
})

export const SlidingView = (props: IProps) => {
  useEffect(() => {
    Animated.timing(translateY, {
      toValue: props.isOpen ? 1 : 0,
      duration: 100,
      useNativeDriver: true,
    }).start()
  }, [props.isOpen])
  const translateY = useRef(new Animated.Value(props.isOpen ? 1 : 0)).current
  const translateYValue = translateY.interpolate({
    inputRange: [0, 1],
    outputRange: [props.height, 0],
  })
  return (
    <Animated.View style={[styles.subView, { height: props.height }, { transform: [{ translateY: translateYValue }] }]}>
      {props.children}
    </Animated.View>
  )
}
