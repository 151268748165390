import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { FieldError } from 'src/components/form/FieldShared'
import { IFormFieldProps } from 'src/components/form/FormFieldTypes'
import { TextInputBox } from 'src/components/TextInputBox'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    paddingVertical: 20,
    color: colors.gray90,
    fontSize: 21,
    lineHeight: 24,
    fontWeight: 'bold',
  },
})

export const FormLongTextField = ({
  label,
  touched,
  error,
  value,
  onChange,
  hint,
  isRequired,
}: IFormFieldProps<string>) => (
  <View style={styles.container}>
    <Text style={styles.title}>
      {label}
      {isRequired && <Text style={[styles.title, { color: colors.red50 }]}> *</Text>}
    </Text>
    <TextInputBox value={value ?? ''} placeHolder={hint ?? ''} onChangeText={onChange} />
    <FieldError touched={touched} error={error} />
  </View>
)
