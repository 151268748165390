import React, { Component } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { st } from 'src/locales'

const getStyles = () =>
  StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      backgroundColor: colors.white,
      marginHorizontal: 0,
      marginBottom: 0,
      marginTop: 52,
    },
    title: {
      color: colors.gray90,
      fontSize: 16,
      lineHeight: 20,
      fontWeight: 'bold',
      marginBottom: 4,
      marginTop: 16,
    },
    description: {
      textAlign: 'center',
      padding: 30,
      paddingTop: 0,
      fontSize: 16,
      color: colors.gray70,
    },
  })

export class NoRideOptionsCard extends Component {
  public render() {
    const styles = getStyles()

    return (
      <View style={styles.container}>
        <Text style={styles.title}>{st.components.noRideOptionsCard.title()}</Text>
        <Text style={styles.description}>{st.components.noRideOptionsCard.info()}</Text>
      </View>
    )
  }
}
