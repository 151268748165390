import React, { Component } from 'react'
import { StyleSheet } from 'react-native'
import Dash from 'react-native-dash'
import { colors } from 'src/assets/colors'

const styles = StyleSheet.create({
  dottedLine: {
    width: 32,
    right: -14,
    flexDirection: 'column',
    borderRadius: 100,
    marginTop: 8,
  },
})

interface IProps {
  dottedLineHeight: number
}

export class TimelineDash extends Component<IProps> {
  public render() {
    return (
      <Dash
        dashGap={8}
        dashThickness={4}
        dashLength={4}
        dashColor={colors.gray30}
        style={{ ...styles.dottedLine, height: this.props.dottedLineHeight }}
        dashStyle={{ borderRadius: 100 }}
      />
    )
  }
}
