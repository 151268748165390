import React, { ReactNode } from 'react'
import { Platform, View } from 'react-native'
import { OsType } from 'src/util/types'

export interface IAccessibleWrapperProps {
  height: number
  children: ReactNode
}

// since there is no way to calculate how much space the list put in ScrollView will take it is necessary to
// calculate the maximum height of the container where ScrollView will be placed beforehand for WEB version ONLY
// this AccessibleBottomSheetWrapper stores the max height allowed for the ScrollView
export const AccessibleBottomSheetWrapper = (props: IAccessibleWrapperProps) =>
  Platform.OS === OsType.Web ? (
    <View style={{ maxHeight: props.height }}>{props.children}</View>
  ) : (
    <View>{props.children}</View>
  )
