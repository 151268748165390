import React, { Component } from 'react'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { st } from 'src/locales'

const styles = StyleSheet.create({
  touchable: {
    flex: 1,
    flexGrow: 1,
    paddingBottom: 16,
  },
  image: {
    height: 30,
    width: 30,
    tintColor: 'white',
  },
  thumbsUpButton: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 8,
    backgroundColor: colors.green50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  thumbsDownButton: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 8,
    backgroundColor: colors.red50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedUp: {
    tintColor: 'white',
    backgroundColor: colors.green50,
  },
  selectedDown: {
    tintColor: 'white',
    backgroundColor: colors.red50,
  },
  disabledThumbsUp: {
    tintColor: 'white',
    backgroundColor: colors.green30,
  },
  disabledThumbsDown: {
    tintColor: 'white',
    backgroundColor: colors.red30,
  },
})

interface IProps {
  selected: boolean
  thumbsUp: boolean
  disabledStyle?: boolean
  onPress: (thumbsUp: boolean) => void
}

export class ThumbButton extends Component<IProps> {
  public render() {
    return (
      <TouchableOpacity
        style={styles.touchable}
        accessibilityRole='button'
        accessibilityLabel={
          this.props.thumbsUp ? st.screens.review.accessibilityThumbsUp() : st.screens.review.accessibilityThumbsDown()
        }
        accessibilityState={{ selected: this.props.selected }}
        onPress={() => this.props.onPress(this.props.thumbsUp)}
      >
        <View
          style={[
            this.props.thumbsUp ? styles.thumbsUpButton : styles.thumbsDownButton,
            this.props.disabledStyle
              ? this.props.thumbsUp
                ? styles.disabledThumbsUp
                : styles.disabledThumbsDown
              : null,
            this.props.selected ? (this.props.thumbsUp ? styles.selectedUp : styles.selectedDown) : null,
          ]}
        >
          <Image style={[styles.image]} source={this.props.thumbsUp ? Images.thumbsUp : Images.thumbsDown} />
        </View>
      </TouchableOpacity>
    )
  }
}
