import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from 'src/assets/colors'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 4,
    borderRadius: 8,
    marginBottom: 8,
    paddingBottom: 4,
  },
  background: {
    width: '100%',
    height: 4,
    backgroundColor: colors.blue20,
    borderRadius: 8,
  },
  bar: {
    width: '100%',
    height: 4,
    backgroundColor: colors.blue50,
    borderRadius: 8,
  },
})

interface IProps {
  firstStep: boolean
}

export class TipProgressBar extends Component<IProps> {
  public render() {
    return (
      <View style={styles.container}>
        <View style={styles.background}>
          <View style={[styles.bar, this.props.firstStep ? { width: '50%' } : { width: '100%' }]}></View>
        </View>
      </View>
    )
  }
}
