import React, { ReactNode } from 'react'
import { FlatList, StyleProp, ViewStyle } from 'react-native'

export const SimpleVirtualizedList = ({ children, style }: { children: ReactNode; style: StyleProp<ViewStyle> }) => (
  <FlatList
    style={style}
    data={[]}
    keyExtractor={() => 'key'}
    renderItem={null}
    ListHeaderComponent={<>{children}</>}
  />
)
