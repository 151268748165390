import { isFinite } from 'lodash'
import { moment } from 'src/helpers/Moment'
import { st } from 'src/locales'

export const MINUTE_IN_MS = 60000

export function getDateFormatted(ts: number) {
  return moment.unix(ts).calendar(undefined, {
    sameDay: `[${st.helpers.timeHelpers.today()}]`,
    nextDay: `[${st.helpers.timeHelpers.tomorrow()}]`,
    nextWeek: 'ddd',
    lastDay: `[${st.helpers.timeHelpers.yesterday()}]`,
    lastWeek: st.helpers.timeHelpers.lastWeek({ dayOfWeek: 'ddd' }),
    sameElse: 'L',
  })
}

export const isNum = (value: undefined | number): value is number => isFinite(value)

export const timeToString = (time: number): string =>
  `${getDateFormatted(time)} at ${moment.unix(time).format('h:mm A')}`

export const timeToStringWithFlexibility = (time: number, flexibility: number): string =>
  `${getDateFormatted(time)} ${moment.unix(time).format('h:mm A')} - ${moment
    .unix(time + flexibility)
    .format('h:mm A')}`

export const timeRangeToString = (range: number[]): string => {
  const startTime = `${getDateFormatted(range[0])} ${moment.unix(range[0]).format('LT')}`
  const endTime = moment.unix(range[1]).format('LT')
  return `${startTime}-${endTime}`
}

export const roundSecondsToMinutesMinOne = (seconds: number) => {
  const minutes = Math.round(seconds / 60)
  return minutes <= 0 ? 1 : minutes
}

// Strip year from localized time
// https://github.com/moment/moment/issues/3341#issuecomment-292077586
export const getMonthDate = (time: number): string =>
  moment
    .unix(time)
    .format('L')
    .replace(new RegExp('[^.]?' + moment().format('YYYY') + '.?'), '')

export const getMonthDateFromString = (time: string): string =>
  time.replace(new RegExp('[^.]?' + moment().format('YYYY') + '.?'), '')
