import React from 'react'
import { ActivityIndicator, Image, StyleSheet, Text, View } from 'react-native'
import { colors } from 'src/assets/colors'
import { Images } from 'src/assets/Images'
import { TouchableListItemWrapper } from 'src/components/touchableListItemWrapper/TouchableListItemWrapper'
import { IconCircle } from '../IconCircle'

const styles = StyleSheet.create({
  cell: {
    height: 70,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexBasis: 'auto',
    backgroundColor: 'white',
  },
  rightArrow: {
    margin: 15,
    marginLeft: 'auto',
  },
  titleContainer: {},
  title: {
    fontSize: 17,
    color: colors.textDark,
  },
  subTitle: {
    fontSize: 13,
    color: colors.textMedium,
  },
  cellRightIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cellLeftIcon: {
    marginLeft: 20,
    marginRight: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  cellText: {
    flex: 1,
    paddingRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
    color: colors.gray90,
  },
})

interface IProps {
  loading?: boolean
  selected: boolean
  data: IListViewItem
  testID?: string
}

export interface IListViewItem {
  id: string
  title: string
  subtitle?: string
  titleIcon?: IListItemIcon
  iconColor?: string
  titleColor?: string
  onPress?: () => void
}

export enum IListItemIcon {
  Heart = 'heart',
  Star = 'star',
  Home = 'home',
  BriefCase = 'briefcase',
  PrayingHands = 'praying-hands',
  Subway = 'subway',
  School = 'graduation-cap',
  Child = 'child',
  Utensils = 'utensils',
  Dumbbell = 'dumbbell',
  Plus = 'plus',
}

export const ListViewItem = ({ loading, selected, data, testID }: IProps) => {
  const renderIcon = () => {
    if (data.titleIcon) {
      return <IconCircle icon={data.titleIcon} backgroundColor={data.iconColor || colors.darkGrayBlue} size={36} />
    }
    return null
  }

  const renderTitle = () => (
    <View style={styles.titleContainer}>
      <Text style={[styles.title, data.titleColor ? { color: data.titleColor } : null]}>{data.title}</Text>
      {data.subtitle ? <Text style={styles.subTitle}>{data.subtitle}</Text> : null}
    </View>
  )

  const renderLoader = () => {
    if (loading && selected) {
      return <ActivityIndicator color='black' style={styles.rightArrow} testID='loading' />
    }
    return null
  }

  return (
    <TouchableListItemWrapper
      disabled={loading}
      style={styles.cell}
      onPress={() => data.onPress?.()}
      testPropertiesKey={testID}
    >
      <View style={styles.cellLeftIcon}>{renderIcon()}</View>
      <View style={styles.cellText}>{renderTitle()}</View>
      <View style={styles.cellRightIcon}>
        {renderLoader()}
        <Image style={styles.rightArrow} resizeMode='contain' source={Images.rightArrow} />
      </View>
    </TouchableListItemWrapper>
  )
}
