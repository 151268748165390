import { createNavigationContainerRef } from '@react-navigation/native'
import { Logger } from 'src/helpers/Logger'
import { NavigationStateHelper } from 'src/navigation/consts/NavigationStateHelper'
import { ScreenName } from 'src/navigation/types/ScreenNameTypes'
import { ParamsListRoot } from 'src/navigation/types/ScreenParamsListTypes'

/**
 * Note that using this approach is against our React style guide.
 * https://www.notion.so/sparelabs/React-coding-style-f9f13d677786425d8586186335f1a28b#bb3c185ccb844aa59143d854756cb4a1
 *
 * This should only be used for cases where we have no way to obtain a reference to the
 * navigation prop through the screens or by passing down.
 *
 * Currently the only use cases we use this for are:
 * 1. jump to the login screen as soon as we start seeing auth errors from the API
 * 2. opening a stripe payment confirm dialog from our payment handling logic
 *
 * https://reactnavigation.org/docs/6.x/navigating-without-navigation-prop
 */

export class RootNavigation {
  private static readonly logger = new Logger(this.name)
  public static navigationRef = createNavigationContainerRef<ParamsListRoot>()

  public static reset() {
    if (!RootNavigation.navigationRef.isReady()) {
      this.logger.debug('navigationRef.isReady() is false, cannot reset')
      return
    }
    RootNavigation.navigationRef.resetRoot({
      routes: [NavigationStateHelper.getRootLoginRoutes()],
    })
  }

  public static handleNavigateConfirmStripePayment(params: ParamsListRoot[ScreenName.ConfirmStripePayment]) {
    if (!RootNavigation.navigationRef.isReady()) {
      this.logger.debug('navigationRef.isReady() is false, cannot navigate to confirming stripe payment')
      return
    }
    RootNavigation.navigationRef.navigate(ScreenName.ConfirmStripePayment, params)
  }
}
