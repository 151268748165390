import { Platform } from 'react-native'
import { ScreenName } from 'src/navigation'
import { OsType } from 'src/util/types'

interface IParams {
  uri: string
}

type Navigate = (screen: ScreenName, params: { uri: string }) => void

/**
 * Converts hyperlinks on rider web to open in new tab, due to web views not working in the browser
 */
export class WebViewHelper {
  public static handleLink(params: IParams, navigate: Navigate): void {
    Platform.OS === OsType.Web
      ? window.open(params.uri, '_blank', 'noreferrer')
      : navigate(ScreenName.WebViewModal, params)
  }
}
