import { observer } from 'mobx-react-lite'
import React from 'react'
import { ActivityIndicator } from 'react-native'
import { colors } from 'src/assets/colors'
import { CancelButton } from 'src/components/buttons/CancelButton'
import { st } from 'src/locales'
import { RequestCancellationStore } from 'src/stores/RequestCancellationStore'

interface IProps {
  handleNavigateRequestCancellation: () => void
}

export const RequestCancelButton = observer((props: IProps) => {
  if (RequestCancellationStore.isLoading) {
    return <ActivityIndicator color={colors.textDark} />
  }

  return (
    <CancelButton
      title={st.components.requestCard.cancelButtonTitle()}
      onPress={() => props.handleNavigateRequestCancellation()}
      testID='requestCancelAction'
    />
  )
})
