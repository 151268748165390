import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useAsync } from 'react-async-hook'
import { Keyboard, KeyboardAvoidingView, Platform, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Animations } from 'src/assets/Animations'
import { colors } from 'src/assets/colors'
import { ButtonWrapper } from 'src/components/buttons/ButtonWrapper'
import { PrimaryButton } from 'src/components/buttons/PrimaryButton'
import { LottieAnimation } from 'src/components/lottieAnimation/LottieAnimation'
import { ReviewTagButton } from 'src/components/review/ReviewTagButton'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { AuthenticatorHelper } from 'src/helpers/AuthenticatorHelper'
import { moment } from 'src/helpers/Moment'
import { TipHelper } from 'src/helpers/TipHelper'
import { useNavigationPreventBackButton } from 'src/hooks/useNavigationPreventBackButton'
import { st } from 'src/locales'
import { ParamsListReview, ScreenName, ScreenPropsReview } from 'src/navigation'
import { MODAL_MAX_WIDTH } from 'src/navigation/consts/NavigationHelper'
import {
  getReviewDescription,
  getTags,
  handlePressConfirm,
  handleThumbPress,
} from 'src/screens/review/ReviewScreenHelper'
import { TipProgressBar } from 'src/screens/tip/TipProgressBar'
import { LoadingStore } from 'src/stores/LoadingStore'
import { ReviewRating, ReviewStore } from 'src/stores/ReviewStore'
import { AlertButtonStyle, OsType } from 'src/util/types'
import { ThumbButton } from '../../components/review/ThumbButton'

const styles = StyleSheet.create({
  safeAreaContainer: {
    flex: 1,
    backgroundColor: colors.white,
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
  headingContent: {
    padding: 16,
  },
  title: {
    color: colors.gray90,
    fontWeight: 'bold',
    fontSize: 21,
  },
  subtitle: {
    color: colors.gray70,
    fontSize: 16,
    fontWeight: '400',
    paddingVertical: 4,
  },
  description: {
    padding: 16,
    color: colors.gray90,
    fontSize: 16,
    fontWeight: 'bold',
  },
  thumbs: {
    flexDirection: 'row',
    paddingHorizontal: 16,
  },
  textInputContainer: {
    paddingTop: 20,
    padding: 20,
    flex: 1,
  },
  textInput: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.gray2,
    paddingTop: 8,
    paddingHorizontal: 8,
    fontSize: 15,
    flex: 1,
    height: 100,
  },
  tagListContainer: {
    paddingHorizontal: 16,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  submitButtonContainer: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.textLight,
    padding: 16,
  },
  tagContainer: {
    paddingRight: 4,
    paddingVertical: 5,
  },
  thankYouContainer: {
    alignItems: 'center',
    paddingVertical: 20,
    backgroundColor: colors.white,
  },
  thankYouText: {
    color: colors.textMedium,
    fontSize: 22,
  },
})

interface IReviewProps {
  loadingStore: LoadingStore
  handleDismiss: () => void
  handleNavigateAddTip: (params: ParamsListReview[ScreenName.AddTip]) => void
}

export const ReviewScreenView = observer(
  ({
    handleDismiss,
    handleNavigateAddTip,
    request,
    canRiderTip,
    loadingStore,
  }: IReviewProps & ParamsListReview[ScreenName.Review]): JSX.Element => {
    const orgName = AuthenticatorHelper.getOrganization().name
    const scrollViewRef = useRef<ScrollView>(null)
    const [isTipPolicyValid, setIsTipPolicyValid] = useState(false)
    const [isDoneReview, setIsDoneReview] = useState(false)

    useAsync(async () => {
      const isTipPolicyValidResult = await TipHelper.isTipPolicyValid(request)
      setIsTipPolicyValid(isTipPolicyValidResult)
    }, [])

    useEffect(() => {
      const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
        scrollViewRef.current?.scrollToEnd()
      })

      return () => {
        ReviewStore.clear()
        keyboardDidShowListener.remove()
      }
    }, [])

    const renderTags = () => {
      const reviewTagNames = getTags(ReviewStore.rating)

      if (!reviewTagNames) {
        return null
      }

      const tags = reviewTagNames.map((tag) => (
        <View key={tag + JSON.stringify(ReviewStore.rating)} style={styles.tagContainer}>
          <ReviewTagButton
            key={tag + JSON.stringify(ReviewStore.rating)}
            active={ReviewStore.tags.get(tag)}
            onPress={() => ReviewStore.setTag(tag)}
            tagType={tag}
          />
        </View>
      ))
      return <View style={styles.tagListContainer}>{tags}</View>
    }

    const renderThankYouMessage = () => (
      <View style={styles.thankYouContainer}>
        <LottieAnimation
          source={Animations.confirmation}
          autoPlay={true}
          loop={false}
          width='60%'
          maxWidth={MODAL_MAX_WIDTH}
        />
        <Text style={styles.thankYouText}>{st.screens.review.thankYouMessage()}</Text>
      </View>
    )

    const renderReviewForm = (orgName: string) => {
      const date = request.scheduledPickupTs ? moment.unix(request.scheduledPickupTs).format('LL') : null
      const address = request.requestedDropoffAddress || request.scheduledDropoffAddress
      const shouldRenderProgressBar = isTipPolicyValid

      return (
        <View style={styles.container}>
          <KeyboardAvoidingView
            // KeyboardAvoidingView interacts differently between Android and iOS
            keyboardVerticalOffset={Platform.OS === OsType.iOS ? 150 : -150}
            behavior='padding'
            style={styles.container}
          >
            <ScrollView ref={scrollViewRef}>
              <View style={styles.headingContent}>
                <Text style={styles.title}>{st.screens.review.reviewTitle()}</Text>
                {address && date && (
                  <Text style={styles.subtitle}>
                    {st.screens.review.rideDateAndAddress({
                      date,
                      address,
                    })}
                  </Text>
                )}
              </View>
              <View style={styles.thumbs}>
                <View style={{ flex: 1, marginRight: 8 }}>
                  <ThumbButton
                    thumbsUp={true}
                    selected={ReviewStore.rating === ReviewRating.Positive}
                    onPress={(thumbsUp) => handleThumbPress(thumbsUp)}
                    disabledStyle={true}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <ThumbButton
                    thumbsUp={false}
                    selected={ReviewStore.rating === ReviewRating.Negative}
                    onPress={(thumbsUp) => handleThumbPress(thumbsUp)}
                    disabledStyle={true}
                  />
                </View>
              </View>
              <Text style={styles.description}>{getReviewDescription(ReviewStore.rating)}</Text>
              {renderTags()}
              <View style={styles.textInputContainer}>
                <TextInput
                  testID='review-text-input'
                  style={styles.textInput}
                  editable={true}
                  multiline={true}
                  underlineColorAndroid='transparent'
                  placeholder={st.screens.review.reviewPlaceholder({ organization: orgName })}
                  numberOfLines={5}
                  onChangeText={(comment) => ReviewStore.setComment(comment)}
                  value={ReviewStore.comment || ''}
                  textAlignVertical='top'
                />
              </View>
            </ScrollView>
          </KeyboardAvoidingView>
          <ButtonWrapper>
            {shouldRenderProgressBar && <TipProgressBar firstStep={true} />}
            <PrimaryButton
              loading={loadingStore.isLoading()}
              title={st.screens.review.sendFeedback()}
              onPress={() =>
                handlePressConfirm(
                  request,
                  canRiderTip,
                  isTipPolicyValid,
                  handleNavigateAddTip,
                  setIsDoneReview,
                  handleDismiss,
                  () => {
                    AlertHelper.alert(
                      st.screens.review.alertTitle(),
                      st.screens.review.alertContent(),
                      [{ text: st.common.buttonConfirm(), style: AlertButtonStyle.Cancel }],
                      { cancelable: false }
                    )
                  },
                  loadingStore
                )
              }
            />
          </ButtonWrapper>
        </View>
      )
    }

    const renderMainView = (orgName: string): JSX.Element => {
      // if the review is done there is no tipping step, then render the animation
      if (isDoneReview && !isTipPolicyValid) {
        return renderThankYouMessage()
      }
      // otherwise we should continue to render the form
      return renderReviewForm(orgName)
    }

    return <SafeAreaView style={styles.safeAreaContainer}>{renderMainView(orgName)}</SafeAreaView>
  }
)

export const ReviewScreen = (props: ScreenPropsReview<ScreenName.Review>) => {
  useNavigationPreventBackButton(() => props.route.params.canRiderTip, props.navigation, [
    props.route.params.canRiderTip,
  ])
  return (
    <ReviewScreenView
      {...props.route.params}
      loadingStore={new LoadingStore()}
      handleDismiss={() => props.navigation.goBack()}
      handleNavigateAddTip={(params) => props.navigation.navigate(ScreenName.AddTip, params)}
    />
  )
}
