import { FontAwesomeIconStyle, IEstimateOutput } from '@sparelabs/api-client'
import { IPoint } from '@sparelabs/geography'
import React, { Component } from 'react'
import { colors } from 'src/assets/colors'
import { IPickupAreaPillProps } from 'src/components/mapMarkers/MapMarkerProps'
import { Pill } from 'src/components/PillArea'
import { PillMarker } from 'src/components/PillMarker'
import { getMinutesUntilPickup } from 'src/helpers/EstimateHelper'
import { MapMarkerHelper, pickupPillAndroidAnchor } from 'src/helpers/MapMarkerHelper'
import { st } from 'src/locales'
import { IMarker, MarkerTypes } from 'src/types'

export class PickupAreaPill extends Component<IPickupAreaPillProps> {
  public render() {
    const { mappable } = this.props

    if (mappable === null || !MapMarkerHelper.showPickupRadius(mappable)) {
      return null
    }

    const radius = (mappable as IEstimateOutput).scheduledPickupRadius || 0
    const marker = this.createMarker(mappable.requestedPickupLocation, radius)
    return <PillMarker marker={marker} />
  }

  private readonly createMarker = (pickupLocation: IPoint, radius: number): IMarker => {
    const scheduledPickupTs = (this.props.mappable as IEstimateOutput).scheduledPickupTs
    let pickupTime: number | undefined

    if (scheduledPickupTs) {
      pickupTime = getMinutesUntilPickup(scheduledPickupTs)
    }
    return {
      name: MarkerTypes.PickupPill,
      coordinate: pickupLocation,
      centerOffset: MapMarkerHelper.getPickupPillCenterOffSet(radius),
      anchor: pickupPillAndroidAnchor,
      child: (
        <Pill
          backgroundColor={colors.green50}
          textColor='white'
          title={st.helpers.mapMarkerHelpers.pickupArea()}
          subtitleBackgroundColor={colors.green10}
          subtitleTextColor={colors.green70}
          subtitle={
            pickupTime && pickupTime < 60 ? st.helpers.mapMarkerHelpers.pickupTime({ time: pickupTime }) : undefined
          }
          icon={[FontAwesomeIconStyle.Solid, 'walking']}
        />
      ),
    }
  }
}
