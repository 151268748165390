import { loadStripe } from '@stripe/stripe-js'
import { AlertHelper } from 'src/helpers/AlertHelper'
import { IStripeConfirmIntentHelper } from 'src/helpers/payments/StripeConfirmIntentHelper'
import { st } from 'src/locales'

export class StripeConfirmIntentHelper implements IStripeConfirmIntentHelper {
  public async confirmIntent(stripePublicKey: string, clientSecret: string): Promise<void> {
    /**
     * On native, we use WebViews to use stripe-js for Stripe 2FA
     * On rider web, this can be done in the browser (no need to navigate to ConfirmStripePayment screen)
     */
    const stripe = await loadStripe(stripePublicKey)
    if (stripe) {
      const result = await stripe.confirmCardPayment(clientSecret)
      if (result.error) {
        throw new Error('Failed to confirm stripe payment')
      }
    } else {
      AlertHelper.alert(st.payments.paymentErrorTitle(), st.payments.stripeError())
    }
  }
}
