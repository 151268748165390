import { IEstimateOutput } from '@sparelabs/api-client'
import React, { Component } from 'react'
import { colors } from 'src/assets/colors'
import { Circle } from 'src/components/mapMarkers/circle/Circle'
import { IRadiusProps } from 'src/components/mapMarkers/MapMarkerProps'
import { geoJsonToCoords } from 'src/helpers/MapHelper'
import { MapMarkerHelper } from 'src/helpers/MapMarkerHelper'

export class PickupRadius extends Component<IRadiusProps> {
  public render() {
    const { mappable } = this.props
    if (!MapMarkerHelper.showPickupRadius(mappable)) {
      return null
    }

    // If code passes the above check, the mappable is an IEstimateOutput
    // and scheduledPickupRadius is defined
    return (
      <Circle
        radius={(mappable as IEstimateOutput).scheduledPickupRadius as number}
        center={geoJsonToCoords(mappable.requestedPickupLocation)}
        strokeWidth={1}
        strokeColor={colors.green50}
        fillColor={colors.green50Fill}
      />
    )
  }
}
