import { ApiClient } from '@sparelabs/api-client'
import { createURL, parse } from 'expo-linking'
import {
  openAuthSessionAsync,
  WebBrowserRedirectResult,
  WebBrowserResult,
  WebBrowserResultType,
} from 'expo-web-browser'
import { Constants } from 'src/consts/Constants'

interface ISsoRedirectResponse {
  code: string | null
  ssoSessionStatus: 'success' | WebBrowserResultType
}

export class SSOHelper {
  public static isWebBrowserRedirectResult = (
    response: WebBrowserRedirectResult | WebBrowserResult
  ): response is WebBrowserRedirectResult => (response as WebBrowserRedirectResult).url !== undefined

  public static readonly getWorkosCode = async (
    loginApiClient: ApiClient,
    redirectHost: string
  ): Promise<ISsoRedirectResponse> => {
    const currentAppLocation = createURL('login')

    const { authorizationUrl } = await loginApiClient.workos.auth({
      domain: Constants.SSO_DOMAIN,
      redirectURI: `${redirectHost}/v1/identity/workos/riderRedirect`,
      state: JSON.stringify({ mobileAppId: Constants.MOBILE_APP_ID, redirectUri: currentAppLocation }),
    })

    if (!authorizationUrl) {
      throw new Error('Unable to create authorizationUrl for SSO login')
    }

    // Opens ssoUrl in the native device browser
    const ssoRedirectResponse = await openAuthSessionAsync(authorizationUrl, currentAppLocation, {
      showInRecents: true,
    })

    // Handle edge case of user dismissing auth window before sso can finish
    // will intentionally not throw an error
    if (
      ssoRedirectResponse.type === WebBrowserResultType.CANCEL ||
      ssoRedirectResponse.type === WebBrowserResultType.DISMISS
    ) {
      return { code: null, ssoSessionStatus: ssoRedirectResponse.type }
    }

    if (!SSOHelper.isWebBrowserRedirectResult(ssoRedirectResponse)) {
      throw new Error('SSO login response did not return a valid url as part of redirect response ')
    }

    const parsedSsoResponse = parse(ssoRedirectResponse.url)

    if (typeof parsedSsoResponse.queryParams?.code !== 'string') {
      throw new Error('Incorrectly formatted WorkOs code')
    }

    return { code: parsedSsoResponse.queryParams.code, ssoSessionStatus: ssoRedirectResponse.type }
  }
}
