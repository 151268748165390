import * as Linking from 'expo-linking'
import queryString, { ParsedQuery, ParsedUrl } from 'query-string'
import { Logger } from 'src/helpers/Logger'
import { IEstimateDeepLinkParams, ISurveyDeepLinkParams } from 'src/screens/home/HomeDeepLinkHandler'
import { ILoginDeepLinkParams } from 'src/screens/login/SetOrganization'
import { IDeepLinkUrl } from 'src/types/deepLink'

interface IInitialDeepLink extends ParsedUrl {
  url: DeepLinkAction
  query: IInitialDeepLinkParams
}

export interface IDeepLink extends ParsedUrl {
  url: DeepLinkAction
  query: IDeepLinkParams
}

export enum DeepLinkAction {
  Estimate = 'https://sparerider/estimate',
  Survey = 'https://sparerider/surveys',
  Login = 'https://sparerider/login',
}

interface IInitialDeepLinkParams extends ParsedQuery {
  link: string
}

type IDeepLinkParams = IEstimateDeepLinkParams | ISurveyDeepLinkParams | ILoginDeepLinkParams

/**
 * This class handles listening for and parsing deep links (currently implemented using Firebase Dynamic Links).
 * These links take the form https://sparelabs.page.link/?link=URL_ENCODED_LINK&ofl=https://sparelabs.com&
 * apn=com.sparelabs.platform.rider.base.staging&isi=1414542240&ibi=com.sparelabs.platform.rider.base.staging
 *
 * ofl - fallback URL for if the device is not iOS or Android
 * apn - Android bundle identifier
 * ibi - iOS bundle identifier
 * isi - App Store ID for the app, for users who do not have the app installed
 * link - the URL and parameters used for the deep link, matching the IDeepLink type - this URL must be URL encoded
 *
 * For making an estimate the URL should be https://sparerider/estimate (this will be the same for all white label apps)
 * and the params should be as specified in IEstimateDeepLinkParams, for example:
 *
 * https://sparerider/estimate?requestedPickupLatitude=49.288783&requestedPickupLongitude=-123.112644&
 * requestedDropoffLatitude=49.281487&requestedDropoffLongitude=-123.123225&requestedPickupTs=1626293874&
 * numRiders=3&serviceId=ec0a23ef-d936-4a83-bfb2-c24548f823fc
 *
 * Which when URL encoded becomes:
 *
 * https%3A%2F%2Fsparerider%2Festimate%3FrequestedPickupLatitude%3D49.288783%26requestedPickupLongitude%
 * 3D-123.112644%26requestedDropoffLatitude%3D49.281487%26requestedDropoffLongitude%3D-123.123225%
 * 26requestedPickupTs%3D1626293874%26numRiders%3D3%26serviceId%3Dec0a23ef-d936-4a83-bfb2-c24548f823fc
 */

export class DeepLinkHelper {
  public static initialDeepLink: string | null = null
  private readonly logger = new Logger('DeepLinkHelper')

  constructor(public readonly action: (url: IDeepLinkUrl) => Promise<void>) {}

  /**
   * Listens for deep links and triggers the appropriate action based on what the app is doing
   */
  public startListening = async (): Promise<void> => {
    // Check to see if the app was opened with a deep link, if so then handle it immediately
    const initialDeepLink = await Linking.getInitialURL()
    if (initialDeepLink && DeepLinkHelper.initialDeepLink !== initialDeepLink) {
      DeepLinkHelper.initialDeepLink = initialDeepLink
      await this.action({ url: initialDeepLink })
    }
    // Listen for links for future
    Linking.addEventListener('url', this.action)
  }

  public stopListening = () => {
    Linking.removeEventListener('url', this.action)
  }

  public static parseDeepLink = (url: string) => {
    const parsedInitialDeepLink = queryString.parseUrl(url) as IInitialDeepLink
    if (parsedInitialDeepLink.query.link) {
      const parsedUrl = queryString.parseUrl(parsedInitialDeepLink.query.link) as IDeepLink
      return parsedUrl
    }
  }
}
