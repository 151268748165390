import React, { useEffect, useRef } from 'react'
import { StyleProp, StyleSheet, TextInput, ViewStyle } from 'react-native'
import { colors } from 'src/assets/colors'

const styles = StyleSheet.create({
  inputBase: {
    backgroundColor: colors.white,
    height: 44,
    paddingHorizontal: 8,
    flex: 1,
    fontSize: 16,
    color: colors.textDark,
    borderWidth: 1,
    borderColor: colors.borderBlue,
  },
  inputSelected: {
    borderWidth: 1,
    borderColor: colors.blue50,
  },
})

interface IProps {
  selected?: boolean
  onFocus: (ref: any) => void
  testID?: string
  placeholder: string
  onChangeText: (text: string) => void
  value: string
  autoFocus?: boolean
  inputStyle?: StyleProp<ViewStyle>
}

export const AutoSuggestTextInput = (props: IProps) => {
  const inputRef = useRef<TextInput>(null)
  useEffect(() => {
    if (props.selected && inputRef && inputRef.current && !inputRef.current.isFocused()) {
      inputRef.current.focus()
    }
  }, [props.selected, inputRef])

  return (
    <TextInput
      ref={inputRef}
      style={[styles.inputBase, props.selected ? styles.inputSelected : {}, props.inputStyle ?? {}]}
      testID={props.testID}
      underlineColorAndroid='transparent'
      placeholder={props.placeholder}
      onChangeText={props.onChangeText}
      value={props.value}
      editable={true}
      onFocus={props.onFocus}
      autoFocus={props.autoFocus}
      autoCorrect={false}
    />
  )
}
