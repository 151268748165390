import { StripeQueryParams } from './types'

/**
 * Generates HTML content that redirects the user through a stripe payment confirm flow
 * Heavily based on a similar implementation for checkout sessions https://github.com/sparelabs/react-native-stripe-checkout-webview
 */
export const stripeConfirmIntentRedirectHTML = (stripePublicKey: string, clientSecret: string): string =>
  `
    <html>
      <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>Payments</title>
      </head>
      <body>
        <!-- Display loading content -->
        <h1 id="sc-loading"></h1>
        <!-- Display error content -->
        <div id="sc-error-message"></div>
        <!-- Exec JS without blocking dom -->      
        <!-- Load Stripe.js -->
        <script src="https://js.stripe.com/v3"></script>
        <!-- Stripe execution script -->
        <script>
          (function initStripeAndConfirmCardPayment () {
            const stripe = Stripe('${stripePublicKey}');
            window.onload = () => {
              stripe.confirmCardPayment("${clientSecret}")
              .then((result) => {
                  // urls used here are not actually ever loaded, they just need to be valid
                  if (result.error) {
                    window.location.href = "https://spare-rider.web.app/cancel?${StripeQueryParams.Cancel}"
                  } else {
                    window.location.href = "https://spare-rider.web.app/success?${StripeQueryParams.Success}"
                  }
                }).catch((err) => {
                  window.location.href = "https://spare-rider.web.app/cancel?${StripeQueryParams.Cancel}"
                });
            };
          })();
        </script>
      </body>
    </html>
    `
